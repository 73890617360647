.friendHeader {
  border-bottom: none;
  text-align: center;
  background: url("../img/friend-request/search-bg-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 900px;
  margin: 0 auto;
  padding-top: 10px;
}

.searchBox {
  border-bottom: none;
  /* padding-left: 1.2rem; */
  /* z-index: 10000;
    position: absolute; */
  /* left: 0;
    right: 0; */
  text-align: center;
  background: url("../img/friend-request/search-bg-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 410px;
  margin: 0 auto;
  height: 100px;
  padding-top: 10px;
  top: 127px;
}

.searchBox input {
  width: 100%;
  height: 2.8rem;
  border: none;
  padding-left: 0rem;
}

.searchBox input[type="text"] {
  height: 100px;
  font-size: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 20px 12px 0px;
  outline: 0;
  font-size: 18px;
  font-weight: normal;
  font-family: Proxima-Regular;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #707070;
  width: 410;
  background: none;
}

.searchBox input[type="text"]::-webkit-input-placeholder {
  /* Edge */
  color: #707070;
  font-size: 18px;
  text-transform: uppercase;
}

.searchBox input[type="text"]:-ms-input-placeholder {
  /* Internet Explorer */
  color: #707070;
  font-size: 18px;
  text-transform: uppercase;
}

.searchBox input[type="text"]::placeholder {
  color: #707070;
  font-size: 18px;
  text-transform: uppercase;
}

.closeSearch {
  position: absolute;
  margin-left: -30px;
  margin-top: 11px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  z-index: 1000;
}
.search {
  display: inline-block;
  margin: 0px 15px;
  vertical-align: top;
  height: 50px;
}
.search:hover {
  cursor: pointer;
}
.searchBarButtonBackground {
  background: url(../img/balance_bg.png) left top;
  color: #ffffff;
}

.searchBarButton {
  height: 70px;
  font-size: 16px;
  font-weight: 600;
  padding: 24px 14px 20px 14px;
  position: relative;
  margin-top: -16px;
  float: left;
  font-family: "Exocet";
}

.inviteBarButton {
  height: 70px;
  font-size: 16px;
  font-weight: 600;
  padding: 24px 14px 20px 14px;
  position: relative;
  margin-top: -16px;
  float: right;
  font-family: "Exocet";
}
.scrollableElement {
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
}

.inviteClose {
  height: 70px;
  padding: 16px 35px 20px 14px;
  position: relative;
  margin-top: -70px;
  float: right;
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  right: 0;
}
.inviteFriendButton {
  font-size: 15px;
  font-weight: 600;
  width: 100px;
  height: 50px;
  padding: 18px 0px 0px 9px;
  position: relative;
  margin-top: -8px;
  font-family: "Exocet";
}

.inviteFriendBackground {
  margin-left: -82px;
  padding-right: 15px;
  background: url(../img/rewards_bg.png) left top;
  color: #86dd39;
}
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.invite-hover-button .invite-hover-button--on,
.invite-hover-button:hover .invite-hover-button--off {
  /* content: "";
  display: flex;
  height: 80px;
  cursor: "pointer"; */
}

.invite-hover-button:hover .invite-hover-button--on {
  content: "";
  display: flex;
  width: 98%;
  height: 91px;
  cursor: "pointer";
  background: url(../img/table_selector.png) no-repeat;
  background-size: cover !important;
  position: absolute;
  z-index: 0;
}
.home_button {
  border-radius: 4px;
  /* background: url(../img/featured_megajackpot_bg.png) top left; */
  background: transparent;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  height: 40px;
  width: 200px;
  transition: all 0.5s;
}

.home_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.home_button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: -5px;
  right: -20px;
  font-size: 20px;
  color: #ca5f64;
  transition: 01.5s;
}

.home_button:hover span {
  padding-right: 25px;
  color: #ca5f64;
}

.home_button:hover span:after {
  opacity: 2;
  right: 0;

  font-size: 20px;
}

.friend_col_1 {
  margin-left: -10px;
}

.friend_col_2 {
  margin-left: -10px;
}

.friend_col_3 {
  text-align: center;
  margin-left: 30px;
  margin-right: -20px;
}

.invite_friend_table {
  margin-top: 18px;
  margin-left: 20px;
}

@media only screen and (min-width: 0px) and (max-width: 400px) {
  .searchBox {
    top: 200px;
    width: 350px;
  }

  .friend_col_1 {
    width: 100%;
    margin-top: -20px;
    margin-left: -20px;
  }

  .friend_col_2 {
    margin-top: -20px;
    margin-left: 150px;
  }

  .friend_col_3 {
    margin-top: 10px;
    margin-left: -437px;
    margin-right: 0;
  }

  .friend_col_4 {
    margin-top: 10px;
    margin-left: 296px;
    margin-right: 0;
  }

  .invite_friend_table {
    margin-left: -32px;
  }

  .invite-hover-button:hover .invite-hover-button--on {
    width: 110%;
  }
  
}

@media only screen and (min-width: 401px) and (max-width: 784px) {
  .searchBox {
    top: 200px;
    width: 350px;
  }

  .friend_col_1 {
    width: 100%;
    margin-top: -20px;
    margin-left: -20px;
  }

  .friend_col_2 {
    margin-top: -20px;
    margin-left: 150px;
  }

  .friend_col_3 {
    margin-top: 10px;
    margin-left: -437px;
    margin-right: 0;
  }

  .friend_col_4 {
    margin-top: 10px;
    margin-left: 296px;
    margin-right: 0;
  }

  .invite_friend_table {
    margin-left: -32px;
  }

  .invite-hover-button:hover .invite-hover-button--on {
    width: 110%;
  }
  
}
