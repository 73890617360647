/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 1, 2021 */



@font-face {
    font-family: 'liberation_serifbold';
    src: url('../font/liberationserif-bold-webfont.woff2') format('woff2'),
         url('../font/liberationserif-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'liberation_serifbold_italic';
    src: url('../font/liberationserif-bolditalic-webfont.woff2') format('woff2'),
         url('../font/liberationserif-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'liberation_serifitalic';
    src: url('../font/liberationserif-italic-webfont.woff2') format('woff2'),
         url('../font/liberationserif-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'liberation_serifregular';
    src: url('../font/liberationserif-regular-webfont.woff2') format('woff2'),
         url('../font/liberationserif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}