body {
    margin: 0px;
    color: #756f69;
    font-family: Open Sans, Arial, sans-serif;
    font-size: 14px;
    background: #080808 url(../img/header_bg.jpg) top center no-repeat;
    overflow-x: hidden !important;
}

/* add this fragment when enable Bootstrap */

/*
*, ::after, ::before {
    box-sizing: revert !important;
}
*/

a:link {
    color: #fc7a52;
    text-decoration: none;
}

a:visited {
    color: #fc7a52;
    text-decoration: none;
}

a:hover,
a:focus,
a:active {
    color: #fc7a52;
    text-decoration: underline;
    border: none !important;
    outline: none !important;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #272727;
}

::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
}

::-webkit-scrollbar-track {
    border-width: 0;
}

img {
    border: 0px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

@font-face {
    font-family: Albertus;
    src: url(../font/albertus_medium.ttf);
}

@font-face {
    font-family: Exocet;
    src: url(../font/Exocet.ttf);
}

@font-face {
    font-family: OpenSans;
    src: url(../font/OpenSans-Semibold.ttf);
}

.wrapper {
    width: 1482px;
    margin: 0px auto;
}

.top_menu {
    height: 96px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
}

.top_menu .wrapper {
    height: 96px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.content {
    background: url(../img/content_bg.jpg) center 210px no-repeat;
    position: relative;
    z-index: 2;
}

.App {
    width: calc(100%);
    transition: all 0.5s ease-in-out;
}

.App-blog-open {
    width: calc(100% - 360px);
    transition: all 0.5s ease-in-out;
}

.top-fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border: none;
    height: 75px;
    width: auto;
    z-index: 999f;
}

.top-fixed .wrapper {
    border-bottom: 0px;
}

.company_selector {
    position: absolute;
    top: -130px;
    left: 16px;
}

.top-panel,
.logo_area img,
.block,
.block > div img,
.button:hover,
.adv_icon img,
.payments a,
.f_menu a,
.soc a,
.soc_panel img,
.toTop:after,
.duels,
.referral-contests,
.nickname button,
.company_selector button,
.payment_sum input[type="radio"] + label,
#game_history table tr:after,
game_ui_list table tr:after,
game_invite table tr:after,
.soc_login a,
.modal_form input,
.modal_form textarea,
.game_icon:after,
.slider-game {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
}

.logo_area {
    text-align: center;
    padding-top: 30px;
    position: relative;
    z-index: -6;
}

.logo_area.christmas {
    padding-top: 60px;
}

.logo_area img:hover,
.button:hover,
.soc_login a:hover {
    -webkit-filter: brightness(1.2);
    -moz-filter: brightness(1.2);
    -o-filter: brightness(1.2);
    -ms-filter: brightness(1.2);
}

.jackpot {
    background: url(../img/jackpot3_bg.png) center top repeat-y;
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 80px;
    font-family: Albertus;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    z-index: 9;
}

.jackpot:after {
    content: "";
    background: url(../img/jackpot3_after_bg.png) center top no-repeat;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -48px;
    height: 62px;
}

.jackpot:before {
    content: "";
    background: url(../img/jackpot3_before_bg.png) center top no-repeat;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -48px;
    height: 48px;
}

.jackpot div {
    font-size: 60px;
    color: #ffda59;
    text-shadow: 0px 0px 10px rgba(255, 157, 103, 0.5);
}

.top_blocks {
    height: 216px;
    width: 1150px;
    margin: -30px auto 0px auto;
    position: relative;
}

.top_blocks:before {
    content: "";
    display: block;
    position: absolute;
    width: 325px;
    height: 244px;
    background: url(../img/shape_top_block.png) no-repeat;
    left: 50%;
    margin-left: -162px;
    margin-top: -14px;
    z-index: 1;
}

.top_blocks:after {
    content: "";
    display: block;
    position: absolute;
    width: 1181px;
    height: 252px;
    background: url(../img/corners_top_block.png) no-repeat;
    top: -18px;
    left: -20px;
}

.block {
    background: rgba(18, 18, 18, 0.9);
    width: 383px;
    height: 216px;
    float: left;
    position: relative;
    z-index: 2;
    margin-left: -2px;
    cursor: pointer;
}

.block:hover {
    background: rgba(12, 12, 12, 0.9) url(../img/top_block_hover.png) center 74px no-repeat;
}

.block > div {
    z-index: 3;
    position: relative;
    text-align: center;
    font-family: Albertus;
    padding-top: 120px;
    font-size: 17px;
    text-transform: uppercase;
}

.block > div span {
    display: block;
    font-size: 24px;
    color: #ffda59;
    text-shadow: 0px 0px 10px rgba(255, 157, 103, 0.5);
    margin-top: 5px;
}

.block > div p {
    margin: 0px;
    background: -webkit-linear-gradient(#f4ebd4, #707068);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.block > div img {
    position: absolute;
    top: -88px;
    left: 50%;
    margin-left: -124px;
}

.block > div:hover img {
    top: -98px;
}

.block:after {
    content: "";
    display: block;
    position: absolute;
    width: 391px;
    height: 224px;
    background: url(../img/border_top_block.png) no-repeat;
    background-size: cover !important;
    top: -4px;
    left: -4px;
}

.blocks_shadow {
    width: 1000px;
    height: 130px;
    background: #000;
    filter: progid: DXImageTransform.Microsoft.Blur(pixelRadius=35);
    -webkit-filter: blur(35px);
    -moz-filter: blur(35px);
    filter: blur(35px);
    margin: -60px auto 0px auto;
    opacity: 0.8;
}

.page_content {
    padding: 0px 0px;
    margin-top: -20px;
    min-height: 900px;
}

.marginer {
    height: 89px;
    background: url(../img/marginer.png) center top no-repeat;
    position: relative;
}

.ba_left {
    width: 430px;
}

.ba_right {
    float: right;
    width: 600px;
    margin-top: -40px;
}

h2,
h3 {
    text-transform: uppercase;
    font-family: Albertus;
    font-size: 48px;
    background: -webkit-linear-gradient(#f4ebd4, #707068);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: normal;
}

.text-green-gradient {
    font-family: Albertus;
    font-size: 48px;
    background: url(../img/font-green.png) no-repeat;
    background-size: cover;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: normal;
}

.text {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
}

button {
    border: 0px;
    outline: none;
    cursor: pointer;
}

input {
    height: 40px;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    color: #fff;
    outline: none;
    font-size: 14px;
    padding: 0px 15px;
}

.button {
    height: 52px;
    text-transform: uppercase;
    font-family: Albertus;
    color: #fff;
    font-size: 16px;
    text-align: center;
    text-shadow: 1px 1px #648823;
    box-shadow: 0px 0px 25px rgba(138, 159, 0, 0.3);
}

.play_button {
    width: 193px;
    background: url(../img/play_button.jpg) no-repeat;
}

.ba_left button {
    margin-top: 50px;
}

.jackpot2 {
    background: url(../img/jackpot4_bg.png) center top repeat-y;
    margin-top: 120px;
}

.jackpot2:after {
    background: url(../img/jackpot4_after_bg.png) center top no-repeat;
}

.jackpot2:before {
    background: url(../img/jackpot4_before_bg.png) center top no-repeat;
}

.page_title {
    height: 68px;
    background: url(../img/page_title_bg.png) 50% 50% no-repeat;
    text-align: center;
    font-size: 36px;
    line-height: 68px;
}

.page_title span {
    display: inline-block;
    padding: 0px 20px;
    background: #080808;
}

.page_title p {
    margin: 0px;
    text-transform: uppercase;
    font-family: Albertus;
    background: -webkit-linear-gradient(#f4ebd4, #707068);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.adv_list {
    font-size: 0px;
    width: 100%;
    padding-top: 20px;
}

.adv {
    font-size: 13px;
    text-align: center;
    font-weight: 600;
}

.adv_icon {
    width: 246px;
    height: 246px;
    background: url(../img/adv_icon_bg.png) 50% 50% no-repeat;
    margin: 0px auto;
    cursor: pointer;
}

.adv_icon:hover img {
    margin-top: -8px;
}

h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
}

.adv span {
    display: block;
    width: 300px;
    margin: 0px auto;
}

.footer {
    margin-top: 75px;
    position: relative;
    z-index: 0;
}

.payments {
    text-align: center;
}

.payments a {
    display: inline-block;
    height: 27px;
    width: 83px;
    background: url(../img/payment_icon_sprite.png) no-repeat;
    opacity: 0.5;
    margin: 0px 24px;
}

.payments a:hover {
    opacity: 1;
}

#mastercard {
    width: 36px;
    background-position: -83px 0px;
}

#visa {
    width: 59px;
    background-position: -119px 0px;
}

#bitcoin {
    width: 84px;
    background-position: -178px 0px;
}

#ideal {
    width: 31px;
    background-position: -266px 0px;
}

#klarna {
    width: 49px;
    background-position: -300px 0px;
}

#union_pay {
    width: 43px;
    background-position: -352px 0px;
}

#giropay {
    width: 63px;
    background-position: -398px 0px;
}

#bancontact {
    width: 33px;
    background-position: -468px 0px;
}

#eps {
    width: 39px;
    background-position: -505px 0px;
}

.footer {
    color: #fff;
    background: url(../img/smoke_bottom.png) center bottom no-repeat;
}

.footer .wrapper {
    width: 1152px;
}

.copyright {
    font-size: 13px;
    font-weight: bold;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding-bottom: 40px;
    padding-top: 5px;
}

.f_menu {
    float: right;
    font-size: 12px;
    font-family: Albertus;
    text-transform: uppercase;
    font-weight: bold;
}

.f_menu a,
.f_menu span.link-button {
    color: #fff;
    margin-left: 50px;
}

.f_menu a:hover,
.f_menu span.link-button:hover {
    color: #fc7a52;
    text-decoration: none;
    text-shadow: 0px 0px 10px rgba(255, 0, 0, 0.5);
}

.f_content {
    padding: 40px 0px 170px 0px;
    font-size: 13px;
    color: #756f69;
}

.f_logos img {
    float: left;
    margin-right: 30px;
}

.logo_footer {
    margin-top: -22px;
}

.f_text {
    width: 420px;
    float: left;
    margin-left: 20px;
    margin-top: -5px;
}

.soc {
    position: absolute;
    bottom: 60px;
    right: 60px;
    z-index: 999;
}

.soc svg {
    width: 48px;
    height: 48px;
}

.soc a {
    display: block;
    float: left;
    width: 24px;
    height: 24px;
    margin-left: 16px;
    opacity: 0.3;
}

.soc a:hover {
    opacity: 1;
}

.soc button {
    display: block;
    float: left;
    width: 32px;
    height: 32px;
    margin-left: 16px;
    opacity: 0.7;
    border-radius: 50% !important;
    overflow: hidden;
}

.soc button:hover {
    opacity: 1;
}

.soc img {
    width: 100%;
    height: 100%;
}

#objective .soc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

#objective .soc-container .soc {
    position: relative;
    bottom: unset;
    right: unset;
}

#objective .soc button {
    width: 48px;
    height: 48px;
    padding: 0;
    background: none;
    margin-left: 4px;
    margin-right: 4px;
}

#objective .soc button img {
    width: 48px;
    height: 48px;
}

#twitter {
    width: 20px;
    background-position: -18px 0px;
}

#vk {
    width: 24px;
    background-position: -38px 0px;
}

#youtube {
    width: 22px;
    background-position: -62px 0px;
}

#discord {
    width: 22px;
    background-position: -84px 0px;
}

#instagram {
    background: url(../img/soc_instagram.png) no-repeat;
}

.game_icon {
    float: left;
    position: relative;
    padding-right: 12px;
    width: 140px;
    cursor: pointer;
}

.game_icon:before {
    content: "";
    display: block;
    width: 1px;
    height: 34px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    right: 0px;
    top: 31px;
}

.game_icon:after {
    content: "";
    display: block;
    width: 160px;
    height: 96px;
    position: absolute;
    background: url(../img/icon_small_d.png) no-repeat;
    top: 0px;
    left: 0px;
}
/*
.game_icon:hover:after {
    content: "";
    display: block;
    width: 160px;
    height: 96px;
    position: absolute;
    background: url(../img/icon_small_full.png) no-repeat;
    top: 0px;
    left: 0px;
}*/

.profile {
    float: left;
    padding-top: 29px;
    padding-left: 40px;
    width: 1300px;
}

.top-fixed .game_icon {
    margin-top: -14px;
}

.login_button,
.bordered_button,
.register_button {
    height: 40px;
    background: url(../img/login_button.jpg);
    width: 108px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
}

.register-zoom {
    background-size: cover;
    zoom: 200%;
}

.bordered_button {
    background: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: none;
    text-shadow: none;
    width: 100px;
}

.bordered_button:hover {
    border: 2px solid rgba(255, 255, 255, 0.6);
}

.login_panel input,
.login_panel button {
    float: left;
    margin-right: 10px;
}

.login_panel input {
    width: 150px;
    padding-left: 40px;
}

.login_panel span {
    display: block;
    float: left;
    height: 40px;
    line-height: 40px;
    font-weight: 600;
    width: 50px;
    text-align: center;
    color: #fff;
    opacity: 0.5;
    margin-right: 10px;
}

.login_panel .bordered_button {
    width: 130px;
}

.login_panel > div {
    float: left;
    position: relative;
}

.right-container {
    float: right;
    position: relative;
    font-size: 14px;
    margin-top: 38px;
}

.blog-toggle {
    float: right;
    position: relative;
    font-size: 14px;
    margin-top: 30px;
    right: 12px;
    width: 40px;
    height: 40px;
    padding: 8px 4px 8px 9px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.4);
}

.blog-toggle-open {
    background: rgba(0, 0, 0, 0.8);
}

.blog-toggle img {
    width: 20px;
    height: 20px;
}

.lang {
    float: right;
    position: relative;
    font-size: 14px;
    margin-top: 38px;
}

.lang a,
.lang .link-button {
    color: #fff;
    text-decoration: none;
}

.top-fixed .profile {
    padding-top: 14px;
}

.top-fixed input {
    background: rgba(83, 79, 74, 0.2);
}

.top-fixed .lang {
    margin-top: 23px;
}

.top-fixed .blog-toggle {
    margin-top: 16px;
}

.lang button img {
    float: left;
    margin-right: 8px;
    margin-top: 2px;
}

.sub_menu.lang_menu {
    z-index: 999;
}

.lang_menu > li > a img,
.lang_menu > li > .link-button img {
    float: left;
    margin-top: 8px;
    margin-right: 8px;
}

/** Drop Menu **/

.main-item {
    position: relative;
    padding-right: 22px;
    display: inline-block;
    background: none;
    font-size: 14px;
    color: #fff;
    font-family: Open Sans;
    line-height: normal;
}

.main-item:after {
    content: "";
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    right: 0px;
    top: 7px;
    background: url(../img/row_icon.png) right 50% no-repeat;
    padding-right: 24px;
    opacity: 0.2;
}

.main-item:focus:after {
    opacity: 1;
}

.sub-menu {
    display: none;
}

.main-item:focus ~ .sub-menu,
.main-item:active ~ .sub-menu,
.sub-menu:hover {
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.95);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.6);
    top: 36px;
    margin-right: -20px;
    width: 180px;
    right: 0px;
    padding: 12px 0px;
    z-index: 1000;
    color: #363636;
    font-weight: normal;
}

.main-item:focus {
    border-color: rgba(0, 0, 0, 0.5);
}

.sub-menu li {
    list-style: none;
}

.sub-menu > li > a,
.sub-menu > li > .link-button {
    display: block;
    height: 34px;
    line-height: 32px;
    font-size: 13px;
    padding: 0px 26px;
    color: #fff;
    text-decoration: none;
}

.sub-menu li a:hover,
.sub-menu li .link-button:hover {
    background-color: rgba(53, 63, 70, 0.3);
}

.sub-menu li a span {
    float: right;
    opacity: 0.4;
}

.sub-menu:before {
    content: "";
    position: absolute;
    right: 20px;
    top: -16px;
    border: 8px solid transparent;
    border-bottom: 8px solid rgba(0, 0, 0, 0.95);
}

.sub-menu hr {
    border-left: none;
    border-bottom: none;
    border-right: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin: 10px 26px;
}

.blog-panel {
    position: fixed;
    background: rgb(36, 38, 43);
    box-shadow: 0px 5px 10px rgba(204, 124, 124, 0.6);
    min-width: 360px;
    max-width: 360px;
    height: calc(100vh - 96px);
    padding: 0px;
    right: 0px;
    top: 0%;
    margin-top: 96px;
    text-align: center;
    font-size: 0px;
    z-index: 998;
}

.blog-panel-show {
    transition: all 0.5s ease-in-out;
    transform: translate(0, 0);
}

.blog-panel-hide {
    transition: all 0.5s ease-in-out;
    transform: translate(100%, 0);
    box-shadow: none;
}

.blog-fixed {
    margin-top: 75px;
    height: calc(100vh - 75px);
}

.blog-panel .blog-list {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.blog-panel .blog-list::-webkit-scrollbar {
    display: none;
}

.blog-panel .blog-container {
    width: 100%;
    padding: 24px;
    position: fixed;
    z-index: 900;
    background: rgb(36, 38, 43);
}

.blog-list .blog-item {
    width: 100%;
    height: 312px;
    max-height: 312px;
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    border: solid #4d4d4d 1px;
    border-radius: 16px;
    background: rgba(49, 52, 58, 1);
}

.blog-item .blog-title {
    margin-top: 10px;
    margin-bottom: 4px;
    color: rgba(153, 164, 176, 0.6);
    font-size: 20px;
    font-family: "OpenSans";
}

.blog-item .blog-img img {
    max-height: 200px;
    max-width: 100%;
    width: 80%;
    object-position: center;
    object-fit: contain;
}

.blog-item .blog-text {
    margin-top: 4px;
    margin-bottom: 10px;
    color: rgba(153, 164, 176, 0.6);
    font-size: 14px;
    font-family: "OpenSans";
    text-align: left;
    word-break: break-word;
    overflow: hidden;
}

.blog-list .seperator {
    width: 100%;
    height: 1px;
    margin-bottom: 12px;
    background: #4d4d4d;
}

.blog-item .emoji-container {
    margin-bottom: 12px;
}

.mt-360 {
    margin-top: 360px;
}

.blog-panel .blog-panel-send-box {
    width: 100%;
    min-height: 116px;
    max-height: 320px;
    bottom: 0;
    font-size: 12px;
    background: rgba(49, 52, 58, 0.8);
}

.blog-panel .rules-container {
    width: 100%;
    width: calc(100% - 16px);
    min-height: 48px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: end;
    border: solid red 1px;
}

.rules-container button {
    border-radius: 50%;
    background: none;
    width: 32px;
    height: 32px;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.blog-panel-send-box .input-error {
    width: calc(100% - 80px);
    margin-left: 22px;
    margin-top: -8px;
    text-align: left;
    color: red;
}

.blog-panel-send-box .input-success {
    width: calc(100% - 80px);
    margin-left: 22px;
    margin-top: -8px;
    text-align: left;
    color: green;
}

.blog-panel .send-input {
    width: calc(100% - 16px);
    min-height: 46px;
    margin: 8px;
}

.blog-panel .blog-comment-item {
    width: 100%;
    display: flex;
    padding-right: 12px;
}

.blog-comment-item .blog-comment-header {
    margin-top: 10px;
    width: 60px;
}

.blog-comment-item .select {
    background: none;
    border: none;
    padding: 0;
}

.blog-comment-item .select button {
    color: white;
}

.blog-comment-item .blog-comment-header .lang_menu,
.blog-comment-item .blog-comment-content .lang_menu {
    width: 120px;
    margin: 0;
    padding: 4px 0;
}

.blog-comment-header .main-item:focus ~ .sub-menu,
.blog-comment-header .main-item:active ~ .sub-menu,
.blog-comment-header .sub-menu:hover {
    left: 0px;
}

.blog-comment-content .main-item:focus ~ .sub-menu,
.blog-comment-content .main-item:active ~ .sub-menu,
.blog-comment-content .sub-menu:hover {
    right: 0px;
}

.blog-comment-item .blog-comment-header button {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background: none;
}

.blog-comment-item .blog-comment-header button.main-item {
    padding: 0;
}

.blog-comment-header button .badge {
    display: flex;
    flex-direction: row;
    transform: translate(0, -50%);
}

.blog-comment-header .blog-comment-avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0;
    display: block;
}

.blog-comment-header .badge .blog-comment-user-membership {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.blog-comment-header .blog-comment-user-flag {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.blog-comment-header .blog-comment-user-membership-name {
    border-radius: 50%;
    padding: 2px;
    font-size: 9px;
    margin-top: -16px;
}

.blog-comment-item .blog-comment-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    padding: 10px 0 10px 10px;
}

.blog-comment-content .title {
    color: rgb(202, 209, 217);
    font-size: 14px;
    font-family: "OpenSans";
}

.blog-comment-content .title .user-name {
    font-weight: 700;
    font-size: 16px;
}

.blog-comment-content .title .time {
    font-weight: 300;
    margin-left: 4px;
}

.blog-comment-content .btn-more {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    font-size: 16px;
    border-radius: 50%;
}

.blog-comment-content .btn-more button {
    color: white;
    background: none;
}

.blog-comment-content .message {
    min-height: 38px;
    text-align: left;
    white-space: pre-wrap;
    user-select: text;
    font-size: 0.875rem;
    vertical-align: top;
    color: rgb(202, 209, 217);
    line-height: 1.125rem;
    word-break: break-word;
    overflow: hidden;
    border-radius: 20px;
    padding: 10px 16px;
    background: rgb(30 25 25);
    flex: 1;
}

.blog-comment-content .p-relative {
    position: relative;
    min-width: 36px;
}

.blog-comment-content .msg-seen {
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.blog-comment-content .msg-seen img {
    border-radius: 50%;
    width: 14px;
    height: 14px;
}

.blog-comment-winner {
    background: #24262b;
    border-radius: 12px;
    min-width: 220px;
    max-width: 220px;
    width: 220px;
    margin-top: 8px;
    padding: 8px;
    font-size: 16px;
}

.blog-comment-winner .game-info {
    display: flex;
    align-items: center;
}

.game-info .game-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #3c4046;
}

.game-info .game-icon img {
    transform: scale(1.4);
    width: 40px;
    height: 40px;
}

.game-info .game-info-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    padding: 4px;
}

.game-info-text .game-title {
    font-size: 16px;
    font-weight: 700;
    color: white;
}

.game-info-text .game-mode {
    font-size: 12px;
    font-weight: 400;
    color: rgba(153, 164, 176, 0.6);
}

.blog-comment-winner .user-level {
    background: #3c4046;
    margin-top: 8px;
    padding: 8px;
    border-radius: 8px;
}

.blog-comment-winner .winner-won {
    background: #3c4046;
    margin-top: 8px;
    padding: 8px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.winner-won .img-won {
    height: 32px;
    aspect-ratio: 1 / 1;
    margin-bottom: 6px;
    margin-right: 12px;
}

.blog-comment-winner .winner-prize {
    margin-top: 8px;
    display: flex;
}

.winner-prize .money {
    padding: 12px;
    padding-left: 0;
    border-radius: 8px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url(../img/balance_bg.png) left;
    color: #f08d2f;
    font-size: 24px;
    font-weight: 700;
    position: relative;
}

.winner-prize .gem {
    margin-left: 4px;
    padding: 12px;
    padding-left: 0;
    border-radius: 8px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url(../img/rewards_bg.png) left;
    color: #86dd39 !important;
    font-size: 24px;
    font-weight: 700;
    position: relative;
}

.winner-prize .icon-money {
    width: 32px;
    height: 32px;
    transform: scale(1.4);
}

.winner-prize .icon-gem {
    width: 32px;
    height: 32px;
}

.img-congrats {
    width: 100%;
    height: 100%;
    position: absolute;
}

.blog-panel .input-container {
    width: 100%;
}

.blog-panel .input-wrapper {
    width: calc(100% - 44px);
    min-height: 46px;
    background: rgb(36, 38, 43);
    border-radius: 23px;
    padding: 0px 8px;
    margin-right: 12px;
    display: flex;
    align-items: center;
}

.blog-panel .input-wrapper-border {
    border: solid greenyellow 1px;
}

.blog-panel .input-error-border {
    border: solid red 1px;
}

.blog-panel .input-wrapper textarea {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    color: white;
    font-weight: 300;
    resize: none;
    font-size: 14px;
    flex: 1;
}

.blog-panel .input-wrapper .input {
    padding: 4px 0px 4px 8px;
    width: 100%;
    display: flex;
}

.blog-panel .input-wrapper .emoji {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    align-self: flex-end;
}

.emoji .emoji-icon {
    width: 20px;
    height: 20px;
}

.emoji .question-icon {
    width: 18px;
    height: 18px;
}

.emoji .win-ban-icon {
    width: 18px;
    height: 18px;
}

.blog-panel .send-btn-wrapper {
    position: relative;
    width: 44px;
}

.blog-panel .send-btn-wrapper .send-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background: linear-gradient(289deg, #76da75, #51bf43);
    transition: transform ease-in 0.1s, box-shadow ease-in;
}

.blog-panel .send-btn-wrapper .send-btn:active {
    transform: scale(0.8);
    background: linear-gradient(289deg, #63bb62, #3b8131);
}

.send-btn-wrapper img {
    width: 16px;
    height: 16px;
}

.soc_panel {
    /* box-shadow: 0px 5px 10px rgba(0,0,0,0.6); */
    width: 76px;
    opacity: 0;
    padding: 15px 0px;
    position: fixed;
    right: 140px;
    top: 50%;
    margin-top: -96px;
    text-align: center;
    font-size: 0px;
    z-index: 998;
}

.soc_panel img {
    margin: -8px 0px;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
}

.soc_panel img:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.games {
    width: 120px;
    height: 439px;
    background: url(../img/games_bg.png) no-repeat;
    position: fixed;
    left: 140px;
    top: 50%;
    margin-top: -225px;
    text-align: center;
    padding-top: 12px;
    z-index: 999;
}

.game {
    cursor: pointer;
    margin: -15px 0px;
    display: block;
}

.game div {
    margin-top: -30px;
    padding-left: 15px;
}

.game span {
    color: #f08d2f;
    font-weight: 600;
    font-size: 14px;
    position: relative;
}

.game span:before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url(../img/coin_icon_small.png);
    position: absolute;
    left: -27px;
    top: -6px;
}

.game img {
    width: 122px;
    margin-left: -1px;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
}

.game:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.toTop {
    width: 70px;
    position: fixed;
    bottom: 180px;
    right: 140px;
    cursor: pointer;
    display: none;
    z-index: 9;
}

.toTop span {
    display: block;
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    font-size: 13px;
    font-weight: 600;
}

.toTop:after {
    content: "";
    display: block;
    position: absolute;
    width: 28px;
    height: 31px;
    background: url(../img/row_up.png) no-repeat;
    top: 79px;
    left: 21px;
}

.toTop:hover:after {
    top: 74px;
}

.toTop:before {
    content: "";
    display: block;
    position: absolute;
    width: 54px;
    height: 54px;
    background: rgba(0, 0, 0, 0.95);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 70px;
    left: 8px;
}

/** Slider **/

.rotateslider-container {
    position: relative;
    width: 570px;
    height: 480px;
    margin-left: auto;
    margin-right: auto;
}

.top_blocks {
    margin-top: 30px;
}

.ba_left h2,
.ba_left .text {
    margin-top: 40px;
}

.rotateslider-container:before {
    content: "";
    display: block;
    width: 545px;
    position: absolute;
    height: 98px;
    background: url(../img/glow_bg.png) no-repeat;
    top: 430px;
    left: -20px;
}

.rotateslider-item:before {
    content: "";
    display: block;
    width: 258px;
    height: 314px;
    position: absolute;
    background: url(../img/slide_glow.png) no-repeat;
    left: 4px;
    bottom: 4px;
}

.rotateslider-container .rotateslider-item {
    position: absolute;
    width: 266px;
    height: 390px;
    background: url(../img/slide_bg.png) no-repeat;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(0.7);
    -ms-transform: translateY(-50%) translateX(-50%) scale(0.7);
    transform: translateY(-50%) translateX(-50%) scale(0.7);
    opacity: 0;
    -webkit-filter: blur(3px);
    filter: blur(3px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.4);
    text-align: center;
    padding: 0px 40px;
    font-size: 13px;
}

.rotateslider-container .rotateslider-item.next {
    left: 80%;
    opacity: 0.5;
}

.rotateslider-container .rotateslider-item.prev {
    left: 20%;
    opacity: 0.5;
}

.rotateslider-container .rotateslider-item.now {
    opacity: 1;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
    -ms-transform: translateY(-50%) translateX(-50%) scale(1);
    transform: translateY(-50%) translateX(-50%) scale(1);
    z-index: 9;
}

.rotateslider-container .arrow {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 50%;
    margin-top: -25px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
}

.rotateslider-container .arrow.left {
    left: 0;
}

.rotateslider-container .arrow.left:before,
.rotateslider-container .arrow.right:before {
    content: "";
    display: block;
    width: 24px;
    height: 28px;
    background: url(../img/prev_row.png) no-repeat;
    position: absolute;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 9px;
    left: 12px;
}

.rotateslider-container .arrow.right:before {
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: 13px;
    left: 15px;
}

.rotateslider-container .arrow.right {
    right: 0;
}

.slide_content {
    position: relative;
}

.slide_content img {
    margin-left: -18px;
}

#menu_icon {
    display: none;
}

#login:before,
#password:before {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    background: url(../img/login_icon.png);
    position: absolute;
    top: 2px;
    left: 3px;
}

#password:before {
    background: url(../img/password_icon.png);
}

.fire {
    width: 1125px;
    height: 450px;
    background: url(../img/fire_effects.png) no-repeat;
    position: absolute;
    top: -326px;
    left: 50%;
    margin-left: -562px;
    z-index: -1;
}

.smoke_center {
    width: 3743px;
    height: 824px;
    background: url(../img/smoke_center.png) 50% 50% no-repeat;
    position: absolute;
    top: 130px;
    z-index: -1;
    left: 50%;
    margin-left: -1600px;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.bg-semitransparent {
    background: rgba(255, 255, 255, 0.05);
}

#profile,
#game_history,
#game_ui,
#generaljackpot,
#ladder,
#bonanza,
#tournaments,
#referral,
#membership,
#objective,
#rewards,
#support {
    background: none;
}

#profile .smoke_center,
#game_history .smoke_center,
#game_ui .smoke_center,
#generaljackpot .smoke_center,
#ladder .smoke_center,
#bonanza .smoke_center,
#tournaments .smoke_center,
#support .smoke_center {
    top: 230px;
    opacity: 0.5;
}

.p-relative {
    position: relative;
    padding: 0 !important;
}

.c_top {
    width: 958px;
    background: url(../img/top_bg.jpg) no-repeat;
    position: relative;
}

.c_bottom {
    min-height: 715px;
    background: url(../img/bottom_bg.png) center bottom no-repeat;
    margin-bottom: 120px;
    padding: 84px 72px;
    color: #756f69;
}

.c_top:before,
.c_top:after,
.modal_bottom:before,
.modal_bottom:after {
    content: "";
    display: block;
    width: 994px;
    height: 89px;
    background: url(../img/profile_corners.png) no-repeat;
    position: absolute;
    top: -18px;
    left: -18px;
}

.c_top:after {
    background: url(../img/profile_corners_bottom.png) no-repeat;
    top: auto;
    bottom: -18px;
    left: auto;
    right: -18px;
}

.c_center {
    margin: 220px auto 0px auto;
    width: 958px;
    background: url(../img/center_bg.jpg) center -155px repeat;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
}

.subscription_content {
    margin: 220px auto 0px auto;
    width: 1096px;
    height: 1096px;
    background: url(../img/subscription-background.png) center no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.subscription_label .strike {
    flex: 1;
    text-decoration: line-through;
    opacity: 0.8;
}

.subscription_label .free_icon {
    margin-top: -8px;
    margin-left: 8px;
    width: 81px !important;
    position: absolute;
    top: 5px;
    right: 0px;
}

.subscription_label .bestoffer_icon {
    margin-top: -8px;
    margin-left: 8px;
    width: 100px !important;
    position: absolute;
    top: 23px;
    left: 24px;
    transform: rotate(-18deg);
}

.subscription_content .subscription_logos {
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscription_logos img {
    margin-top: -28px;
}

.subscription_layer {
    /* background: url(../img/subscription_layer.png) center no-repeat; */
    /* background-size: 920px 100%; */
    width: 100%;
    height: 1000px;
    position: absolute;
    -webkit-transform: translate(0%,-50%);
    transform: translate(0%,-50%);
    position: absolute;
    top: 55%;
    z-index: 1;
}

.subscription_content .subscription-faq {
    position: absolute;
    bottom: 0px;
    padding: 40px;
    width: 100%;
    z-index: 0;
}

.c_bottom h4,
.modal_content h5 {
    text-transform: uppercase;
    font-family: Albertus;
    font-size: 38px;
    color: #2c2823;
    font-weight: normal;
    text-align: center;
    margin: -10px 0px 0px 0px;
}

.c_membership {
    margin-top: 60px;
}

/** Tabs Style **/

.tabs {
    margin-top: 20px;
}

.tabs section {
    display: none;
    padding: 30px 0px 0px 0px;
    border-top: 2px solid #b09981;
    text-align: left;
}

.tabs input {
    display: none;
}

.tabs > label {
    display: inline-block;
    background: #e2c8a3;
    padding: 0px 50px;
    height: 46px;
    line-height: 46px;
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    position: relative;
    margin-right: 2px;
    border-bottom: 2px solid #b09981;
    margin-bottom: -2px;
}

.tabs > label > div {
    display: inline-block;
}

.tabs label:before {
    font-weight: normal;
    margin-right: 10px;
}

.tabs label:hover {
    cursor: pointer;
}

.tabs > input:checked + label {
    color: #363636;
    border-top: 2px solid #b09981;
    border-left: 2px solid #b09981;
    border-right: 2px solid #b09981;
    border-bottom: 2px solid #efdab7;
    background: none;
}

.tabs input:checked + label:before {
    opacity: 1;
}

.tabs #tab1:checked ~ #content1,
.tabs #tab2:checked ~ #content2,
.tabs #tab3:checked ~ #content3 {
    display: block;
}

table {
    border-spacing: 4px 4px;
    border-collapse: separate;
}

table tr td {
    background: rgba(255, 248, 234, 0.4);
    padding: 14px;
}

table h5 {
    height: 48px;
    line-height: 48px;
    margin: 0px;
    font-size: 13px;
    font-weight: normal;
    padding-left: 20px;
}

table button {
    margin: 5px;
}

.button_regular {
    height: 52px;
    background: url(../img/button_bg.jpg) center top;
    padding: 0px 50px;
    box-shadow: inset 0px 0px 20px rgba(55, 38, 11, 0.5), 0px 5px 15px rgba(30, 16, 10, 0.5);
}

.select {
    position: relative;
    display: inline-block;
    background: #e2c9a4;
    height: 44px;
    line-height: 44px;
    border: 2px solid #d5b88d;
    padding: 0px 10px;
    margin-right: 1px;
}

.select button {
    color: #756f69;
    font-weight: 600;
    text-align: left;
}

.select .main-item:focus ~ .sub-menu,
.select .main-item:active ~ .sub-menu,
.select .sub-menu:hover {
    margin-right: -2px;
    /*max-height: 200px;*/
    overflow-y: scroll;
}

.sub-menu::-webkit-scrollbar {
    width: 3px;
}

.table_content {
    padding: 0px 10px;
}

.select .main-item:after {
    background: url(../img/row_icon_dark.png) right 50% no-repeat;
    opacity: 0.4;
}

.select .main-item:focus {
    color: #020504;
}

.d-flex .select .main-item:focus {
    color: white;
}

.select .main-item:focus:after {
    opacity: 1;
}

/** Radio **/

.radio_r input[type="radio"] {
    display: none;
}

.radio_r input[type="radio"] + label {
    cursor: pointer;
    border: 2px solid #d5b88e;
    background: #e3cba8;
    border-radius: 2px;
    width: 138px;
    height: 44px;
    line-height: 44px;
    display: inline-block;
    padding-left: 42px;
    font-size: 14px;
    font-weight: 600;
    color: #756f69;
    position: relative;
    margin-right: 2px;
}

.radio_r input[type="radio"]:checked + label {
    color: #2c2823;
    border-color: #fbf0dd;
    background-color: #fbf0dd;
    box-shadow: 0px 3px 10px rgba(155, 126, 82, 0.2);
}

.radio_r input[type="radio"] + label:before {
    content: "";
    display: block;
    width: 22px;
    height: 22px;
    position: absolute;
    background: #d9bc94;
    top: 11px;
    left: 11px;
}

.radio_r input[type="radio"]:checked + label:before {
    background: #e5d7b4 url(../img/ok_icon.png) -7px -5px no-repeat;
}

.public_info .select button {
    width: 340px;
}

.tabs section input,
.modal_form input,
.modal_form textarea {
    display: block;
}

.tabs section input,
.modal_form input,
.modal_form textarea {
    border: 2px solid #d5b88e;
    background: #e3cba8;
    border-radius: 2px;
    height: 48px;
    line-height: 48px;
    color: #2c2823;
    width: 100%;
    box-sizing: border-box;
}

.balance {
    height: 70px;
    background: url(../img/balance_bg.png) left top;
    font-size: 16px;
    color: #f08d2f;
    font-weight: 600;
    padding: 25px 4px 0px 42px;
    position: relative;
    margin-top: -16px;
    float: left;
}

.friendOnline {
    height: 70px;
    font-size: 16px;
    font-weight: 600;
    padding: 24px 0px 20px 14px;
    position: relative;
    margin-top: -15px;
    float: right;
    font-family: "Exocet";
}

.friendOnlineSearch {
    height: 35px;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 0px 0px 14px;
    position: relative;
    margin-top: 13px;
    float: right;
    z-index: 10;
    font-family: "Exocet";
}
.friendOfflineSearch {
    height: 70px;
    font-size: 15px;
    font-weight: 600;
    width: 100px;
    padding: 6px 0px 0px 13px;
    position: relative;
    margin-top: 10px;
    float: right;
    z-index: 100;
    font-family: "Exocet";
}

.balance:before,
.td_award span:before {
    content: "";
    display: block;
    width: 50px;
    height: 48px;
    background: url(../img/coin_icon_medium.png) left top;
    position: absolute;
    top: 8px;
    left: 0px;
}

#ladder .td_award span:before,
#bonanza .td_award span:before {
    content: none;
}

#ladder .td_award .counter,
#bonanza .td_award .counter {
    margin-left: 24px;
    color: white;
    font-size: 12px;
    font-weight: 300;
}

#ladder .td_award img,
#bonanza .td_award img {
    width: 32px;
    height: 32px;
    margin-top: -4px;
}

#ladder table td .center,
#bonanza table td .center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
}
#ladder table td .button_reentry,
#bonanza table td .button_reentry {
    height: 36px;
    background: url(../img/button_bg.jpg) center top;
    padding: 2px 8px;
    margin: 0;
    font-size: 10px;
    box-shadow: inset 0px 0px 20px rgba(55, 38, 11, 0.5), 0px 5px 15px rgba(30, 16, 10, 0.5);
    z-index: 999;
}

#ladder .step_4,
#bonanza .step_4 {
    z-index: 999;
}

.balance button {
    float: right;
    margin-top: -5px;
    margin-left: 5px;
    font-size: 8px;
}

.deposit_button {
    height: 32px;
    background: url(../img/deposit_button.jpg) left top;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: 1px 1px #a48007;
    box-shadow: 0px 0px 15px rgba(226, 164, 0, 0.3);
    position: relative;
    z-index: 5;
    background-size: 100%;
}

.subscription_button {
    height: 32px;
    background: url(../img/subscription_background.jpg) left top;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    text-shadow: 1px 1px #a48007;
    box-shadow: 0px 0px 15px rgba(226, 164, 0, 0.3);
    position: relative;
    z-index: 5;
    background-size: 100%;
}

.nickname {
    position: relative;
    float: left;
}

.nickname p {
    max-width: 70px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
}
.duels,
.nickname button {
    display: block;
    float: left;
    height: 40px;
    max-width: 200px;
    overflow: hidden;
    background: rgba(89, 104, 114, 0.2);
    line-height: 38px;
    margin-left: 10px;
    color: #fff !important;
    font-size: 15px;
    text-decoration: none !important;
    font-weight: 600;
    padding: 0px 20px 0px 10px;
}

.company_selector button {
    display: block;
    float: left;
    height: 86px;
    max-width: 200px;
    white-space: nowrap;
    background: rgba(89, 104, 114, 0.2);
    line-height: 38px;
    margin-left: 0px;
    color: #fff !important;
    font-size: 15px;
    text-decoration: none !important;
    font-weight: 600;
    padding: 0px 20px 0px 10px;
}

.referral-contests {
    display: block;
    float: left;
    height: 40px;
    background: rgba(89, 104, 114, 0.2);
    line-height: 38px;
    margin-left: 10px;
    color: #fff !important;
    font-size: 15px;
    text-decoration: none !important;
    font-weight: 600;
    padding: 0px 10px 0px 10px;
    box-shadow: 0px 0px 15px 1px #000000;
}

.duels:hover,
.referral-contests:hover,
.nickname button:hover,
.company_selector button:hover {
    background-color: rgba(89, 104, 114, 0.6);
}

.nickname button {
    padding-right: 38px;
    display: flex;
    align-items: center;
}

.company_selector button {
    padding-right: 38px;
}

.nickname .main-item:after {
    right: 14px;
    top: 17px;
}

.company_selector .main-item:after {
    right: 14px;
    top: 41px;
}

.nickname button img {
    margin-top: -4px;
    margin-right: 10px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.company_selector button img {
    width: 96px;
    height: 64px;
}

.nickname .main-item:focus ~ .sub-menu,
.nickname .main-item:active ~ .sub-menu,
.nickname .sub-menu:hover {
    top: 45px;
    margin-right: -7px;
    width: 260px;
}

.company_selector .main-item:focus ~ .sub-menu,
.company_selector .main-item:active ~ .sub-menu,
.company_selector .sub-menu:hover {
    top: 121px;
    margin-right: -7px;
    width: 160px;
}

.nickname .sub-menu > li > a,
.nickname .sub-menu > li > .link-button,
.company_selector .sub-menu > li > a,
.company_selector .sub-menu > li > .link-button {
    position: relative;
    padding: 0px 26px 0px 55px;
}

.company_selector .sub-menu > li > .link-button {
    height: 84px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.company_selector .sub-menu > li > .link-button img {
    width: 96px;
    height: 64px;
}

.nickname .sub-menu > li > a:before,
.nickname .sub-menu > li > .link-button:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background: url(../img/menu_icon.png) no-repeat;
    opacity: 0.4;
    left: 26px;
    top: 7px;
}

.company_selector .sub-menu > li > a:before,
.company_selector .sub-menu > li > .link-button:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    background: url(../img/menu_icon.png) no-repeat;
    opacity: 0.4;
    left: 26px;
    top: 30px;
}

#m_settings:before {
    background: url(../img/settings_icon.png) no-repeat;
}

#m_logout:before {
    background: url(../img/logout_icon.png) no-repeat;
}

.duels {
    background: rgba(89, 104, 114, 0.2) url(../img/bell_icon.png) center no-repeat;
    padding-left: 24px;
    position: relative;
}

.duels span,
.referral-contests span,
.blog-toggle span {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    background: #d32132;
    padding: 0px 8px 0px 7px;
    box-shadow: 0px 0px 15px rgba(211, 33, 50, 1);
    position: absolute;
    text-align: center;
    top: -4px;
    right: -4px;
}

.menu-wrap {
    display: flex;
}

.profile_data {
    padding: 10px 26px;
    color: #756f69;
    font-size: 12px;
}

.profile_data h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

.profile_data img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.profile_data div {
    margin-left: 64px;
    min-height: 42px;
    padding-top: 8px;
}

.td_title {
    width: 370px;
}

/** Checkbox **/

.checkbox {
    display: inline-block;
    width: 22px;
    height: 22px;
    position: relative;
}

.checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin: 0px;
    width: 22px;
    height: 22px;
}

.checkbox label:before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    background-color: #d9bc94;
    margin: 0px;
    top: 0px;
    left: 0px;
}

.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox input[type="checkbox"]:checked + label {
    font-weight: 600;
}

.checkbox input[type="checkbox"]:checked + label:before {
    background: #e5d7b4 url(../img/ok_icon.png) -7px -5px no-repeat;
}

.eagle {
    width: 314px;
    height: 245px;
    background: url(../img/eagle.png) no-repeat;
    position: absolute;
    top: 188px;
    left: 315px;
    z-index: -6;
}

.c_bottom .eagle {
    top: 0px;
    left: -170px;
    top: -78px;
}

.leaves {
    width: 335px;
    height: 144px;
    background: url(../img/leaves.png) no-repeat;
    position: absolute;
    right: -50px;
    bottom: -30px;
    z-index: 9;
}

.deposit_area {
    float: left;
    width: 516px;
}

.rules {
    margin-left: 546px;
    padding-left: 30px;
    border-left: 1px solid #e1c194;
    min-height: 480px;
}

.rules h5 {
    color: #2c2823;
    font-size: 16px;
    margin: 0px;
    margin-bottom: 15px;
    position: relative;
    padding-left: 36px;
}

.rules h5:before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background: url(../img/rules_icon.png) no-repeat;
    position: absolute;
    top: -8px;
    left: -14px;
}

.rules_text {
    font-size: 12px;
    padding-left: 36px;
    padding-bottom: 30px;
}

.page_block {
    display: table;
    width: 100%;
}

.filter-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
}

.filter-bar .btn-status-tab {
    width: 106.5px;
    height: 42.5px;
    text-align: center;
    background: url(../img/button_bg.jpg) top center;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.filter-bar .btn-status-tab.tab-active {
    background: url(../img/button_bg.jpg) center top;
}

.filter-bar .btn-status-tab.tab-deactive {
    background: url(../img/button_bg_orange.jpg) center top;
}

.filter-bar .right-container {
    flex: 1;
    margin: 0;
}

.filter-bar .right-container .select {
    float: right;
    margin-left: 4px;
    min-width: 250px;
}

.filter-bar .right-container .select .main-item,
.filter-bar .right-container .select .sub-menu {
    width: 100%;
}

.payment_sum {
    font-size: 0px;
    padding: 20px 0px;
    overflow: hidden;
}

.react-tiny-popover-container {
    z-index: 9999;
}

.popover-amounts {
    background-color: #000;
    padding: 10px;
    position: absolute;
    top: 0px;
}

.payment_sum input[type="radio"] + label {
    width: 120px;
    height: 160px;
    background: url(../img/deposit_sum.jpg) no-repeat center;
    background-size: cover;
    padding: 0px;
    border: none;
    margin: 4px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    line-height: 0px;
    text-align: center;
    padding-top: 10px;
}

.payment_subscribe input[type="radio"] + label {
    width: 260px;
    height: 300px;    
}

.payment_subscribe {
    margin-top: 40px;
}

.payment_sum input[type="radio"] + label.subscription_label {
    background: url(../img/membership-background.png) no-repeat center;
    background-size: 260px 300px;    
    padding: 10px;
    box-shadow: none !important;
    outline: 0px;
    display: flex;
    width: 260px;
    height: 300px;
}

.payment_sum input[type="radio"] + label.subscription_label .subscription_benefits {
    color: #fff;
    padding: 0px 20px;
    height: 120px;
    text-align: left;
}
.payment_sum input[type="radio"] + label.subscription_label .subscription_benefits ul {
    height: 100%;
    /* list-style: none; */
    padding-left: 25px;

}

.payment_sum input[type="radio"] + label.subscription_label .subscription_benefits ul li {
    font-size: 13px;
    line-height: 20px;
}

.payment_sum input[type="radio"] + label.subscription_label .subscription_bestoffer {
    position: absolute;
    left: -10px;
    width: 50px;    
}

.payment_sum input[type="radio"] + label.subscription_label div {
    width: 100%;
    padding-top: 30px;
}

.payment_sum input[type="radio"] + label.subscription_label div p {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    font-size: 18px;
    text-transform: capitalize;
    text-shadow: 2px 1px 0px #000;
}

.payment_sum input[type="radio"] + label.subscription_label img {
    width: 80px;
    margin-top: 10px;
}

.payment_sum input[type="radio"] + label span {
    display: block;
    margin-top: -10px;
    font-size: 30px;
    color: #f08d2f;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.payment_sum input[type="radio"] + label p {
    margin-top: 10px;
    color: #fff;
    line-height: 12px;
    height: 40px;
    display: flex;
    align-items: center;    
    font-size: 12px;
}

.payment_sum input[type="radio"]:checked + label {
    box-shadow: 0px 10px 38px rgba(75, 58, 41, 0.8), 0px 5px 10px rgba(75, 58, 41, 0.5);
}

.payment_sum input[type="radio"] + label:before,
.payment_system input[type="radio"] + label:before {
    width: 16px;
    height: 16px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.payment_sum input[type="radio"]:checked + label:before {
    background: rgba(0, 0, 0, 0.5);
}

.payment_sum input[type="radio"]:checked + label:after,
.payment_system input[type="radio"]:checked + label:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: #5ec519;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: 16px;
    box-shadow: 0px 0px 10px #469e0c;
}

.payment_sum input[type="radio"]:checked + label {
    -webkit-filter: brightness(1.2);
    -moz-filter: brightness(1.2);
    -o-filter: brightness(1.2);
    -ms-filter: brightness(1.2);
    outline: 3px solid #f08d2f;
    opacity: 0.9;
}

.payment_subscribe input[type="radio"]:checked + label {
    outline: 0px solid #f08d2f;
}

.payment_system input[type="radio"] + label:before {
    background: #d8ba90;
}

.payment_system input[type="radio"]:checked + label:before {
    background: #f0e0c3;
}

.payment_system {
    font-size: 0px;
    padding-bottom: 30px;
}

.payment_system input[type="radio"] + label {
    width: 159px;
    height: 74px;
    padding: 0px;
    padding-top: 10px;
    text-align: center;
    line-height: 50px;
    margin: 4px;
}

.payment_system input[type="radio"]:checked + label {
    background-color: #f7ead4;
}

#game_history .c_bottom {
    background: url(../img/game_history_bg.jpg) center top no-repeat;
    height: 715px;
}

#generaljackpot .c_bottom,
#ladder .c_bottom,
#bonanza .c_bottom {
    padding: 21px;
}

#game_history .c_bottom h4,
#game_ui .c_bottom h4,
#generaljackpot .c_bottom h4,
#ladder .c_bottom h4,
#bonanza .c_bottom h4,
#premios_modal .modal_content h5,
#referral_modal .modal_content h5,
#referral_contest_modal .modal_content h5,
#generaljackpot_modal .modal_content h5,
#ladder_modal .modal_content h5,
#bonanza_modal .modal_content h5,
#tournaments .c_bottom h4,
#laddergames .c_bottom h4,
#support .c_bottom h4,
#terms_modal .modal_content h5,
#privacy_modal .modal_content h5,
#faq_modal .modal_content h5,
#faq_rewards_modal .modal_content h5,
#faq_referral_modal .modal_content h5,
#faq_membership_modal .modal_content h5,
#faq_promo_code_modal .modal_content h5,
#faq_referral_contest_modal .modal_content h5,
#rules_modal .modal_content h5,
#tickets_modal .modal_content h5,
#profile_modal h5,
#rewards .c_bottom h4,
#referral .c_bottom h4,
#noticia_modal .modal_content h5 {
    background: -webkit-linear-gradient(#f4ebd4, #707068);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.prize_td .title,
.prize_card_header .title {
    position: relative;
    font-size: 15px;
    top: 4px;
}

.date-container {
    position: relative;
}

.date-container .date-ranger {
    position: absolute !important;
    right: 0 !important;
    top: 10px !important;
    z-index: 999 !important;
}

#game_history table,
#premios_modal table,
#referral_contest_modal table,
generaljackpot_modal table,
#support table {
    border-collapse: collapse;
    margin-top: 20px;
}

#game_history table td,
#premios_modal table td,
#referral_contest_modal table td,
#generaljackpot_modal table td,
#ladder_modal table td,
#bonanza_modal table td {
    background-color: rgba(90, 44, 47, 0.4);
    color: #fff;
    border-bottom: 1px solid #442225;
    padding: 9px 20px;
}

#game_history table tr {
    cursor: pointer;
    height: 51px;
}

.tr_winner {
    height: 51px;
}

#game_history table td span,
#premios_modal table td span,
#referral_contest_modal table td span,
#generaljackpot_modal table td span {
    position: relative;
    z-index: 3;
}

#game_history table td.td-status {
    position: relative;
}

#game_history table td .remaining_time {
    font-size: 9px;
    font-weight: 700;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#game_history table td .button_reentry {
    height: 40px;
    background: url(../img/button_bg.jpg) center top;
    padding: 2px 8px;
    font-size: 10px;
    box-shadow: inset 0px 0px 20px rgba(55, 38, 11, 0.5), 0px 5px 15px rgba(30, 16, 10, 0.5);
    z-index: 999;
}

#game_history table .th-reentry {
    text-align: center;
}

#game_history table .tb-reentry {
    padding: 0;
    text-align: center;
}

.t_game_icon {
    text-align: center;
}

.t_game_icon img {
    width: 80px;
    margin: -30px;
}

.t_status_icon {
    text-align: right;
}

.t_status_icon img {
    margin: -30px;
    margin-top: -24px;
    width: 60px;
    height: 60px;
}

.entry_pay,
.winner_award {
    color: #fc7a52;
    text-shadow: 2px 1px 0px #000;
    font-size: 16px;
    font-family: "liberation_serifbold";
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
}

.card .entry_pay {
    text-shadow: 0px 2px 3px #171515;
}

.entry_pay .title {
    position: relative;
    top: -8px;
    text-shadow: 0px 2px 3px #171515;
    font-weight: 700;
    /*color: hsl(0deg 0% 88%);*/
    font-family: Open Sans, Arial, sans-serif;
    font-size: 15px;
}

.winner_award {
    color: #70a541 !important;
}

.subtitle td {
    background: url(../img/subtitle_bg.png);
    height: 25px;
    color: #b3928f !important;
}

/* #game_history table tr:after, */

.tr_winner:after {
    content: "";
    display: inline-block;
    width: 929px;
    height: 51px;
    background: url(../img/table_selector.png) no-repeat;
    background-size: cover !important;
    position: absolute;
    margin-left: -840px;
    margin-top: 0px;
    opacity: 0;
    z-index: 99;
}

/* #game_history table tr:hover:after, */

.tr_winner:after {
    opacity: 1;
}

#game_history table tr .effect {
    content: "";
    display: inline-block;
    width: 929px;
    height: 51px;
    background: url(../img/table_selector.png) no-repeat;
    background-size: cover !important;
    position: absolute;
    margin-left: -840px;
    margin-top: 0px;
    opacity: 0;
    z-index: 0;
}

#game_history table tr .effect.effect-active {
    opacity: 1;
}

#game_history .subtitle:hover:after {
    display: none !important;
}

.paginator {
    font-size: 0px;
    text-align: center;
    padding-top: 30px;
    margin-bottom: -20px;
    position: relative;
    z-index: 10;
}

.paginator a,
.paginator span {
    display: inline-block;
    width: 50px;
    height: 50px;
    font-size: 14px;
    color: #b3928f;
    text-decoration: none;
    cursor: pointer;
}

#page_active {
    color: #fff;
    font-weight: bold;
    position: relative;
}

#page_active:before {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    border: 1px solid #9d3f41;
    top: -8px;
    left: 6px;
    box-shadow: inset 0px 0px 10px rgba(147, 46, 48, 0.4), 0px 0px 10px rgba(147, 46, 48, 0.4);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.prev,
.next {
    background: url(../img/menu_icon.png) 50% 50% no-repeat;
    margin-bottom: -20px;
}

.prev {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.game_ui {
    position: relative;
    margin: 0px 20px;
}

#game_ui .game_ui {
    height: 471px;
}

#generaljackpot .game_ui,
#ladder .game_ui,
#bonanza .game_ui {
    height: 1126px;
}

.game_stat,
.game_status {
    font-size: 13px;
    color: #91b3b9;
    position: absolute;
    left: 0px;
    top: 10px;
}

.game_stat span {
    color: #fff;
}

.game_status {
    left: auto;
    right: 0px;
}

.game_status img {
    float: right;
    margin-right: -20px;
    margin-top: -10px;
}

.game_main {
    text-align: center;
}

.game_icon_main {
    margin-top: -40px;
}

.premio {
    text-align: center;
    background: url(../img/premio_bg.png) center 12px no-repeat;
    height: 180px;
    margin-top: -55px;
}

.premio h5 {
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    font-family: Albertus;
    text-transform: uppercase;
    margin-top: -20px;
    margin-bottom: 10px;
}

.premio div {
    color: #f08d2f;
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 18px;
    position: relative;
    padding-left: 60px;
}

#ladder_modal .premio div,
#ladder .premio div,
#bonanza_modal .premio div,
#bonanza .premio div {
    color: #f08d2f;
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 0px;
    position: relative;
    padding-left: 0px;
}

.premio div:before {
    content: "";
    width: 100px;
    height: 97px;
    background: url(../img/coin_icon_big.png) no-repeat;
    position: absolute;
    margin-top: -22px;
    margin-left: -82px;
}

#ladder_modal .premio div:before,
#ladder .premio div:before,
#bonanza_modal .premio div:before,
#bonanza .premio div:before {
    content: none;
    width: 100px;
    height: 97px;
    background: none;
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
}

#ladder_modal .premio img,
#bonanza_modal .premio img {
    width: 70px;
    height: 70px;
}

#ladder .premio img,
#bonanza .premio img {
    width: 70px;
    height: 70px;
    margin-top: -10px;
}

#ladder_modal .entry_pay div,
#bonanza_modal .entry_pay div {
    display: flex;
    align-items: center;
    justify-content: center;
}

#ladder_modal .entry_pay img,
#bonanza_modal .entry_pay img {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
}

.premio .button_regular {
    padding: 0px 30px;
    background: url(../img/button_bg_blue.jpg) center top;
    box-shadow: inset 0px 0px 20px rgba(10, 48, 78, 0.5), 0px 5px 15px rgba(10, 28, 41, 0.5);
}

.game_subtitle {
    font-size: 12px;
    color: #91b3b9;
    text-align: center;
    font-weight: 600;
}

.game_ui_list {
    margin: 0px -23px;
}

.game_invite {
    margin: 0px -23px;
}

#game_ui .c_center {
    background: url(../img/game_ui_center.jpg) center -155px repeat;
}

#game_ui .c_bottom {
    background-image: url(../img/game_ui_bottom.jpg);
    padding: 84px 72px 54px 72px;
}

#game_ui .c_top {
    background-image: url(../img/game_ui_top.jpg);
}

/*
#generaljackpot .c_center {
    background: url(../img/game_ui_center-red.jpg) center -155px repeat;	
}
#generaljackpot .c_bottom {
    background-image: url(../img/game_ui_bottom-red.jpg);
	padding: 84px 72px 54px 72px;
}
#generaljackpot .c_top {
    background-image: url(../img/game_ui_top-red.jpg);
}
#generaljackpot .c_top {
	background-size: 958px 1245px;
}
*/

.game_ui_list {
    position: relative;
    z-index: 99;
}

.game_invite {
    position: relative;
    z-index: 99;
    margin-right: 20px;
}

.game_ui_list table {
    border-spacing: 1px 1px;
    border-collapse: separate;
}

.game_invite table {
    border-spacing: 0px 0px;
    border-collapse: collapse;
    box-shadow: 0px 0px 11px coral;
}

.game_ui_list table td {
    background: url(../img/green_td_1.png);
    padding: 11px 5px 12px 5px;
    border: 1px solid rgba(84, 137, 146, 0.05);
    border-top: 1px solid rgba(84, 137, 146, 0.2);
    color: #fff;
    background-size: contain;
}

.game_invite table td {
    background: url(../img/bg_panel.png) no-repeat center;
    border: 0px solid rgba(84, 137, 146, 0.05);
    border-top: 1px solid rgba(84, 137, 146, 0.2);
    color: #fff;
    text-align: center;
    font-family: "Exocet";
    background-size: cover;
}

.game_ui_list table td span {
    position: relative;
    z-index: 9;
}

.game_invite table td span {
    position: relative;
    z-index: 9;
    font-weight: bold;
    color: #f6b772;
}

.dark_td {
    background: url(../img/green_td_2.png) !important;
    background-size: contain !important;
    border: 1px solid rgba(84, 137, 146, 0.03);
    border-top: 1px solid rgba(84, 137, 146, 0.1);
}

.td_num {
    width: 30px;
    text-align: center;
}

.td_pts {
    width: 140px;
    text-align: center;
}

.td_win > div {
}

.td_award {
    text-align: center;
}

.td_award span {
    color: #f08d2f;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    padding-left: 30px;
}

#ladder .td_award span,
#bonanza .td_award span {
    padding-left: 12px;
}

.td_award span:before {
    left: -11px;
    top: -17px;
}

#ladder .td_award span:before,
#bonanza .td_award span:before {
    left: 0;
    top: 0;
}

.td_award .tour_add {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 10px;
    text-shadow: 2px 1px 0px #000;
}

.tr_winner:after {
    background: url(../img/table_selector_green.png);
    margin-right: 0px;
    margin-top: 0px;
}

.game_ui_warn {
    text-align: center;
    color: #f0ac4b;
    font-size: 13px;
    text-shadow: 0px 0px 15px rgba(255, 86, 20, 0.6);
    margin-bottom: -15px;
    margin-top: 15px;
}

.game_ui_warn span {
    position: relative;
    padding-left: 40px;
}

.game_ui_warn img {
    float: left;
    position: absolute;
    left: -5px;
    top: -16px;
}

/** Modal **/

.modalx-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1003;
    cursor: pointer;
    overflow: auto;
    -webkit-transition: background 0.25s linear;
    -moz-transition: background 0.25s linear;
    transition: background 0.25s linear;
    display: none;
    background: rgba(6, 6, 6, 0.7);
}

.modalx-overlay .d-flex-center {
    height: 100%;
}

.modal_window {
    width: 500px;
    background: url(../img/modal_center.jpg);
    z-index: 1002;
    position: relative;
    cursor: auto;
    margin: 0 auto;
    display: none;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    margin-bottom: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
    top: 80px;
}

.modal_top {
    background: url(../img/modal_top.jpg) center top no-repeat;
}

.modal_bottom {
    background: url(../img/modal_bottom.png) center bottom no-repeat;
}

.modal_content {
    min-height: 300px;
    padding: 70px 70px 80px 70px;
}

#spin_modal .modal_content {
    min-height: 300px;
    padding: 40px 40px 40px 40px;
}

.modal_content h5 {
    font-size: 30px;
}

.modal_button button {
    width: 100%;
    box-sizing: border-box;
}

.modal_form {
    padding: 30px 0px 20px 0px;
}

.modal_form input,
.modal_form textarea {
    margin-bottom: 12px;
    background: rgba(214, 185, 144, 0.6);
}

.modal_form input:focus,
.modal_form textarea:focus {
    background: rgba(214, 185, 144, 0.8);
    border-color: #8b704a;
}

.modal_form textarea {
    outline: none;
    padding: 15px;
    line-height: 16px;
    font-family: Open Sans;
    font-size: 14px;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 200px;
}

.modal_form .select {
    margin-bottom: 12px;
    width: 100%;
    box-sizing: border-box;
}

.modal_form .select button {
    width: 100%;
    box-sizing: border-box;
}

.modal_form .button.bordered_button {
    width: 100%;
    box-sizing: border-box;
    color: #756f69;
    border-color: #756f69;
}

.input_title {
    font-size: 13px;
    margin-bottom: 4px;
}

.inputWithClearButton {
    position: relative;
    width: 100%;
}

.inputWithClearButton button {
    position: absolute;
    right: 2px;
    top: 2px;
    height: calc(100% - 4px);
    width: 40px;
    background-color: transparent;
    color: #f00;
}

.soc_login a,
.soc_login .link-button {
    display: block;
    height: 52px;
    background: linear-gradient(to top, #276cc9, #54b2de);
    color: #fff;
    text-align: center;
    text-decoration: none;
    line-height: 48px;
    font-size: 15px;
    margin-bottom: 5px;
    padding-right: 30px;
    box-shadow: 0px 5px 15px rgba(30, 16, 10, 0.2);
}

.soc_login a img,
.soc_login .link-button img {
    float: left;
    margin-top: 9px;
    margin-left: 9px;
}

.soc_login a span,
.soc_login .link-button span {
    font-weight: 600;
}

#google_button {
    background: linear-gradient(to top, #e2e9ed, #ffffff);
    color: #000;
}

.agreement {
    color: #2c2823;
    font-size: 13px;
    text-align: center;
    padding-top: 25px;
}

.or {
    border-top: 1px solid #d5b78f;
    text-align: center;
    position: relative;
    margin: 30px 0px 20px 0px;
}

.or span {
    display: inline-block;
    margin-top: -10px;
    position: absolute;
    width: 40px;
    left: 50%;
    margin-left: -20px;
    background: url(../img/modal_center.jpg) 50% 50%;
}

#close_modal {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background: url(../img/close.png) no-repeat;
    top: -18px;
    right: -18px;
    z-index: 9;
}

#spin_modal #close_modal {
    top: 40px;
    right: 0px;
}

.modal_top:before {
    width: 500px;
    background: url(../img/modal_logo.png);
    margin-left: -250px;
}

.modal_bottom:before,
.modal_bottom:after {
    background: url(../img/modal_corners.png);
    width: 536px;
}

.modal_bottom:after {
    top: auto;
    bottom: -18px;
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.blur-bg {
    filter: blur(10px);
}

#spin_modal .modal_bottom:before,
#spin_modal .modal_bottom:after {
    background: none;
}

#withdraw_modal .payment_system {
    margin: 15px -10px -10px -10px;
    padding-bottom: 0px;
    text-align: center;
}

#withdraw_modal .payment_system input[type="radio"] + label {
    width: 172px;
}

#withdraw_modal .iconInfo {
    padding-left: 3px;
    cursor: pointer;
}

.success,
.error {
    position: relative;
}

.success:before,
.error:before {
    content: "";
    display: block;
    width: 37px;
    height: 34px;
    background: url(../img/ok_icon.png) no-repeat;
    position: absolute;
    top: 7px;
    right: 7px;
}

.error:before {
    background: url(../img/error_icon.png) no-repeat;
}

.error input {
    border-color: #ef8062;
    color: #f0462f;
}

.error_reason {
    font-size: 13px;
    color: #ed452f;
    background: rgba(0, 0, 0, 0.95) url(../img/error_alert.png) left center no-repeat;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    padding: 24px 20px 24px 70px;
    position: absolute;
    right: -359px;
    top: -17px;
    width: 240px;
}

.error_reason:before {
    content: "";
    position: absolute;
    left: -16px;
    top: 33px;
    border: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.95);
}

.success_reason {
    font-size: 13px;
    color: green;
    background: rgba(0, 0, 0, 0.95) url(../img/ok_icon.png) left center no-repeat;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    padding: 24px 20px 24px 70px;
    position: absolute;
    right: -359px;
    top: -17px;
    width: 240px;
}

.success_reason:before {
    content: "";
    position: absolute;
    left: -16px;
    top: 33px;
    border: 8px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.95);
}

#premios_modal,
#referral_modal,
#referral_contest_modal,
#generaljackpot_modal,
#ladder_modal,
#bonanza_modal {
    background: url(../img/modal_center_red.jpg) center;
}

#spin_modal {
    background: none;
}

#spin_modal .spin-right {
    margin-left: -48px;
}

#spin_modal .spin-right img {
}

#spin_modal .spin-right .spin-text {
    font-size: 24px;
    margin-bottom: 70px;
}

#spin_modal .spin-container .spin-mobile {
    display: none;
}

#spin_modal .spin-result-container {
    margin-top: -60px;
    margin-left: 40px;
    margin-right: 40px;
}

#spin_modal .spin-result {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    }

.spin-latest-prize-container {
    font-size: 16px;
    font-weight: 700;
    color: white;
    padding: 8px;
    text-align: center;
    text-shadow: 3px 3px 4px #000;
}

#spin_modal .spin-result-item-container {
    width: 138px;
    min-width: 138px;
    max-height: 138px;
    height: 98px;
    min-height: 98px;
    max-height: 98px;
}

#spin_modal .spin-result-item-container.highlight {
    background: url(../img/spin/spin-result-item-border.png) center bottom no-repeat;
    animation: spin-result-item-highlight 1s infinite;
}


#spin_modal .spin-result-item {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    color: #f08d2f;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 4px 0px 4px;
    margin:8px;
    border-radius: 20px;
    background: #7b350d34;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
    position: relative;
}

@keyframes spin-result-item-highlight {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

#spin_modal .spin-result-item .user-info{
    color: white;
    font-size: 14px;
}

#spin_modal .spin-result-item .user-avartar {
    width: 36px;
    height: 36px;
    padding: 4px;
    margin-right: 8px;
}

#spin_modal .spin-result-item .img-coin {
    width: 44px;
    height: 44px;
    margin-top: -10px;
    margin-left: -4px;
    margin-right: -4px;
}

#premios_modal .modal_top,
#referral_modal .modal_top,
#referral_contest_modal .modal_top,
#generaljackpot_modal .modal_top,
#ladder_modal .modal_top,
#bonanza_modal .modal_top {
    background: none;
}

#spin_modal .modal_top {
    background: none;
}
#spin_modal .c_bottom:before {
    background: none;
}

#premios_modal .modal_bottom,
#referral_modal .modal_bottom,
#referral_contest_modal .modal_bottom,
#generaljackpot_modal .modal_bottom,
#ladder_modal .modal_bottom,
#bonanza_modal .modal_bottom {
    background: url(../img/modal_bottom_red.png) center bottom no-repeat;
}

#spin_modal .modal_bottom {
    background: none;
}

#spin_modal.modal_window {
    width: 1000px;
    box-shadow: none;
}

#spin_modal .modal_content .spin-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#premios_modal .premio,
#referral_contest_modal .premio,
#generaljackpot_modal .premio,
#ladder_modal .premio,
#bonanza_modal .premio {
    margin-top: 0px;
    height: 130px;
    padding-top: 34px;
    background-image: url(../img/premio_bg_modal.png);
    margin-bottom: 30px;
}

#ladder_modal .premio,
#bonanza_modal .premio {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
}

#premios_modal table,
#referral_contest_modal table,
#generaljackpot_modal table {
    margin: 0px;
    margin-bottom: 15px;
}

#premios_modal table .entry_pay,
#referral_contest_modal table .entry_pay,
#generaljackpot_modal table .entry_pay {
    text-align: right;
}

#premios_modal table .entry_pay .tour_add,
#referral_contest_modal table .entry_pay .tour_add,
#generaljackpot_modal table .entry_pay .tour_add {
    position: relative;
    top: 0;
    right: 0;
    margin-left: 10px;
    text-shadow: 2px 1px 0px #000;
}

#premios_modal .subtitle,
#referral_contest_modal .subtitle,
#generaljackpot_modal .subtitle {
    font-weight: 600;
}

.vs_player {
    text-align: center;
    color: #b3928f;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.vs_player h6 {
    font-size: 22px;
    font-family: Albertus;
    font-size: 24px;
    color: #eb2d27;
    margin: 0px;
    letter-spacing: 1px;
}

#premios_modal .modal_content,
#referral_modal .modal_content,
#referral_contest_modal .modal_content,
#generaljackpot_modal .modal_content {
    padding: 70px;
}

#game_page .c_bottom:before {
    width: 325px;
    height: 14px;
    background: url(../img/border_top.png) no-repeat;
    top: -14px;
    margin-left: -162px;
}

.game_page_icon {
    width: 248px;
    height: 248px;
    position: absolute;
    left: 50%;
    margin-left: -124px;
    top: -115px;
}

.game_page_icon img {
    width: 248px;
    height: 248px;
}

#game_page .bordered_button {
    float: right;
    margin: -3px 0px;
}

.level h6 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 10px;
}

.level h6 span {
    color: #f08d2f;
}

.level {
    text-align: center;
    width: 400px;
    margin: 30px auto 0px auto;
}

.progressbar {
    width: 100%;
    background: #201011;
    color: #fff;
    height: 20px;
    line-height: 20px;
    border-radius: 12px;
    padding: 2px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
}

.progressbar > div {
    height: 20px;
    background: #f08d2f;
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px rgba(255, 197, 72, 1), 0px 0px 20px rgba(239, 90, 0, 0.6);
}

#game_page .eagle,
#support .eagle {
    display: none;
}

/** Tournaments **/

#tournaments .c_top,
#rewards .c_top,
#referral .c_top {
    width: 958px;
    background: url(../img/tournament_top.jpg) no-repeat;
    position: relative;
}

#tournaments .c_bottom,
#rewards .c_bottom,
#referral .c_bottom {
    min-height: 715px;
    background: url(../img/tournament_bottom.jpg) center bottom no-repeat;
    margin-bottom: 120px;
    padding: 84px 72px;
    color: #756f69;
}

#tournaments .c_center,
#rewards .c_center,
#referral .c_center {
    margin: 220px auto 0px auto;
    width: 958px;
    background: url(../img/tournament_center.jpg) center -155px repeat;
    height: auto;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
}

#spin_modal .c_top  {
    width: 958px;
    background: url(../img/spin_top.png) no-repeat;
    position: relative;
}

#spin_modal .c_bottom {
    min-height: 415px;
    background: url(../img/spin_bottom.png) center bottom no-repeat;
    margin-bottom: 0px;
    padding: 30px 0px 30px 0px;
    color: #756f69;
}

#spin_modal .c_center {
    margin: 0;
    width: 958px;
    background: url(../img/spin_center.png) center -155px repeat;
    height: auto;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
}

#generaljackpot .c_top,
#ladder .c_top,
#bonanza .c_top {
    width: 958px;
    background: url(../img/generaljackpot_top.jpg) no-repeat;
    position: relative;
}

#generaljackpot.orange .c_top {
    background: url(../img/generaljackpot_orange_top.jpg) no-repeat;
}

#generaljackpot .c_bottom,
#ladder .c_bottom,
#bonanza .c_bottom {
    /*width: 814px;*/
    min-height: 715px;
    background: url(../img/generaljackpot_bottom.jpg) center bottom no-repeat;
    margin-bottom: 120px;
    /*padding: 84px 72px;*/
    color: #756f69;
}

#generaljackpot.orange .c_bottom {
    background: url(../img/generaljackpot_orange_bottom.jpg) center bottom no-repeat;
}

#generaljackpot .cloth,
#ladder .cloth,
#bonanza .cloth {
    background: url(../img/generaljackpot_h_center.png) center no-repeat;
    background-size: 100% 100%;
    background-position-y: 73px;
}

#generaljackpot.orange .cloth {
    background: url(../img/generaljackpot_orange_h_center.png) center no-repeat;
    background-size: 100% 100%;
    background-position-y: 73px;
}

#generaljackpot .cloth::before,
#ladder .cloth::before,
#bonanza .cloth::before {
    content: "";
    background: url(../img/generaljackpot_h_top.png) center no-repeat;
    background-size: 100% 100%;
    width: 916px;
    height: 73px;
    position: absolute;
}

#generaljackpot.orange .cloth::before {
    background: url(../img/generaljackpot_orange_h_top.png) center no-repeat;
}

#generaljackpot .cloth::after,
#ladder .cloth::after,
#bonanza .cloth::after {
    content: "";
    background: url(../img/generaljackpot_h_bottom.png) center no-repeat;
    background-size: 100% 100%;
    width: 916px;
    height: 73px;
    position: absolute;
}

#generaljackpot.orange .cloth::after {
    background: url(../img/generaljackpot_orange_h_bottom.png) center no-repeat;
}

#generaljackpot .game_ui_list,
#ladder .game_ui_list,
#bonanza .game_ui_list {
    padding: 0px 42px;
    padding-top: 100px;
}

#generaljackpot .game_ui_warn,
#ladder .game_ui_warn,
#bonanza .game_ui_warn {
    padding-bottom: 42px;
}

#generaljackpot .c_center,
#ladder .c_center,
#bonanza .c_center {
    margin: 220px auto 0px auto;
    width: 958px;
    background: url(../img/generaljackpot_center.jpg) center -155px repeat;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
}

#generaljackpot.orange .c_center {
    background: url(../img/generaljackpot_orange_center.jpg) center -155px repeat;
}

#tournaments .c_bottom h4,
#rewards .c_bottom h4,
#referral .c_bottom h4 {
    font-size: 24px;
    margin-top: -30px;
}

#rewards .c_bottom h4,
#referral .c_bottom h4 {
    font-size: 36px;
}

#tournaments .c_bottom:before {
    background: url(../img/tournaments_title.png) no-repeat;
}

.game_image {
    position: absolute;
    width: 248px;
    height: 248px;
    left: 50%;
    margin-left: -124px;
    top: -130px;
    z-index: 3;
}

#tournaments table,
#tournaments .card {
    color: #b4cbdb;
}

/*#tournaments .card {
	bottom: 0px;
    top: 0px;
    position: absolute;
    left: 15px;
    right: 15px;
}*/

#tournaments table {
    border-collapse: collapse;
    margin-top: 20px;
}

#tournaments table td {
    padding: 11px 20px;
    background: rgba(73, 99, 123, 0.2);
    border-top: 1px solid #233444;
    height: 100%;
}

#tournaments .card {
    background: rgba(73, 99, 123, 0.2);
    border-top: 1px solid #233444;
}

#tournaments table td span {
    position: relative;
    z-index: 5;
}

#tournaments .subtitle td {
    color: #86949f !important;
    background: url(../img/subtitle_bg_2.png);
    border: none;
    height: 28px;
}

.custom_title {
    font-weight: 1000;
    text-shadow: 2px 2px 6px black;
    /* color: #87a52f; */
}

.tour_info {
    font-size: 12px;
}

.tour_info b {
    display: block;
}

.tour_info a {
    color: #fff;
}

.tour_info .iconFast {
    cursor: pointer;
    filter: drop-shadow(1px 2px 2px black);
}

.tour_info .iconFast img {
    top: 0px;
    padding-left: 3px;
    position: relative !important;
    width: 30px;
    height: 30px;
}

.tour_info .iconInfo {
    padding-left: 3px;
    cursor: pointer;
}

.game_ui_list .iconInfo {
    padding-left: 3px;
    cursor: pointer;
}

.tour_info .card {
    font-size: 14px;
}

.mobile-only .tour_info .mobile-icon-box {
    display: flex;
}

.mobile-only .tour_info .mobile-icon-box .mobile-label {
    font-size: 9px;
    color: rgb(255, 198, 0);
    font-weight: bold;
    margin: auto;
}

.more_info {
    color: #fc7a52 !important;
    padding-left: 13px;
    background: url(../img/plus_icon.png) left 4px no-repeat;
}

.faq_td {
    text-align: right;
    padding-right: 0px !important;
}

.faq_info {
    color: #fff !important;
    font-size: 11px;
    padding: 2px 0px 2px 22px;
    font-weight: bold;
    font-family: Albertus;
    background: url(../img/faq_icon.png) left 1px no-repeat;
}

.keep_training {
    text-align: right;
    color: #fc7a52;
    font-weight: 600;
    width: 120px;
    padding-left: 0px !important;
    position: relative;
}

.keep_training span {
    padding-left: 18px;
    background: url(../img/lock_icon.png) left 3px no-repeat;
}

.keep_training .lock-chain {
    position: absolute;
    background-image: url(../img/turnament_lock.png) !important;
    top: -9px;
    left: 12px;
    z-index: 10;
    height: calc(100% + 30px);
    width: 100%;
    background-size: 100% 100%;
}

.mobile-only {
    position: relative;
}

.mobile-only .keep_training {
    cursor: pointer;
}

.mobile-only .bg-padlock {
    background-image: url(../img/turnament_lock_border.png) !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: -15px;
    left: -13px;
    height: calc(100% + 30px);
    width: calc(100% + 26px);
}

.mobile-only .bg-padlock.freerolls {
    top: -30px;
    height: calc(100% + 60px);
}

.mobile-only .keep_training:after {
    content: "";
    display: block;
    background: url(../img/turnament_lock_shadow.png) no-repeat;
    background-size: cover !important;
    width: 270px;
    position: absolute;
    right: 0;
    top: 0px;
    bottom: 0px;
    z-index: 1;
}

.rules_td {
    width: 110px;
    padding-right: 5px !important;
    height: 56px;
}

.prize_td span,
.prize_card_header span {
    display: inline-block;
    position: relative;
}

.sign_length_1 {
    padding-left: 10px;
}

.sign_length_2 {
    padding-left: 22px;
}

.sign_length_3 {
    padding-left: 34px;
}

.prize_td span img,
.prize_card_header span img {
    width: 20px;
    height: 22px;
    margin-top: 8px;
    margin-left: -6px;
}

.prize_td:before,
.prize_card_header:before {
    background: url(../img/prize_bg.jpg) no-repeat !important;
    background-size: cover !important;
    content: " ";
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.prize_td,
.prize_card_header {
    position: relative;
    text-shadow: 0px 2px 3px #171515;
    font-size: 30px;
    font-weight: 700;
    color: hsl(0deg 0% 88%);
}

.prize_td {
    width: 170px;
    min-height: 77px;
}

.prize_td p,
.prize_td b,
.prize_card_header p,
.prize_card_header b {
    font-size: 18px;
    margin-top: 5px;
    display: inline-block;
    position: absolute;
}

.prize_td p,
.prize_card_header p {
    /*top: -16px;*/
    left: -2px;
}

.tour_add {
    text-shadow: 2px 2px 3px #363636;
    font-size: 12px;
    position: absolute;
    color: #fff;
    top: 4px;
    right: 4px;
    padding-top: 5px;
    padding-right: 18px;
    background: url(../img/gem.png) right 2px no-repeat;
    background-size: contain;
}

.tour_add_bonanza {
    text-shadow: 2px 2px 3px #363636;
    font-size: 12px;
    position: absolute;
    color: #fff;
    top: 4px;
    right: 4px;
    padding-top: 5px;
    padding-right: 18px;
}

.ic_stat {
    background: url(../img/icon_stat.png) right 2px no-repeat;
}

#tournaments .deposit_button {
    width: 104px;
    background: url(../img/details_button.png) no-repeat;
}

#tournaments .enter_button,
.support .enter_button,
.copy-link-button {
    background: url(../img/enter_button.png) no-repeat;
    background-size: cover;
    text-shadow: 1px 1px #649022;
    box-shadow: 0 1px 2px 0px #252525;
    position: relative;
    z-index: 5;
}

#tournaments .enter_button {
    height: 40px;
}

#tournaments .card .enter_button {
    width: 100%;
    height: 50px;
    background-size: cover;
    font-size: 15px;
}

#tournaments .enter_button:active,
.support .enter_button:active {
    box-shadow: 0px 0px 2px 0px #252525 !important;
    transform: translateY(1px);
    transition: 0ms;
}

.featured td,
.featured .card {
    background: rgba(225, 174, 84, 0.2) !important;
}

.mega-featured td,
.mega-featured .card {
    background: rgba(225, 84, 84, 0.2) !important;
}

.mega-featured-orange td,
.mega-featured-orange .card {
    background: rgba(239, 138, 55, 0.2) !important;
    /*feb172 ef8a37 fd7507*/
}

.ladder td,
.ladder .card {
    background: rgba(232, 53, 238, 0.2) !important;
}

.bonanza td,
.bonanza .card {
    background: rgba(50, 191, 246, 0.2) !important;
}

.spin td,
.spin .card {
    background: rgba(251, 11, 123, 0.329) !important;
}

.reentry td,
.reentry .card {
    background: rgba(159, 225, 84, 0.2) !important;
}

.referral_contest {
    /*box-shadow: -4px 6px 14px -2px #000000;*/
}

.referral_contest td,
.referral_contest .card {
    /*background: rgba(27, 0, 255, 18%) !important;*/
}

.reentry .prize_td:before,
.reentry .prize_card_header:before {
    background: url(../img/prize_bg.jpg) no-repeat !important;
    background-size: cover !important;
    content: " ";
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.prize_card_header:before {
    border-top-right-radius: 0.3rem;
}

.featured .prize_td:before,
.featured .prize_card_header:before {
    background: url(../img/prize_bg_2.jpg) no-repeat !important;
    background-size: cover !important;
    content: " ";
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.mega-featured .prize_td:before,
.mega-featured .prize_card_header:before {
    background: url(../img/prize_megajackpot_bg.jpg) no-repeat !important;
    background-size: cover !important;
    content: " ";
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.mega-featured-orange .prize_td:before,
.mega-featured-orange .prize_card_header:before {
    background: url(../img/prize_megajackpot_orange_bg.jpg) no-repeat !important;
    background-size: cover !important;
    content: " ";
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.spin .prize_td:before,
.spin .prize_card_header:before {
    background: url(../img/prize_spin_bg.jpg) no-repeat !important;
    background-size: cover !important;
    content: " ";
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.ladder .prize_td:before,
.ladder .prize_card_header:before {
    background: url(../img/prize_ladder_bg.jpg) no-repeat !important;
    background-size: cover !important;
    content: " ";
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.bonanza .prize_td:before,
.bonanza .prize_card_header:before {
    background: url(../img/prize_bonanza_bg.jpg) no-repeat !important;
    background-size: cover !important;
    content: ' ';
    display: block;
    filter: opacity(50%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.featured .tour_add,
.mega-featured .tour_add,
.mega-featured-orange .tour_add {
    background: url(../img/gem.png) right 2px no-repeat;
    background-size: contain;
}

.featured .ic_stat,
.mega-featured .ic_stat,
.mega-featured-orange .ic_stat {
    background: url(../img/icon_stat_b.png) right 2px no-repeat;
}

.featured .prize_td:after,
.mega-featured .prize_td:after,
.mega-featured-orange .prize_td:after,
.mobile-only .prize_td:after,
.reentry .prize_td:after {
    content: "";
    display: block;
    width: 814px;
    position: absolute;
    left: -105px;
    top: 0px;
    bottom: 0px;
    z-index: 4;
}

.featured .card:after,
.mega-featured .card:after,
.mega-featured-orange .card:after,
.reentry .card:after {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 4;
    border-radius: 0.3rem;
}

.featured .card:after {
    background: url(../img/featured_bg.png) no-repeat center;
    background-size: cover;
}

.mega-featured .card:after {
    background: url(../img/featured_megajackpot_bg.png) no-repeat center;
    background-size: cover;
}

.mega-featured-orange .card:after {
    background: url(../img/featured_megajackpot_orange_bg.png) no-repeat center;
    background-size: cover;
}

.reentry .card:after,
.referral_contest .card:after {
    background: url(../img/reentry_highlight.png) no-repeat center;
    background-size: cover;
}

.featured .prize_td:after,
.mega-featured .prize_td:after,
.mega-featured-orange .prize_td:after,
.reentry .prize_td:after,
.mobile-only .prize_td:after,
.featured .card:after,
.mega-featured .card:after,
.mega-featured-orange .card:after,
.reentry .card:after,
.mobile-only .card:after {
    -webkit-filter: opacity(50%);
    -moz-filter: opacity(50%);
    -o-filter: opacity(50%);
    -ms-filter: opacity(50%);
    filter: opacity(50%);
}

.featured .prize_td:after {
    background: url(../img/featured_bg.png) no-repeat;
    background-size: cover;
}

.mega-featured .prize_td:after {
    background: url(../img/featured_megajackpot_bg.png) no-repeat;
    background-size: cover;
}

.mega-featured-orange .prize_td:after {
    background: url(../img/featured_megajackpot_orange_bg.png) no-repeat;
    background-size: cover;
}

/* .mobile-only .prize_td:after {
    background: url(../img/turnament_lock_border.png) no-repeat !important;
    background-size: cover;
    position: absolute;
    top: -13px;
    left: -117px;
    width: 838px;
    height: 100px;
    filter: opacity(100%);
} */

.reentry .prize_td:after,
.referral_contest .prize_td:after {
    background: url(../img/reentry_highlight.png) no-repeat;
    background-size: cover;
}

.featured.highlight .prize_td:after,
.mega-featured.highlight .prize_td:after,
.mega-featured-orange.highlight .prize_td:after,
.featured.highlight .card:after,
.mega-featured.highlight .card:after,
.mega-featured-orange.highlight .card:after {
    animation: highlightFadeTr 1.5s alternate infinite;
}

@keyframes highlightFadeTr {
    from {
    }
    to {
        -webkit-filter: opacity(100%);
        -moz-filter: opacity(100%);
        -o-filter: opacity(100%);
        -ms-filter: opacity(100%);
        filter: opacity(100%);
    }
}

.remaining_time {
    font-weight: bold;
}

/*.highlight .remaining_time {
	animation: highlightFadeRemainingTime 1.5s alternate infinite;
	font-size: 14px;
}*/

.iconTimeRemaining {
    padding-right: 3px;
}

.highlight .iconTimeRemaining {
    animation: highlightFadeRemainingTime 1.5s alternate infinite;
}

@keyframes highlightFadeRemainingTime {
    from {
    }
    to {
        color: red;
    }
}

.d_flex {
    display: flex;
}

.d-flex-1 {
    flex: 1;
}

#tournaments .entry_pay span {
    flex: 1;
}

#tournaments .entry_pay .strike {
    flex: 1;
    text-decoration: line-through;
}

#tournaments .entry_pay .free_icon {
    margin-top: -8px;
    margin-left: 8px;
}

#tournaments .entry_pay {
    width: 105px;
}

#tournaments .reamaining {
    width: 130px;
}

.level_2 {
    color: #fff;
    padding-top: 10px;
    /* padding-bottom: 14px; */
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 600;
    text-shadow: 3px 2px 3px #4a0c0c;
    text-align: center;
    font-family: "Albertus";
}

.level_2 > span {
    color: #f08d2f;
}

.level_2 > span > img {
    margin-bottom: 0px;
}

.bar_level {
    width: 261px;
    height: 40px;
    background: url(../img/bar.png) no-repeat;
    background-position: 0px 20px;
    margin: 0px auto;
    position: relative;
    overflow: hidden;
    padding: 0px 0px 0px 11px;
    margin-top: -10px;
}

.mb_12 {
    margin-bottom: 12px;
}

.bar_level div {
    background: url(../img/progress_bar.png) right top;
    height: 39px;
    margin-top: 10px;
    text-align: right;
    font-size: 10px;
}

.bar_level div span {
    color: #61bb11;
}

.d-flex-ver {
    display: flex;
    flex-direction: column;
}

.d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-flex-center-j {
    display: flex;
    justify-content: center;
}

.d-flex-ver {
    display: flex;
    flex-direction: column;
}

.d-flex-v-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.membership-expiration {
    background: #0d0b0b;
    margin-top: 16px;
    padding: 8px 10px;
}

.membership-expiration span {
    text-align: center;
    font-size: 18px;
    font-family: "Exocet";
    text-transform: uppercase;
    background: -webkit-linear-gradient(#dba76d, #875e31);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.membership-expiration .real-time {
    font-size: 12px;
}

.membership-bar {
    height: 40px;
    width: 504px;
    background: url(../img/membership/membership_progress_bg.png) no-repeat;
    margin: 0px;
    position: relative;
    overflow: hidden;
}

.membership-image {
    width: 100px;
}

.membership-next-image {
    width: 100px;
}

.membership-clock-image {
    height: 25px;
    margin-right: 8px;
}

.membership-point {
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-family: "Exocet";
    text-transform: uppercase;
    background: -webkit-linear-gradient(#dba76d, #875e31);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.membership-bar .membership-fr {
    width: 100%;
    height: 100%;
    background: url(../img/membership/membership_progress_fr.png) left;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mb_12 {
    margin-bottom: 12px;
}

.membership-bar .membership-progress {
    background: url(../img/membership/membership_progress_bar.png) left;
    height: 24px;
    margin-top: 8px;
    text-align: left;
    font-size: 10px;
}

.membership-next {
    font-size: 20px;
    margin-top: -10px;
    font-family: "Albertus";
    font-weight: 700;
    color: white;
}

.membership-bar div span {
    color: #61bb11;
}

.game-membership-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.game-membership-container img {
    width: 130px;
}

.game-membership-container span {
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 24px;
    font-family: "Exocet";
    text-align: center;
    text-transform: uppercase;
    color: #debdbd;
    text-shadow: 0px 1px 2px black, 0 0 1em #020008, 0 0 0.2em #000000;
}

.faq_help {
    position: absolute;
    right: 70px;
    top: 60px;
    z-index: 100;
}

.membership {
    position: absolute;
    left: 70px;
    top: 60px;
}

.faq_help button {
    margin-left: 20px;
}

#support h4 {
    text-align: left;
    font-size: 30px;
}

#support .faq_help {
    top: 70px;
}

#support table td {
    border-top: 1px solid #e2c599;
    padding: 9px 20px;
    font-size: 13px;
    color: #fff;
}

#support .subtitle td {
    background: url(../img/green_td_3.png) !important;
    height: 25px;
    padding: 9px 20px;
    border: none;
    color: #91b3b9 !important;
}

#support table tr td {
    background: rgba(47, 103, 100, 0.3);
    border-top: 1px solid #223c3c;
}

#support .bordered_button {
    width: auto;
    padding: 0px 20px;
}

#support .button_regular,
#tickets_modal .button_regular {
    padding: 0px 25px;
    height: 40px;
}

#support #page_active {
    color: #fff !important;
}

#support #page_active:before {
    border-color: #f08d2f;
}

.support_button {
    text-align: right;
}

.ticket_num {
    text-align: center;
}

.ticket_num span {
    background: rgba(255, 255, 255, 0.2);
    padding: 4px 10px;
    border-radius: 3px;
}

.ticket_cat {
    font-weight: 600;
}

.ticket_status {
    position: relative;
}

.ticket_status span {
    padding-left: 36px;
    white-space: nowrap;
}

.ticket_status:before {
    content: "";
    display: block;
    width: 48px;
    height: 48px;
    background: url(../img/message_icon.png) no-repeat;
    position: absolute;
    left: 4px;
    top: 6px;
}

.read .ticket_status:before {
    background: url(../img/read_icon.png) no-repeat;
}

.read td {
    background-color: rgba(195, 232, 99, 0.3);
}

.closed .ticket_status:before {
    background: url(../img/closed_icon.png) no-repeat;
}

#support #page_active {
    color: #756f69;
}

#support #page_active:before {
    box-shadow: none;
}

#terms_modal,
#privacy_modal,
#faq_modal,
#faq_rewards_modal,
#faq_referral_modal,
#faq_membership_modal,
#faq_promo_code_modal,
#faq_referral_contest_modal,
#rules_modal,
#tickets_modal,
#notify_game_mobile_only_modal,
#noticia_modal {
    width: 958px;
    background: url(../img/tournament_center.jpg) center;
}

#terms_modal .modal_top,
#privacy_modal .modal_top,
#faq_modal .modal_top,
#faq_rewards_modal .modal_top,
#faq_referral_modal .modal_top,
#faq_membership_modal .modal_top,
#faq_promo_code_modal .modal_top,
#faq_referral_contest_modal .modal_top,
#rules_modal .modal_top,
#tickets_modal .modal_top,
#notify_game_mobile_only_modal .modal_top,
#noticia_modal .modal_top {
    background: url(../img/tournament_top.jpg) center top no-repeat;
}

#terms_modal .modal_bottom,
#privacy_modal .modal_bottom,
#faq_modal .modal_bottom,
#faq_rewards_modal .modal_bottom,
#faq_referral_modal .modal_bottom,
#faq_membership_modal .modal_bottom,
#faq_promo_code_modal .modal_bottom,
#faq_referral_contest_modal .modal_bottom,
#rules_modal .modal_bottom,
#tickets_modal .modal_bottom,
#notify_game_mobile_only_modal .modal_bottom,
#noticia_modal .modal_bottom {
    background: url(../img/tournament_bottom.jpg) center bottom no-repeat;
}

#terms_modal .modal_bottom:after,
#privacy_modal .modal_bottom:after,
#faq_modal .modal_bottom:after,
#faq_rewards_modal .modal_bottom:after,
#faq_referral_modal .modal_bottom:after,
#faq_membership_modal .modal_bottom:after,
#faq_promo_code_modal .modal_bottom:after,
#faq_referral_contest_modal .modal_bottom:after,
#rules_modal .modal_bottom:after,
#tickets_modal .modal_bottom:after,
#notify_game_mobile_only_modal .modal_bottom:after,
#noticia_modal .modal_bottom:after {
    background: url(../img/profile_corners_bottom.png);
    width: 994px;
    moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

#terms_modal .modal_bottom:before,
#privacy_modal .modal_bottom:before,
#faq_modal .modal_bottom:before,
#faq_rewards_modal .modal_bottom:before,
#faq_referral_modal .modal_bottom:before,
#faq_membership_modal .modal_bottom:before,
#faq_promo_code_modal .modal_bottom:before,
#faq_referral_contest_modal .modal_bottom:before,
#rules_modal .modal_bottom:before,
#tickets_modal .modal_bottom:before,
#notify_game_mobile_only_modal .modal_bottom:before,
#noticia_modal .modal_bottom:before {
    background: url(../img/profile_corners.png);
    width: 994px;
}

#tickets_modal {
    background: url(../img/support_center.jpg);
}

#tickets_modal .modal_top {
    background: url(../img/support_top.jpg) center top no-repeat;
}

#tickets_modal .modal_bottom {
    background: url(../img/support_bottom.jpg) center bottom no-repeat;
}

#tickets_modal h5 {
    text-align: left;
}

.ticket_time {
    font-size: 14px;
    color: #91b3b9;
}

.terms_text {
    margin-top: -20px;
    height: 600px;
    overflow-y: scroll;
}

.terms_text::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(0, 0, 0, 0.1);
}

.terms_text::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
}

.terms_text::-webkit-scrollbar-track {
    border-width: 0;
}

.terms_scroll {
    color: #fff;
    font-size: 13px;
}

#support .c_center {
    background-image: url(../img/support_center.jpg);
}

#support .c_top {
    background-image: url(../img/support_top.jpg);
}

#support .c_bottom {
    background-image: url(../img/support_bottom.jpg);
}

.chat_message {
    margin-bottom: 20px;
}

.cm_ava {
    width: 46px;
    height: 46px;
    float: left;
    border-radius: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.cm_ava img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.cm_message {
    margin-left: 70px;
}

.message {
    color: #fff;
    background: rgba(154, 219, 55, 0.2);
    padding: 16px 20px 18px 20px;
    border-radius: 5px;
    position: relative;
}

.message:before {
    content: "";
    position: absolute;
    left: -16px;
    top: 18px;
    border: 8px solid transparent;
    border-right: 8px solid rgba(154, 219, 55, 0.2);
}

.m_creator {
    font-weight: 700;
    margin-bottom: 10px;
}

.m_creator a {
    color: #a2d037;
}

.m_date {
    color: #91b3b9;
    margin-top: 10px;
}

.my_message .message {
    background: rgba(255, 255, 255, 0.1);
    text-align: right;
}

.my_message .message:before {
    border-left: 8px solid rgba(255, 255, 255, 0.1);
    border-right: none;
    right: -8px;
    left: auto;
    top: 18px;
}

.my_message .m_creator a {
    color: #fc7a55;
}

.my_message .cm_ava {
    float: right;
}

.my_message .cm_message {
    margin-right: 70px;
    margin-left: 0px;
}

.add_reply .message {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
}

.add_reply .message:before {
    border-right: 8px solid rgba(255, 255, 255, 0.1);
    border-left: none;
    left: -8px;
    right: auto;
}

.add_reply .message textarea {
    height: 100px;
    margin: 0px;
    color: #fff;
    background: rgba(21, 44, 43, 0.3);
    border: none;
}

#tickets_modal .modal_form {
    padding-bottom: 0px;
}

#tickets_modal .modal_content {
    padding-bottom: 50px;
}

/** Fire Animations **/

.sparks {
    position: absolute;
    width: 1282px;
    left: 50%;
    margin-left: -800px;
    top: -60px;
}

.wheel-container .sparks {
    position: absolute;
    width: 1282px;
    left: 50%;
    margin-left: -800px;
    top: 50%;
}

.sparks > div {
    position: absolute;
}

.sparks .spark_1 {
    background: url(../img/spark_1.png) no-repeat;
    width: 764px;
    height: 313px;
    right: 0;
    bottom: -320px;
    transform: scale(0.6);
    animation: spark-1 4s linear infinite;
    animation-delay: 1s;
}

@keyframes spark-1 {
    0% {
        bottom: -320px;
        transform: scale(0.6);
        opacity: 1;
    }
    25% {
        bottom: -240px;
        transform: scale(0.7);
        opacity: 1;
    }
    50% {
        bottom: -160px;
        transform: scale(0.8);
        opacity: 1;
    }
    75% {
        bottom: -80px;
        transform: scale(0.9);
        opacity: 0.5;
    }
    100% {
        bottom: 0px;
        transform: scale(1);
        opacity: 0;
    }
}

.sparks .spark_2 {
    background: url(../img/spark_2.png) no-repeat;
    width: 179px;
    height: 335px;
    right: 230px;
    bottom: -320px;
    transform: scale(0.6);
    animation: spark-1 4s linear infinite;
    animation-delay: 2s;
}

.sparks .spark_3 {
    background: url(../img/spark_3.png) no-repeat;
    width: 128px;
    height: 165px;
    right: 280px;
    bottom: -140px;
    transform: scale(0.6);
    animation: spark-3 4s linear infinite;
    animation-delay: 2s;
}

@keyframes spark-3 {
    0% {
        bottom: -140px;
        transform: scale(0.6);
        opacity: 1;
    }
    25% {
        bottom: -80px;
        transform: scale(0.7);
        opacity: 1;
    }
    50% {
        bottom: -20px;
        transform: scale(0.8);
        opacity: 1;
    }
    75% {
        bottom: 40px;
        transform: scale(0.9);
        opacity: 0.5;
    }
    100% {
        bottom: 100px;
        transform: scale(1);
        opacity: 0;
    }
}

.sparks .spark-big {
    background: url(../img/spark_4.png) no-repeat;
    width: 794px;
    height: 176px;
    right: 0;
    bottom: -180px;
    transform: scale(0.6);
    animation: spark-5 4s linear infinite;
}

.sparks .spark_5 {
    animation-delay: 2s;
    right: 40px;
}

@keyframes spark-5 {
    0% {
        bottom: -180px;
        transform: scale(0.6);
        opacity: 1;
    }
    25% {
        bottom: -120px;
        transform: scale(0.7);
        opacity: 1;
    }
    50% {
        bottom: -60px;
        transform: scale(0.8);
        opacity: 1;
    }
    75% {
        bottom: 0px;
        transform: scale(0.9);
        opacity: 0.5;
    }
    100% {
        bottom: 60px;
        transform: scale(1);
        opacity: 0;
    }
}

.loadingIni {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.loadingIni > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.link-button {
    cursor: pointer !important;
    white-space: nowrap;
}

#profile_modal,
#activated_modal,
#challenger_modal,
#alert_modal,
#video_modal {
    width: 672px;
}

#profile_modal,
#activated_modal,
#challenger_modal,
#alert_modal,
#video_modal {
    background: url(../img/profile_bg.jpg);
}

#profile_modal .modal_content,
#activated_modal .modal_content,
#challenger_modal .modal_content,
#alert_modal .modal_content,
#video_modal .modal_content {
    padding: 65px;
    min-height: 404px;
    color: #fff;
    height: auto;
    overflow: hidden;
}

#activated_modal .modal_content,
#alert_modal .modal_content,
#video_modal .modal_content {
    overflow: visible;
}

#profile_modal .modal_top,
#activated_modal .modal_top,
#challenger_modal .modal_top,
#alert_modal .modal_top,
#video_modal .modal_top {
    background: url(../img/profile_top.jpg) center top no-repeat;
}

#profile_modal .modal_bottom,
#activated_modal .modal_bottom,
#challenger_modal .modal_bottom,
#alert_modal .modal_bottom,
#video_modal .modal_bottom {
    background: url(../img/profile_bottom.jpg) center bottom no-repeat;
}

#profile_modal .modal_bottom:before,
#profile_modal .modal_bottom:after,
#activated_modal .modal_bottom:before,
#activated_modal .modal_bottom:after,
#challenger_modal .modal_bottom:before,
#challenger_modal .modal_bottom:after,
#alert_modal .modal_bottom:before,
#alert_modal .modal_bottom:after,
#video_modal .modal_bottom:before,
#video_modal .modal_bottom:after {
    background: url(../img/modal_corners2.png);
    width: 708px;
}

#profile_modal .modal_top:before,
#activated_modal .modal_top:before,
#challenger_modal .modal_top:before,
#alert_modal .modal_top:before,
#video_modal .modal_top:before {
    width: 325px;
    background: url(../img/border_top.png);
    margin-left: -162px;
    height: 14px;
    top: -14px;
}

#profile_modal .modal_top:after,
#activated_modal .modal_top:after,
#challenger_modal .modal_top:after,
#alert_modal .modal_top:after,
#video_modal .modal_top:after {
    content: "";
    display: block;
    position: absolute;
    width: 325px;
    background: url(../img/border_top.png);
    margin-left: -162px;
    height: 14px;
    bottom: -14px;
    left: 50%;
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.profile_avatar {
    height: 108px;
    background: url(../img/avatar_bg.png) center top no-repeat;
    position: relative;
    text-align: center;
    padding-top: 25px;
    margin-top: -20px;
    font-size: 13px;
    margin-bottom: 25px;
}

.profile_avatar:before {
    content: "";
    display: block;
    width: 540px;
    height: 1px;
    background: url(../img/ava_lines.png) center top no-repeat;
    position: absolute;
    top: 54px;
}

.profile_avatar img {
    width: 59px;
    height: 59px;
    border-radius: 50%;
}

.status {
    position: absolute;
    top: 20px;
    left: 0px;
}

.country {
    position: relative;
    display: inline-block;
    top: 3px;
    right: 5px;
}

.profile_avatar .country {
    position: absolute;
    display: inline-block;
    top: 20px;
    right: 5px;
}

.country img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    float: right;
}

.emblem img {
    width: 32px;
    height: 32px;
    float: right;
}

.partida-level {
    position: relative;
    display: inline-block;
    top: 3px;
}

.partida-level img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    float: right;
    margin-bottom: -2px;
    margin-right: 5px;
}

.status {
    padding-left: 25px;
}

.membership-level {
    position: relative;
    display: inline-block;
    top: 3px;
}

.membership-level img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    float: right;
    margin-bottom: -2px;
    margin-right: 5px;
}

.membership-card-container {
    margin-top: 100px;
    margin-bottom: 70px;
    padding-left: 50px;
    padding-right: 50px;
}

.membership-card-bg {
    background: url(../img/membership/card-bg-black.jpg) no-repeat;
    height: auto;
    width: 100%;
    min-width: 270px;
    max-width: 270px;
    aspect-ratio: 289 / 413;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.7);
}

.friend-card-bg {
    background: url(../img/friend-request/bg-1.png) no-repeat;
    height: auto;
    width: 100%;
    max-width: 330px;
    aspect-ratio: 289 / 413;
    background-position: center;
    background-size: 100% 100%;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.7);
}
.friend-card-bg-2 {
    /* background: url(../img/membership/card-frame.png) no-repeat; */
    height: auto;
    width: 100%;
    max-width: 330px;
    aspect-ratio: 289 / 413;
    background-position: center;
    background-size: cover;
}

.membership-card-bg:hover {
    transform: translate(0, -12px);
}

.friend-card-bg:hover {
    cursor: pointer;
}

.membership-card-bg-2 {
    background: url(../img/membership/card-frame.png) no-repeat;
    height: auto;
    width: 100%;
    min-width: 270px;
    max-width: 270px;
    aspect-ratio: 289 / 413;
    background-position: center;
    background-size: cover;
}

.membership-progress-bg {
    background: url(../img/membership/card-bg-black-810.png) no-repeat;
    height: auto;
    width: 810px;
    min-width: 810px;
    aspect-ratio: 810 / 220;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.7);
}

.membership-proress-frame {
    background: url(../img/membership/card_frame_land.png) no-repeat;
    height: auto;
    width: 810px;
    min-width: 810px;
    aspect-ratio: 810 / 221;
    background-position: center;
    background-size: cover;
}

.membership-card {
    font-family: "OpenSans";
    padding: 20px 36px;
}

.membership-title {
    width: 90%;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 28px;
    font-family: "Exocet";
    text-align: center;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#dba76d, #875e31);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.friend-title {
    width: 200px;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 31px;
    font-family: "Exocet";
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    /* background: -webkit-linear-gradient(#107d69, #ffebd5); */
    /* -webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
}

.membership-name {
    width: 90px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    font-family: "OpenSans";
    text-align: center;
    text-transform: uppercase;
    color: #958063;
}

.membership-card .membership-description {
    font-size: 16px;
    color: #958063;
    font-weight: 300;
    line-height: 20px;
    margin-top: 12px;
}

.line-camp-1 {
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.line-camp-2 {
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.membership-card .membership-icon {
    width: 100px;
}

.membership-card .membership-point {
    font-size: 16px;
    color: #ffffff;
}

.membership-card .friend-point {
    font-size: 16px;
    color: #9c9d9e;
    text-transform: uppercase;
}

.membership-card .membership-gem-discount {
    font-size: 16px;
    background: -webkit-linear-gradient(#677728, #94c142);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.membership-card .membership-free-ticket {
    font-size: 16px;
    color: #ffa217;
}

.online:before,
.offline:before {
    content: "";
    display: block;
    width: 23px;
    height: 23px;
    background: url(../img/online.png) no-repeat;
    position: absolute;
    left: 0px;
    top: -2px;
}

.offline:before {
    background: url(../img/offline.png) no-repeat;
}

#profile_modal h4,
#challenger_modal h4 {
    font-family: Albertus;
    font-weight: normal;
    font-size: 24px;
    color: #ffda59;
    text-align: center;
    margin: -20px 0px 30px 0px;
    text-shadow: 0px 0px 20px rgba(255, 157, 103, 0.4);
}

.personal_info,
.level_info {
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    width: 254px;
    float: left;
    text-align: left;
}

.level_info {
    margin-left: 30px;
}

#profile_modal h5 {
    font-size: 16px;
    text-align: left;
}

#profile_modal hr {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #1e2d3c;
    margin: 10px -20px 10px -20px;
}

.infos {
    line-height: 26px;
}

.infos span,
.games_lev div span {
    color: #ffda59;
}

.games_lev > div {
    height: 40px;
    line-height: 40px;
    position: relative;
    padding-left: 50px;
}

.games_lev > div > img {
    width: 64px;
    float: left;
    position: absolute;
    left: -10px;
    top: -10px;
}

.games_lev > div > span > img {
    margin-bottom: -8px;
}

.level_info button {
    margin-top: 5px;
    width: 100%;
    background: url(../img/profile_button_bg.png) no-repeat;
    font-size: 10px;
    padding-left: 20px;
    text-shadow: 1px 1px #3d4954;
    box-shadow: 0px 0px 15px rgb(139, 154, 168, 0.3);
}

.faq_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
}

.faq_question {
    color: #fd7a52;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-left: 55px;
}

.faq_question:before {
    top: -25px !important;
    left: -10px !important;
}

.faq_text {
    padding-left: 55px;
    margin: 10px 0px 30px 0px;
}

.rewards {
    margin-left: 10px;
    padding-right: 15px;
    background: url(../img/rewards_bg.png) left top;
    color: #86dd39;
}

.friendOnlineBackground {
    margin-left: 10px;
    padding-right: 15px;
    background: url(../img/rewards_bg.png) left top;
    color: #86dd39;
}
.friendSearchOnlineBackground {
    margin-left: 10px;
    padding-right: 15px;
    background: url(../img/friend-request/btn-send-1.png) left top no-repeat;
    color: #ffffff;
    background-size: 100% 100%;
    width: 200px;
    height: 50px;
}

.friendSearchOfflineBackground {
    margin-left: 10px;
    padding-right: 15px;
    background: url(../img/friend-request/btn-send-1.png) left top no-repeat;
    color: #232323;
    background-size: 100% 100%;
    height: 50px;
    color: #ffffff;
    padding-top: 15px;
}

.friendOfflineBackground {
    margin-left: 10px;
    padding-right: 15px;
    background: url(../img/balance_bg.png) left top;
    color: #ffffff;
}

.rewards > a {
    color: #86dd39;
}

.rewards:before {
    background: url(../img/gem.png) left center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 15px;
    height: 40px;
}

.reward_info {
    font-size: 13px;
    margin-top: 15px;
    display: table;
}

.rew_text,
.rew_balance {
    background: rgba(255, 255, 255, 0.05);
    padding: 22px 18px 22px 70px;
    position: relative;
}

.rew_balance {
    line-height: 54px;
}

.rew_text {
    width: 402px;
    float: left;
}

.reward_info b {
    color: #fff;
}

.rew_balance {
    margin-left: 491px;
}

.rew_balance b span {
    color: #86dd39;
}

.rew_text:before,
.rew_balance:before,
.faq_question:before {
    content: "";
    display: block;
    width: 72px;
    height: 72px;
    background: url(../img/info_icon.png) no-repeat;
    position: absolute;
    top: 12px;
    left: 4px;
}

.rew_balance:before {
    background: url(../img/gem.png) no-repeat left center;
    background-size: contain;
}

#rew_title h4 {
    font-size: 16px !important;
    text-align: left;
    margin: 14px 0px !important;
}

.rew_filters {
    float: right;
}

.rew_filters .select button {
    color: #fff;
    font-weight: normal;
    font-size: 13px;
}

.rew_filters .select {
    border: none;
    background: rgba(255, 255, 255, 0.05);
    height: 36px;
    line-height: 36px;
}

.rew_filters .main-item:after {
    background: url(../img/row_icon.png) right 50% no-repeat;
}

.rew_filters .select .main-item:focus {
    color: #fff;
}

.rew_items {
    font-size: 0px;
    display: table;
    width: 100%;
    padding-top: 10px;
}

.r_item {
    display: inline-block;
    background: rgba(255, 255, 255, 0.05);
    padding: 14px 27px 24px 27px;
    margin: 4px;
    width: 150px;
    font-size: 14px;
    float: left;
    color: #fff;
    text-align: center;
}

.r_title {
    font-weight: bold;
    line-height: 24px;
}

.r_title b {
    display: block;
    color: #f08d2f;
    font-size: 24px;
}

.r_icon {
    width: 86px;
    height: 86px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin: 5px auto;
    position: relative;
}

.r_icon img {
    position: absolute;
    left: -30px;
    top: -32px;
}

.r_price {
    font-size: 12px;
    font-weight: bold;
}

.r_price span {
    color: #86dd39;
}

.r_price button {
    margin-top: 5px;
}

button:disabled,
button:disabled:hover {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
    cursor: default;
}

#rewards .c_bottom,
#referral .c_bottom {
    color: #86949f !important;
}

.rewards {
    margin-left: 10px;
    padding-right: 15px;
    background: url(../img/rewards_bg.png) left top;
    color: #86dd39 !important;
}

.rewards:before {
    background: url(../img/gem.png) left center;
    background-size: contain;
    background-repeat: no-repeat;
    top: 15px;
    height: 40px;
}

.reward_info {
    font-size: 13px;
    margin-top: 15px;
    display: table;
}

.rew_text,
.rew_balance {
    background: rgba(255, 255, 255, 0.05);
    padding: 22px 18px 22px 70px;
    position: relative;
}

.rew_text {
    width: 402px;
    float: left;
}

.reward_info b {
    color: #fff;
}

.rew_balance {
    margin-left: 491px;
}

.rew_balance b span {
    color: #86dd39;
}

.rew_text:before,
.rew_balance:before,
.faq_question:before {
    content: "";
    display: block;
    width: 72px;
    height: 72px;
    background: url(../img/info_icon.png) no-repeat;
    position: absolute;
    top: 12px;
    left: 4px;
}

.rew_balance:before {
    background: url(../img/gem.png) no-repeat left center;
    background-size: contain;
}

#rew_title h4 {
    font-size: 16px !important;
    text-align: left;
    margin: 14px 0px !important;
}

.rew_filters {
    float: right;
}

.rew_filters .select button {
    color: #fff;
    font-weight: normal;
    font-size: 13px;
}

.rew_filters .select {
    border: none;
    background: rgba(255, 255, 255, 0.05);
    height: 36px;
    line-height: 36px;
}

.rew_filters .main-item:after {
    background: url(../img/row_icon.png) right 50% no-repeat;
}

.rew_filters .select .main-item:focus {
    color: #fff;
}

.rew_items {
    font-size: 0px;
    display: table;
    width: 100%;
    padding-top: 10px;
}

.r_item {
    display: inline-block;
    background: rgba(255, 255, 255, 0.05);
    padding: 14px 27px 24px 27px;
    margin: 4px;
    width: 150px;
    font-size: 14px;
    float: left;
    color: #fff;
    text-align: center;
}

.r_title {
    font-weight: bold;
    line-height: 24px;
}

.r_title b {
    display: block;
    color: #f08d2f;
    font-size: 24px;
}

.r_icon {
    width: 86px;
    height: 86px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin: 5px auto;
    position: relative;
}

.r_icon img {
    position: absolute;
    left: -30px;
    top: -32px;
}

.r_price {
    font-size: 12px;
    font-weight: bold;
}

.r_price span {
    color: #86dd39;
}

.r_price button {
    margin-top: 5px;
}

#challenger_modal .activate_buttons {
    text-align: center;
}

#challenger_modal .activate_buttons button {
    width: 320px;
}

#challenger_modal h6 {
    color: #fff;
    font-size: 20px;
    margin: 0px;
    padding-top: 15px;
    text-align: center;
    font-family: Albertus;
    font-weight: normal;
}

#challenger_modal h6 span {
    color: #86949f;
}

#challenger_modal h4 {
    margin-bottom: 20px;
}

.game_selector {
    background: rgba(0, 0, 0, 0.25);
    margin: 0px -44px;
    padding: 0px 44px;
}

.game_sel {
    font-size: 0px;
    text-align: center;
}

.gs_item {
    display: inline-block;
    margin: -25px -28px -20px -28px;
    cursor: pointer;
    width: 190px;
    overflow: hidden;
}

.gs_item img {
    width: 190px;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
}

.gs_item:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.arrow_left,
.arrow_right {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    z-index: 999;
    top: 41%;
    left: 50px;
    cursor: pointer;
    color: #fff;
    text-align: center;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
}

.arrow_left:before,
.arrow_right:before {
    content: "";
    display: block;
    width: 24px;
    height: 28px;
    background: url(../img/prev_row.png) no-repeat;
    position: absolute;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 5px;
    left: 8px;
}

.arrow_right {
    left: auto;
    right: 50px;
}

.arrow_right:before {
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: 8px;
    left: 10px;
}

.gs_row_up,
.gs_row_down {
    width: 77px;
    height: 34px;
    background: url(../img/game_row_up.png) no-repeat;
    position: absolute;
    left: 22px;
    top: -23px;
    z-index: 999;
}

.slider-handle {
    width: 200px;
    height: 75px;
    background: url(../img/slider-handle/slider-handle-bg.png) no-repeat;
    position: fixed;
    left: 30px;
    top: 200px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider-handle .btn-slider-menu {
    width: 114px;
    height: 25px;
    background: url(../img/slider-handle/slider-handle.png) no-repeat;
    opacity: 0.8;
}

.slider-handle .btn-slider-menu.open-menu {
    position: absolute;
    top: 5px;
}

.slider-handle .btn-slider-menu.close-menu {
    transform: rotate(180deg);
    position: absolute;
    bottom: 6px;
}

.slider-handle .btn-slider-menu:hover {
    zoom: 1.1;
    opacity: 1;
}

.slider-handle .slider-title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    font-family: "Exocet";
    text-align: center;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#dba76d, #875e31);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.slider-handle .slider-title.open-menu {
    position: absolute;
    bottom: 20px;
}

.slider-handle .slider-title.close-menu {
    position: absolute;
    top: 26px;
}

.gs_row_down {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(180deg);
    top: auto;
    bottom: -17px;
}

.slick-list {
    padding-bottom: 10px !important;
    height: 418px !important;
}

.slider {
    width: 120px;
    height: 451px;
    background: url(../img/games_bg.png) no-repeat;
    text-align: center;
    z-index: 999;
    padding-top: 15px;
    box-sizing: border-box;
}

.slider .slick-arrow {
    width: 77px;
    height: 26px;
    /* background: url(../img/game_row_up.png) no-repeat; */
    position: absolute;
    left: 22px;
    top: 12px;
    z-index: 999;
}

.slider .slick-arrow:hover,
.slider .slick-arrow:focus,
.slider .slick-arrow:active {
    /* background: url(../img/game_row_up.png) no-repeat; */
}

.slider .slick-arrow:before {
    display: none;
}

.slider .slick-prev {
    transform: rotate(180deg);
    top: auto;
    bottom: 2px;
}

.slider img {
    max-width: 100%;
    position: relative;
}

.slider img.iconFast {
    width: 20px;
    height: 20px;
    margin: 0;
    z-index: 999;
    filter: drop-shadow(2px 2px 2px rgb(99, 99, 99));
    animation: blink 2s linear infinite;
}

.iconBlink {
    margin: 0;
    z-index: 999;
    filter: drop-shadow(2px 2px 2px rgb(99, 99, 99));
    animation: blink 2s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0.1;
    }
    10% {
        opacity: 0.6;
    }
    20% {
        opacity: 0.4;
    }
    30% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.1;
    }
}

.slider-game {
    box-sizing: border-box;
    cursor: pointer;
}

.slider-game:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.slider-game img {
    display: block;
    margin: -10px auto;
    height: 101px;
}

.slider-game span {
    display: inline-block;
    color: #f08d2f;
    font-weight: 600;
    font-size: 14px;
    position: relative;
    margin-left: 5px;
}

.vs_block {
    position: fixed;
    left: 50px;
    top: 50%;
    margin-top: -200px;
    z-index: 999;
}

.vs_block .slider {
    zoom: 120%;
}

.entry_cost .radio_r {
    background: rgba(0, 0, 0, 0.25);
    height: auto;
    border-radius: 10px;
    text-align: center;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 15px;
}

.entry_cost .radio_r input[type="radio"] + label:before {
    display: none;
}

.entry_cost .radio_r input[type="radio"] + label {
    background: url(../img/ec_label.jpg);
    height: 38px;
    border: none;
    border-radius: 5px;
    padding: 0px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
    width: 128px;
    margin: 1px;
}

.entry_cost .radio_r input[type="radio"]:checked + label {
    background: url(../img/ec_label_sel.jpg);
    color: #f08d2f;
    border: 2px solid #f08d2f;
    width: 124px;
    height: 34px;
    line-height: 34px;
    box-shadow: 0px 0px 0px 3px rgba(240, 141, 47, 0.2);
}

.prize_c {
    text-align: center;
    padding-bottom: 20px;
}

.prize_c h6 {
    text-transform: uppercase;
}

.prize_c span {
    color: #ffda59;
    font-family: Albertus;
    text-shadow: 0px 0px 10px rgb(255, 157, 103, 0.5);
    font-size: 36px;
}

.notifications {
    position: relative;
    float: left;
}

.notification_item {
    position: relative;
    color: #756f69;
    font-size: 13px;
    padding: 16px 30px 16px 55px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.notification_item a,
.notification_item a:hover {
    color: #fff;
    font-weight: bold;
    background: none !important;
}

.notifications .main-item:focus ~ .sub-menu,
.notifications .main-item:active ~ .sub-menu,
.notifications .sub-menu:hover {
    width: 300px;
    top: 45px;
}

.notifications .sub-menu:before {
    right: 38px;
}

.notifications .main-item:after {
    display: none;
}

.notification_item b {
    color: #61bb11;
}

.notification_item b.challenge-ladder {
    color: #a111bbd3;
    text-transform: capitalize;
}

.notification_item b.challenge-bonanza {
    color: #127ed6d3;
    text-transform: capitalize;
}

.notif_buttons {
    margin-top: 10px;
}

.notif_buttons button {
    height: 24px;
    font-size: 10px;
    padding: 0px 16px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-right: 2px;
}

.notif_buttons .bordered_button {
    padding: 0px 16px;
    width: auto;
}

.notif_buttons .green_button {
    background: url(../img/enter_button.png) no-repeat;
    text-shadow: 1px 1px #649022;
    box-shadow: 0px 0px 15px rgba(101, 130, 44, 0.3);
}

.rejected {
    opacity: 0.4;
}

.show_more {
    margin-top: 10px;
    text-align: center;
}

.show_more a,
.show_more .link-button {
    font-family: Albertus;
    text-transform: uppercase;
    color: #fff;
    font-size: 10px;
    display: block;
    padding: 5px 0px;
    text-decoration: none;
    background: none !important;
    width: 100%;
}

.forgot_password {
    float: left;
    height: 40px;
    line-height: 20px;
    padding-left: 10px;
    width: 100px;
}

.forgot_password .link-button {
    color: #fff;
    opacity: 0.5;
    width: 100px;
    white-space: pre-wrap;
    line-height: 20px;
}

#forgot_modal .modal_top:before,
#forgot_modal_success .modal_top:before,
#forgot_modal_error .modal_top:before {
    display: none;
}

#forgot_modal .activate_icon,
#forgot_modal_success .activate_icon,
#forgot_modal_error .activate_icon {
    text-align: center;
}

#forgot_modal_success .modal_content,
#forgot_modal_error .modal_content {
    min-height: auto;
}

#forgot_modal_success .rec_text,
#forgot_modal_error .rec_text {
    margin-bottom: 30px;
}

.activate_text {
    font-size: 14px;
}

.activate_icon {
    height: 118px;
    background: url(../img/avatar_bg.png) center top no-repeat;
    margin-top: -111px;
    position: relative;
    padding-top: 15px;
}

.personaje_selector {
}

.personaje_selector .personaje_sel {
}

.personaje_selector .personaje_sel > .personaje_item {
    width: calc(25% - 18px);
    margin: 1px;
    float: left;
    text-align: center;
    padding: 8px;
}

.personaje_selector .personaje_sel > .personaje_item.selected {
    background: #d5b88e;
}

.personaje_selector .personaje_sel > .personaje_item > img {
    max-width: 100%;
    height: 75px;
    margin: 0 auto;
}

.td_win .select {
    background: none;
    border: none;
    line-height: 19px;
    padding: 0;
    margin: 0;
    height: 19px;
    width: auto;
    margin-top: -4px;
}

.td_win .select > .main-item {
    padding: 0;
    margin: 0;
    z-index: 100;
}

.td_win .select .sub-menu {
    left: 0;
    top: 10px;
}

iframe.coinbase-commerce-iframe {
    top: 100px !important;
    height: calc(100% - 100px) !important;
}

#activated_modal,
#alert_modal {
    display: block !important;
    top: 270px;
    z-index: 100;
    position: absolute;
    left: 50%;
    margin-left: -336px;
}

#activated_modal .modal_content,
#alert_modal .modal_content {
    min-height: 180px;
    text-align: center;
}

#activated_modal h4,
#alert_modal h4 {
    text-transform: uppercase;
    font-family: Albertus;
    color: #7dce37;
    font-size: 24px;
    text-shadow: 0px 0px 10px rgba(139, 197, 37, 0.3);
    font-weight: normal;
    margin-bottom: 20px;
    margin-top: -10px;
}

.activate_text {
    margin-bottom: 35px;
}

.activate_buttons button {
    height: 45px;
    padding: 0px 30px;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0px 6px;
}

.button_orange {
    background: url(../img/button_bg_orange.jpg) center top;
    text-shadow: 1px 1px #895101;
}

.activate_text {
    font-size: 14px;
}

.activate_icon {
    height: 118px;
    background: url(../img/avatar_bg.png) center top no-repeat;
    margin-top: -111px;
    position: relative;
    padding-top: 15px;
}

.mt_block {
    position: absolute;
    background: rgba(0, 0, 0, 0.95) url(../img/mt_border.png) no-repeat;
    box-shadow: 0px 10px 30px rgb(0, 0, 0, 0.6);
    width: 230px;
    font-size: 13px;
    font-family: Open Sans;
    font-weight: normal;
    color: #756f69;
    z-index: 20;
    padding: 22px 25px 25px 85px;
    text-align: left;
    display: none;
}

.mt_block:after {
    content: "";
    width: 340px;
    height: 24px;
    background: url(../img/mt_border.png) no-repeat;
    position: absolute;
    top: 0px;
    left: 0px;
}

.second-container .mt_block:after,
.spin-card .mt_block:after {
    content: none;
}

.second-container .mt_block:before,
.spin-card .mt_block:before {
    content: none;
}

.mt_block:after {
    top: auto;
    bottom: 0px;
    transform: rotate(180deg);
}

.mt_block h6 {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0px;
}

.step {
    width: 55px;
    height: 54px;
    background: url(../img/step_bg.png) no-repeat;
    text-align: center;
    line-height: 56px;
    color: #ffda59;
    text-shadow: 0px 0px 10px rgb(255, 157, 103, 0.5);
    font-family: Albertus;
    font-size: 18px;
    position: absolute;
    left: 16px;
    top: 50%;
    margin-top: -27px;
}

.mt_block .notif_buttons {
    position: absolute;
    left: 50%;
    margin-left: -40px;
    margin-bottom: -10px;
}

.mt_block .notif_buttons button {
    width: 80px;
    z-index: 999;
    position: relative;
}

.mt_block .notif_buttons button a {
    color: #fff;
}

.step_1 {
    left: 200px;
    top: 20px;
}

.mt_block:before {
    content: "";
    display: block;
    width: 112px;
    height: 112px;
    background: url(../img/step_1_row.png) no-repeat;
    position: absolute;
    left: -104px;
    top: 76px;
}

.step_2 {
    top: 106px;
    left: 80px;
}

.step_2:before {
    width: 38px;
    height: 71px;
    background: url(../img/step_2_row.png) no-repeat;
    left: -29px;
    top: -62px;
}

.step_3 {
    top: 106px;
    left: 250px;
}

.step_3:before {
    width: 185px;
    height: 72px;
    background: url(../img/step_3_row.png) no-repeat;
    left: -176px;
    top: -62px;
}

#tournaments .mt_block .notif_buttons,
#ladder .mt_block .notif_buttons,
#bonanza .mt_block .notif_buttons {
    margin-left: -60px;
}

#tournaments .mt_block .notif_buttons button,
#ladder .mt_block .notif_buttons button,
#bonanza .mt_block .notif_buttons button {
    width: 120px;
    background-size: cover;
}

#tournaments .green_button {
    background-size: cover;
}

#tournaments .step:before {
    content: "";
    display: block;
    width: 48px;
    height: 48px;
    background: url(../img/step_info.png) no-repeat;
    position: absolute;
    top: 3px;
    left: 3px;
}

#tournaments td {
    position: relative;
}

.step_4,
.step_5 {
    top: -73px;
    left: -340px;
}

.step_4:before,
.step_5:before {
    width: 44px;
    height: 45px;
    background: url(../img/step_4_row.png) no-repeat;
    right: -36px;
    top: 75px;
    left: auto;
}

.step_6 {
    bottom: 140px;
    left: 20px;
}

.spin-card .step_6 {
    bottom: 0px;
    left: 0px;
}

.deposit-bonus-gems {
    font-weight: normal;
    display: block;
    color: #fff;
    position: absolute;
    width: 100%;
    bottom: 10px;
    font-size: 12px;
    font-variant: small-caps;
}

.deposit-bonus-gems > img {
    height: 20px;
    margin-bottom: -5px;
}

.deposit-bonus-membership {
    font-weight: normal;
    display: block;
    color: #fff;
    position: absolute;
    width: 100%;
    bottom: 10px;
    font-size: 12px;
    font-variant: small-caps;
}

.deposit-bonus-membership > img {
    height: 30px;
    margin-bottom: -10px;
}

.deposit-bonus-membership-points {
    font-weight: normal;
    display: block;
    color: #fff;
    position: absolute;
    width: 100%;
    bottom: 10px;
    font-size: 12px;
    font-variant: small-caps;
}

.deposit-bonus-membership-points > img {
    height: 30px;
    margin-top: 3px;
    margin-bottom: -10px;
}

.deposit-bonus-time {
    font-weight: normal;
    display: block;
    color: #fff;
    position: absolute;
    width: 100%;
    top: 18px;
    right: 10px;
    text-align: right;
    font-size: 11px;
    font-variant: small-caps;
    text-transform: uppercase;
    text-shadow: #000 1px 1px 0;
}

.slider-game.highlight {
    position: relative;
}

.slider-game.highlight a:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 24px;
    right: 23px;
    bottom: 24px;
    border: 5px solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    box-shadow: 0 0 10px #0f0, inset 0 0 10px #0f0;
    animation: animate 5s linear infinite;
}

.slider-game.highlight a:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 19px;
    right: 24px;
    bottom: 25px;
    border: 7px solid rgb(230, 208, 13);
    border-radius: 50%;
    box-shadow: 0 0 10px #fff, inset 0 0 10px #fff;
    filter: url(#wavy) blur(1px);
}

.slider-game.highlight a:nth-child(2):before {
    animation-delay: -2.5s;
}

@keyframes animate {
    0% {
        box-shadow: 0 0 5px #0f0, inset 0 0 5px #0f0;
        filter: hue-rotate(0deg);
    }
    20% {
        box-shadow: 0 0 4px #0f0, inset 0 0 6px #0f0;
    }
    40% {
        box-shadow: 0 0 4px #0f0, inset 0 0 8px #0f0;
    }
    60% {
        box-shadow: 0 0 8px #0f0, inset 0 0 8px #0f0;
    }
    80% {
        box-shadow: 0 0 10px #0f0, inset 0 0 10px #0f0;
    }
    100% {
        box-shadow: 0 0 5px #0f0, inset 0 0 5px #0f0;
        filter: hue-rotate(360deg);
    }
}

svg {
    width: 0;
    height: 0;
}

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.referral-link {
    font-size: 16px;
    font-weight: bold;
}

.copied {
    bottom: 0px;
    right: 20px;
    z-index: 999;
}

.sub-menu a.highlight {
    text-decoration: underline;
    font-weight: bold;
}

.text-shadow {
    text-shadow: 0 0.5rem 0.8rem rgba(0, 0, 0, 0.5) !important;
}

.f-blur-1 {
    filter: blur(1px);
}

.emoji-picker-react {
    background: rgba(49, 52, 58, 0.8) !important;
    border-color: transparent !important;
    box-shadow: 0px 1px 4px rgba(124, 143, 204, 0.6) !important;
}

.emoji-picker-react .emoji-group:before {
    background: rgba(49, 52, 58, 1) !important;
}

.emoji-picker-react .emoji-categories button.icn-activities {
    background: url("../img/emoji/activities.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-activities.active {
    background: url("../img/emoji/activities_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

.emoji-picker-react .emoji-categories button.icn-smileys_people {
    background: url("../img/emoji/smileys_people.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-smileys_people.active {
    background: url("../img/emoji/smileys_people_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

.emoji-picker-react .emoji-categories button.icn-animals_nature {
    background: url("../img/emoji/animal_nature.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-animals_nature.active {
    background: url("../img/emoji/animal_nature_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

.emoji-picker-react .emoji-categories button.icn-food_drink {
    background: url("../img/emoji/food_drink.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-food_drink.active {
    background: url("../img/emoji/food_drink_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

.emoji-picker-react .emoji-categories button.icn-travel_places {
    background: url("../img/emoji/travel_place.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-travel_places.active {
    background: url("../img/emoji/travel_place_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

.emoji-picker-react .emoji-categories button.icn-objects {
    background: url("../img/emoji/objects.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-objects.active {
    background: url("../img/emoji/objects_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

.emoji-picker-react .emoji-categories button.icn-symbols {
    background: url("../img/emoji/symbols.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-symbols.active {
    background: url("../img/emoji/symbols_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

.emoji-picker-react .emoji-categories button.icn-flags {
    background: url("../img/emoji/flags.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
}

.emoji-picker-react .emoji-categories button.icn-flags.active {
    background: url("../img/emoji/flags_active.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: 50% 50% !important;
    opacity: 1 !important;
}

#objective .c_bottom,
#membership .c_bottom,
#laddergames .c_bottom {
    min-height: 715px;
    background: url(../img/bottom_bg.png) center bottom no-repeat;
    padding: 84px 72px;
    color: #86949f !important;
}

#objective .c_top,
#membership .c_top,
#laddergames .c_top {
    width: 1282px;
    background: none;
    position: relative;
}

#objective .c_bottom,
#membership .c_bottom,
#laddergames .c_bottom {
    min-height: 715px;
    background: none;
    margin-bottom: 0px;
    padding: 84px 12px;
    color: #756f69;
}

#objective .c_center,
#membership .c_center,
#laddergames .c_center {
    margin: 220px auto 0px auto;
    width: 1282px;
    height: auto;
    /* background: url(../img/bg_panel.png) center -155px no-repeat; */
    background: url(../img/bg_panel.png);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
}

#objective .c_bottom h4,
#membership .c_bottom h4,
#laddergames .c_bottom h4 {
    font-size: 34px;
    margin-bottom: 32px !important;
    text-align: center;
    font-family: "Exocet";
    text-transform: uppercase;
    background: -webkit-linear-gradient(#dba76d, #875e31);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

#objective .c_bottom,
#membership .c_bottom,
#laddergames .c_bottom h4 {
    color: #86949f !important;
}

#objective .c_top:before,
#objective .c_top:after,
#membership .c_top:before,
#membership .c_top:after,
#laddergames .c_top:before,
#laddergames .c_top:after {
    content: "";
    display: block;
    width: 1319px;
    height: 120px;
    background: url(../img/profile_corners_top_2.png) no-repeat;
    position: absolute;
    top: -24px;
    left: -18px;
}

#objective .c_top:after,
#membership .c_top:after,
#laddergames .c_top:after {
    background: url(../img/profile_corners_bottom_2.png) no-repeat;
    top: auto;
    bottom: -24px;
    left: auto;
    right: -18px;
}

#objective .c_center,
#membership .c_center,
#laddergames .c_center {
    margin: 220px auto 0px auto;
    width: 1282px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.9);
}

#objective .c_bottom:before,
#membership .c_bottom:before,
#laddergames .c_bottom:before {
    content: "";
    display: block;
    width: 661px;
    height: 149px;
    /* background: url(../img/logo_title.png) no-repeat; */
    /* background: none; */
    background: url(../img/tournaments_title.png) no-repeat;
    position: absolute;
    top: -87px;
    left: 50%;
    margin-left: -330px;
    z-index: 2;
}

.objective-card-container {
    margin-top: 20px;
    margin-bottom: 50px;
}

.objective-row {
    display: flex;
    flex-wrap: wrap;
}

.objective-col {
    flex: 25%;
    flex-direction: column;
    max-width: 25%;
    position: relative;
}

/* .objective-col:hover {
	transform: translate(0, -24px);
} */

.objective-in-active {
    filter: grayscale(1);
}

.objective-col.objective-in-active:hover {
    transform: translate(0, -24px);
}

.objectiveLogo {
    float: left;
    margin-left: 10px;
    cursor: pointer;
}

.objective-col .objective-card-header {
    background: url(../img/objective/objective-header.png) no-repeat;
    background-position: center;
    height: auto;
    width: 100%;
    aspect-ratio: 580 / 120;
    overflow: hidden;
    transform: translate(0, 44%);
}

.objective-card {
    background: url(../img/objective/objective-bg.png) no-repeat;
    height: auto;
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    padding: 40px 20px;
    aspect-ratio: 280 / 539;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.7);
}

.objective-card .objective-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.objective-img-container .img-bg {
    width: 201px;
    height: 180px;
    background: url(../img/objective/objective-icon-bg.png) no-repeat;
    position: absolute;
    top: 23px;
    left: 45px;
}

.objective-img-container img {
    width: 248px;
    height: 248px;
    margin-top: -50px;
    z-index: 999;
}

.objective-img-container svg {
    width: 112px;
    height: 112px;
}

.objective-img-container .social-icon {
    width: 248px;
    height: 248px;
    padding: 68px;
    margin-top: -50px;
    z-index: 999;
}

.objective-card .description {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    font-family: "OpenSans";
    text-align: center;
    color: #ffffff;
    transform: translate(0, -50%);
}

.objective-bar-container {
    margin-top: -12px;
    margin-bottom: 4px;
}

.objective-bar {
    height: 40px;
    width: 231px;
    background: url(../img/objective/objective_progress_bg.png) no-repeat;
    margin-left: 10px;
    position: relative;
    overflow: hidden;
}

.objective-bar .objective-fr {
    width: 100%;
    height: 100%;
    background: url(../img/objective/objective_progress_fr.png) left;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.objective-bar .objective-fr.progress-text {
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    font-family: "OpenSans";
    text-align: center;
    color: #f0e2d0;
}

.objective-bar .objective-progress {
    background: url(../img/objective/objective_progress_bar.png) left;
    height: 24px;
    margin-top: 8px;
    text-align: left;
    font-size: 10px;
}

.objective-card .objective-dont-miss {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    font-family: "OpenSans";
    text-align: center;
    color: #c0ae97;
}

.objective-card .timer-container {
    margin: 10px 0;
    display: flex;
}


.timer-container .timer-seperator {
    width: 20px;
    height: 60px;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    font-family: "OpenSans";
    text-align: center;
    background: -webkit-linear-gradient(#987241, rgba(210, 174, 128, 0.9531), rgba(22, 18, 18, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient();
}

.timer-container .timer-item {
    width: 60px;
}

.timer-item .timer-progress {
    width: 60px;
    height: 60px;
    position: relative;
}

.timer-item .timer-progress span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 24px;
    font-family: "OpenSans";
    background: -webkit-linear-gradient(#9d794b, #ffdcae, #665025);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.timer-item .timer-desc {
    margin-top: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    font-family: "OpenSans";
    color: #c5ab8a;
    text-transform: uppercase;
}

.objective-card .btn-objective {
    width: 211px;
    height: 40px;
    margin-left: 20px;
    position: absolute;
    bottom: 80px;
    background: url("../img/objective/objective-btn.png");
    box-shadow: 0px 2px 10px;
}

.btn-objective .objective-rewards {
    font-family: "OpenSans";
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #dff38b;
}

.btn-objective img {
    width: 28px;
    height: 28px;
    margin-top: -2px;
}

.objective-card .CircularProgressbar-trail {
    stroke-width: 6px;
}

.tournament-card {
    background: url(../img/race-game/race-game-bg-base.png) no-repeat !important;
    height: auto;
    width: 100%;
    min-width: 280px;
    max-width: 280px;
    aspect-ratio: 280 / 682;
    background-position: center;
    background-size: cover !important;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.7);
    border-top: none !important;

    position: relative;
}

.tournament-card-container {
    margin-top: 20px;
    margin-bottom: 50px;
}

.tournament-row {
    display: flex;
    flex-wrap: wrap;
}

.spin-card-container .tournament-row {
    display: flex;
    flex-wrap: wrap;
    max-height: 500px;
    overflow-y: auto;
}

.spin-cover-container {
    
}

.spin-cover-container:hover {
    transform: scale(1.1);
    transition: transform .2s;
}

.spin-cover-container .video-container {
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px 12px black;
}

.spin-cover-container .spin-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: url(../img/race-game/free-roll-bg.png); */
    background-size: contain;
}

.spin-cover-container .spin-overlay img {
    width: 80%;
    height: 80%;
    object-fit: cover;
}


.spin-cover-container .cover-bottom {
    padding: 12px 8px;
    font-size: 24px;
    color: white;
    text-align: center;
    text-transform: capitalize;
}

.tournament-col {
    flex: 25%;
    flex-direction: column;
    max-width: 25%;
    position: relative;
}

.tournament-in-active {
    filter: grayscale(1);
}

.tournament-col.tournament-in-active:hover {
    transform: translate(0, -24px);
}

.tournament-card .top-container {
    height: auto;
    width: 100%;
    padding: 12px;
    aspect-ratio: 264 / 197;
    background-position: center;
    background-size: cover !important;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}

.tournament-card .top-container .img-ladder-logo,
.tournament-card .top-container .img-bonanza-logo {
    height: auto;
    width: 100%;
    aspect-ratio: 264 / 197;
}

.tournament-card .top-container.bg-vip {
    background: url(../img/race-game/vip-bg.png);
    background-position: center;
    background-size: cover !important;
}

.tournament-card .top-container.bg-slot_war {
    background: url(../img/race-game/slot-war-bg.png);
    background-position: center;
    background-size: cover !important;
}

.tournament-card .top-container.bg-free_roll {
    background: url(../img/race-game/free-roll-bg.png);
    background-position: center;
    background-size: cover !important;
}

.tournament-card .top-container.bg-table_war {
    background: url(../img/race-game/table-war-bg.png);
    background-position: center;
    background-size: cover !important;
}

.tournament-card .top-container img {
    object-fit: cover;
    object-position: center;
}

.tournament-card .top-container .divider {
    background: url(../img/race-game/race-game-divider.png);
    width: 100%;
    height: 9px;
    background-position: center;
    background-size: cover !important;
    position: absolute;
    bottom: 0;
}

.tournament-card .top-container .btn-prize {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 24px;
    left: 24px;
    padding: 0px;
    background: none;
    z-index: 99;
    box-shadow: 0px 4px 4px;
    border-radius: 50%;
}

.tournament-card .top-container .btn-question {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 24px;
    right: 32px;
    padding: 0px;
    background: none;
    z-index: 99;
    box-shadow: 0px 4px 4px;
    border-radius: 50%;
}

.tournament-card .top-container .btn-prize img,
.tournament-card .top-container .btn-question img {
    width: 100%;
}

.tournament-card .second-container {
    background: url(../img/race-game/burning-bg.png);
    height: auto;
    width: 100%;
    padding: 0px 24px;
    aspect-ratio: 254 / 464;
    background-position: center;
    background-size: cover !important;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tournament-card .second-container .shine-img {
    width: 200px;
    height: 60px;
    position: absolute;
    transform: translateY(-50%);
}

.mt-36 {
    margin-top: 36px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-8 {
    margin-top: 8px;
}

.tournament-card .tournament-title {
    width: 80%;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 30px;
    font-family: "Exocet";
    text-align: center;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#dba76d, #875e31);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.tournament-card .second-container .indicator {
	margin-top: 2px;
	color: #958063;
	font-size: 14px;
	line-height: 19px;
	font-family: 'OpenSans';
	text-align: center;
}

.tournament-card .second-container .guarantee {
    margin-top: -4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    font-family: "OpenSans";
    text-align: center;
    color: #ff6161;
}

.tournament-card .second-container .text-value {
    margin-bottom: 2px;
    color: white;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-family: "OpenSans";
    text-align: center;
}

.tournament-card .detail-container {
	width: 100%;
	margin-top: 2px;
	position: relative;
}

.tournament-card .detail-container .gem {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    font-family: "OpenSans";
}
.tournament-card .detail-container .ldr-gem {
    width: 24px;
    height: 24px;
}

.tournament-card .detail-container .gem img {
	width: 16px;
	height: 16px;
	margin-top: -8px;
}

.tournament-card .time-remaining {
	margin-top: 10px;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 22px;
	font-family: 'OpenSans';
	text-align: center;
	color: #C0AE97;
}

.tournament-card .button-deposit {
    height: 40px;
    margin-top: 20px;
    background: url(../img/button_bg.jpg) center top;
    padding: 10px;
    font-size: 10px;
    line-height: 22px;
    font-family: "OpenSans";
    font-weight: 800;
    text-transform: uppercase;
    z-index: 99;
    color: white;
    box-shadow: 0px 2px 10px black;
    opacity: 0.9;
}

 .button-green {
    height: 40px;
    margin-top: 20px;
    background: url(../img/button_bg.jpg) center top;
    padding: 10px 40px;
    font-size: 14px;
    line-height: 22px;
    font-family: "OpenSans";
    font-weight: 800;
    text-transform: uppercase;
    z-index: 99;
    color: white;
    box-shadow: 0px 2px 10px black;
    opacity: 0.9;
}

.tournament-card .button-green:hover {
    opacity: 1;
}

.spin-card {
    background: url(../img/spin/castillito.png) no-repeat !important;
    height: auto;
    width: 87%;
    min-width: 220px;
    max-width: 280px;
    aspect-ratio: 324 / 234;
    background-position: center;
    background-size: cover !important;
    border: none;

    position: relative;
}

.spin-card-select {
    /* background: url(../img/spin/castillito-outline.png) no-repeat !important; */
    height: auto;
    width: 100%;
    min-width: 280px;
    max-width: 280px;
    aspect-ratio: 324 / 234;
    background-position: center;
    background-size: cover !important;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spin-card-select.selected {
    background: url(../img/spin/castillito-outline.png) no-repeat !important;
    height: auto;
    width: 100%;
    min-width: 280px;
    max-width: 280px;
    aspect-ratio: 324 / 234;
    background-position: center;
    background-size: cover !important;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spin-card-container {
    margin-top: 20px;
    margin-bottom: 50px;
    min-height: 500px;
}

.spin-card-container img {
    width: 40px;
    height: 40px;
}

.spin-card .spin-cost {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white;
    text-shadow: 2px 2px #4a0c0c;
    cursor: pointer;
}

.spin-card .spin-players {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #f8efb3;
    font-weight: 900;
    background: rgba(155, 1, 1, 0.685);
    padding: 3px 8px 4px 8px;
    border-radius: 3px;
    box-shadow: 2px 2px 10px 1px #000000ab;
    cursor: pointer;
}

.spin-card .spin-players::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(0,0,0,.25) 0%, rgba(255,255,255,.25) 15%, rgba(0,0,0,.25) 30%, rgba(255,255,255,.25) 45%, rgba(0,0,0,.25) 60%, rgba(255,255,255,.25) 75%, rgba(0,0,0,.25) 90%);
  }

.spin-card-select.selected .spin-players {
    border: solid 1px;
    border-color: #f8efb3;
    box-shadow: 0px 0px 14px 0px #f7e366;
}

.spin-card .spin-upto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    line-height: 27px;
    color: white;
    text-shadow: 2px 2px #4a0c0c;
}

.CircularProgressbar {
    border-radius: 50%;
    box-shadow: 0px 1px 4px black;
}

.Toastify__close-button > svg {
    color: #f5dcaf;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    color: white;
}

.Toastify__toast {
    border-radius: 20px;
}

.player-bg {
    /* background-image: url("../img/friend-request/start-1.png"); */
    background-size: 100px 100px;
    height: 160px;
    width: 160px;
    overflow: hidden;
    margin-top: 30px;
}

.bg_main {
    background-image: url("../img/profile/bg2-1.png");
    background-size: 100% 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    /* margin-left: -50px; */
}

.profile-header {
    background-image: url("../img/profile/header.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 355px;
    padding: 10px;
    margin-top: -35px;
}

.profile-header .header-inner {
    text-align: center;
    margin: 0 auto;
    width: 100px;
}

.profile-image-bg {
    background-image: url("../img/profile/profile-frame.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 120px;
    width: 120px;
}

.profile-name-bg {
    background-image: url("../img/profile/name-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100px;
    width: 220px;
    padding-top: 8px;
    margin-left: -50px;
    margin-top: -12px;
}

.profile-description-bg {
    background-image: url("../img/profile/name-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90px;
    width: 600px;
    margin-left: -235px;
    padding-top: 15px;
    margin-top: -15px;
}

.profile-left-side-bg {
    background-image: url("../img/profile/left-side-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.profile-left-side-bg-sec-1 {
    background-image: url("../img/profile/left-side-bg-sec-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 610px;
    width: 450px;
    padding-top: 40px;
    text-align: center;
}

.profile-left-side-bg-sec-2 {
    background-image: url("../img/profile/left-side-bg-sec-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 450px;
    width: 450px;
    margin-top: -35px;
    text-align: center;
    padding-top: 15px;
    margin-left: 0px;
}

.profile-left-side-bg-sec-3 {
    background-image: url("../img/profile/left-side-bg-sec-3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 450px;
    width: 450px;
    margin-top: -55px;
    text-align: center;
    padding-top: 10px;
    margin-left: 0px;
}

.profile-rectangle {
    background-image: url("../img/profile/Rectangle.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 40px;
    width: 325px;
    margin-left: 60px;
    text-align: left;
    padding-top: 8px;
    padding-left: 10px;
    font-size: 11px;
}

.profile-rectangle:hover {
    background: #111418;
}

.profile-rectangle-2 {
    background-image: url("../img/profile/Rectangle-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 40px;
    width: 330px;
    margin-left: 60px;
    text-align: left;
    padding-top: 1px;
    padding-left: 10px;
}

.profile-rectangle-2:hover {
    background: #101011;
}

.profile-rectangle-3 {
    background-image: url("../img/profile/Rectangle-3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 40px;
    width: 320px;
    margin-left: 60px;
    text-align: left;
    padding-top: 1px;
    padding-left: 10px;
}

.profile-rectangle-3:hover {
    background: #1a1d24;
}

.profile-center-side-bg-sec-1 {
    background-image: url("../img/profile/center-side-bg-sec-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
    padding-top: 40px;
    text-align: center;
    margin-top: 15px;
    margin-left: 90px;
}

.profile-center-side-bg-sec-2 {
    background-image: url("../img/profile/center-side-bg-sec-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 400px;
    width: 570px;
    margin-top: -30px;
    text-align: center;
    margin-left: 18px;
    padding-top: 60px;
}

.profile-center-side-bg-sec-3 {
    background-image: url("../img/profile/center-side-bg-sec-3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 620px;
    width: 590px;
    padding-top: 40px;
    text-align: center;
    margin-left: 50px;
    margin-top: 0px;
}

.profile-center-rectangle-2 {
    background-image: url("../img/profile/right-side-rectangle-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 60px;
    width: 470px;
    margin-left: 60px;
    text-align: left;
    padding-left: 0px;
    padding: 7px;
    margin-top: 10px;
    padding-top: 15px;
}

.profile-right-side-bg-sec-1 {
    background-image: url("../img/profile/right-side-bg-sec-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 585px;
    width: 370px;
    padding-top: 40px;
    text-align: center;
    margin-left: 70px;
    margin-top: 26px;
}

.profile-right-side-bg-sec-2 {
    background-image: url("../img/profile/right-side-bg-sec-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 480px;
    width: 430px;
    margin-top: -38px;
    text-align: center;
    margin-left: 40px;
    padding-top: 60px;
}

.profile-right-rectangle-1 {
    background-image: url("../img/profile/right-side-rectangle-1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 60px;
    width: 340px;
    margin-left: 15px;
    text-align: left;
    padding-left: 0px;
    padding: 7px;
    margin-top: 10px;
    padding-top: 15px;
}

.profile-right-rectangle-1:hover,
.profile-center-rectangle-2:hover {
    background: #181818;
}

.profile-right-section-image-bg {
    background-image: url("../img/profile/profile-frame.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    padding: 2px;
}

.profile-right-section-award-bg {
    background-image: url("../img/profile/award-bg.png");
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    padding: 10px;
}

.profile-right-section-award-bg-blank {
    background-image: url("../img/profile/award-bg-blank.png");
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    padding: 10px;
}

.profile-right-rectangle-2 {
    background-image: url("../img/profile/Rectangle-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 40px;
    width: 330px;
    margin-left: 0px;
    text-align: left;
    padding-top: 1px;
    padding-left: 10px;
}

.profile-right-rectangle-2:hover {
    background: #101011;
}

.friend-col {
    flex: 33%;
    flex-direction: column;
    max-width: 33%;
    position: relative;
}

.fishes {
    position: relative;
    top: 30px;
    left: 27px;
    height: 100px;
    width: 100px;
    border-radius: 100%;
}
.fish {
    position: absolute;
    top: 0px;
    left: 0px;
}
.player-section {
    position: relative;
    left: -30px;
    top: 0;
    margin-top: 28px;
    margin-bottom: 60px;
}

.personal-info {
    padding-left: 70px;
    color: #8589a1;
    text-shadow: 0px 3px 4px rgb(0 0 0 / 20%);
}

.personal-info-right {
    text-align: right;
    margin-left: -60px;
    color: white;
}

@media only screen and (min-width: 985px) and (max-width: 1320px) {
    .profile-left-side-bg-sec-1 {
        width: 350px;
    }

    .profile-center-side-bg-sec-1 {
        width: 95%;
        margin-left: 70px;
    }

    .personal-info {
        padding-left: 60px;
        color: #8589a1;
    }

    .profile-rectangle {
        width: 250px;
        margin-left: 50px;
        padding-top: 8px;
        padding-left: 10px;
    }

    .profile-right-side-bg-sec-1 {
        width: 315px;
        margin-left: 25px;
    }

    .profile-right-rectangle-1 {
        width: 300px;
        margin-left: 10px;
    }

    .profile-left-side-bg-sec-2 {
        width: 350px;
    }

    .profile-rectangle-2 {
        width: 260px;
        margin-left: 45px;
        padding-top: 1px;
        padding-left: 10px;
    }

    .profile-left-side-bg-sec-3 {
        width: 350px;
    }

    .profile-rectangle-3 {
        width: 260px;
        margin-left: 42px;
    }

    .profile-center-side-bg-sec-3 {
        width: 424px;
        margin-left: 40px;
    }

    .profile-center-rectangle-2 {
        width: 340px;
        margin-left: 43px;
    }

    .profile-right-side-bg-sec-2 {
        width: 368px;
        margin-top: -30px;
        margin-left: 0px;
    }

    .profile-right-rectangle-2 {
        width: 270px;
        padding: 2px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 984px) {
    .profile-left-side-bg-sec-1 {
        width: 280px;
    }

    .profile-center-side-bg-sec-1 {
        width: 95%;
        margin-left: 60px;
        font-size: 12px;
    }

    .personal-info {
        padding-left: 40px;
        color: #8589a1;
    }

    .profile-rectangle {
        width: 200px;
        margin-left: 40px;
    }

    .profile-right-side-bg-sec-1 {
        width: 250px;
        margin-left: 19px;
    }

    .profile-right-rectangle-1 {
        width: 240px;
        margin-left: 6px;
        font-size: 11px;
    }

    .profile-left-side-bg-sec-2 {
        width: 280px;
    }

    .profile-rectangle-2 {
        width: 215px;
        margin-left: 30px;
        padding-top: 1px;
        padding-left: 10px;
    }

    .profile-left-side-bg-sec-3 {
        width: 280px;
    }

    .profile-rectangle-3 {
        width: 215px;
        margin-left: 30px;
        font-size: 11px;
    }

    .profile-center-side-bg-sec-3 {
        width: 326px;
        margin-left: 35px;
    }

    .profile-center-rectangle-2 {
        width: 260px;
        margin-left: 33px;
        font-size: 11px;
    }

    .profile-right-side-bg-sec-2 {
        width: 290px;
        margin-top: -30px;
        margin-left: 0px;
    }

    .profile-right-rectangle-2 {
        width: 230px;
        margin-left: -20px;
        padding: 2px;
        font-size: 11px;
    }
}

.award-section {
    padding-left: 50px;
    padding-top: 0px;
    width: 398px;
    height: 330px;
    overflow: hidden;
}

.award-section:hover {
    overflow-y: auto;
}

.won-games-section {
    width: 400px;
    height: 310px;
    overflow: hidden;
}

.won-games-section:hover {
    overflow-y: auto;
}

.partida-player-img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.tr_player:hover .partida-player-img{
    width: 50px;
    height: 50px;
    margin-top: -8px;
}

@media only screen and (min-width: 0px) and (max-width: 784px) {
    .friend_row {
      margin-top: 90px;
    }
  }

  .promo-code {
    background-image: url("../img/profile/Rectangle-2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 45px;
}

.promo-code:hover {
    background: #101011;
    width: 100%;
}

.promoCodeOverflow{
    overflow: hidden;
}

.promoCodeOverflow:hover{
    overflow-y: auto;
}
.apply-promo-code-button{
    margin-top:10px;
    background: yellow;
    font-weight: bold;
    width: 80px;
    height: 40px;
    box-shadow: 0px 0px 15px rgb(226 164 0 / 30%);
    font-family: "Exocet"
}

.apply-promo-code-button:hover{
    background: rgb(175, 175, 25);
    letter-spacing: 1px;
}


.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.crackers{
    background: url('../img/promo-code/crackers.gif');
    background-size: 100% 500%;
}

.promo-code-error{
    background: url('../img/modal_bottom_red_800.png') no-repeat;
    background-size: 100% 100%;
    
}

#notify_game_mobile_only_modal_overlay .modal_top:before {
    background: unset;
}

#notify_game_mobile_only_modal_overlay .modal_window .modal_content .terms_text{
    text-align: center;
    height: unset;
}

#notify_game_mobile_only_modal_overlay .modal_window .modal_content .terms_text .terms_scroll .noticia_text{
    font-size: 30px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content {
    text-align: center;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_welcome {
    color: white;
    font-size: 16px;
    margin-top: -25px;
    font-family: Albertus;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_title {
    position: relative;
    font-size: 40px;
    color: rgb(255, 198, 0);
    font-family: Albertus;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_title:before {
    content: "";
    display: block;
    background: url(../img/mobile-only-modal/title_decor_1.png) no-repeat;
    height: 40px;
    position: absolute;
    width: 180px;
    top: 5px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_title:after {
    content: "";
    display: block;
    background: url(../img/mobile-only-modal/title_decor_2.png) no-repeat;
    height: 40px;
    position: absolute;
    width: 617px;
    top: 5px;
    right: 0;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_chest {
    position: relative;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_chest .btn_promo_code {
    position: absolute;
    top: 61%;
    left: 270px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_chest .btn_promo_code button{
    background: url(../img/enter_button.png) no-repeat;
    background-size: cover;
    text-shadow: 1px 1px #649022;
    box-shadow: 0 1px 2px 0px #252525;
    position: relative;
    z-index: 5;
    height: 40px;
    color: white;
    width: 270px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_chest img {
    width: 390px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_chest:after {
    content: "";
    display: block;
    background: url(../img/mobile-only-modal/chest_texture.png) no-repeat;
    position: absolute;
    /* height: 40px; */
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_qr_scan {
    margin-top: 5px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_qr_scan .text {
    line-height: 19px;
    opacity: 0.9;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_qr_scan .qr_item {
    background: url(../img/mobile-only-modal/qr_bg.png) no-repeat;
    height: 185px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_qr_scan .brand_store_info {
    display: flex;
    text-align: left;
    justify-content: center;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_qr_scan .brand_store_info .brand_logo {
    margin: auto 0;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_qr_scan .brand_store_info .store_name {
    margin-left: 10px;
    line-height: 16px;
}

#notify_game_mobile_only_modal_overlay .mobile_only_content .mo_prize {
    color: white;
    margin: 15px auto -20px auto;
    width: 360px;
    background: url(../img/mobile-only-modal/prize_bg.png) no-repeat;
    padding: 0 25px;
    height: 72px;
    font-size: 16px;
    font-weight: bold;
}

#notify_game_mobile_only_modal_overlay .mo_bg_left_bottom {
    position: absolute;
    bottom: 21px;
    z-index: 1;
    background: url(../img/mobile-only-modal/phone_l.png) no-repeat;
    height: 395px;
    width: 310px;
}

#notify_game_mobile_only_modal_overlay .mo_bg_right_bottom {
    position: absolute;
    bottom: 21px;
    right: 0;
    z-index: 1;
    background: url(../img/mobile-only-modal/phone_r.png) no-repeat;
    height: 485px;
    width: 328px;
}

#notify_game_mobile_only_modal_overlay .mo_smoke_bg_bottom {
    position: absolute;
    bottom: 21px;
}

.sala-desktop-game-logo,
.sala-mobile-game-logo {
    height: 75px;
    margin: -35px -16px;
}

.sala-desktop-race-logo,
.sala-mobile-race-logo {
    height: 60px;
    margin: -45px -9px;
}

.salas-mobile-card-body {
    position: relative;
    z-index: 9;
}

.salas-mobile-card-body a {
    color: #fc7a52 !important;
}

.generaljackpot-game-logo {
    height: 75px;
    margin: -35px 0px;
}