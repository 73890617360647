@media screen and (max-width: 1730px) {
	.games {
		left: 80px;
	}
	
	.soc_panel,
	.toTop {
		right: 80px;
	}
	
	.eagle {
		left: 228px;
	}
}

@media screen and (max-width: 330px) {
	.payment_sum input[type="radio"] + label {
		zoom: 95%;
	}
}

@media screen and (max-width: 1580px) {
	.games {
		left: 2%;
	}
	
	.soc_panel,
	.toTop {
		right: 2%;
	}
	
	.eagle {
		left: 178px;
	}
}

@media screen and (max-width: 890px) {
	#tournaments .c_bottom,
	#generaljackpot .c_bottom{
		background-position: -80px bottom;
		padding: 84px 20px 40px 20px;
		width: auto;
	}
	
	#tournaments .c_center,
	#generaljackpot .c_center{
		background-position: -80px top;
		width: auto;
	}
	
	#tournaments .c_top,
	#generaljackpot .c_top{
		background-position: -80px top;
		width: auto;
	}

	#generaljackpot .c_bottom{
		padding-top: 21px;
		padding-left: 0px;
		padding-right: 0px;
	}

	#generaljackpot .cloth::before{
		width: 100%;
	}

	#generaljackpot .cloth::after{
		width: 100%;
	}

	#generaljackpot .game_ui_list {
		padding-left: 0px;
		padding-right: 0px;
	}
}

@media screen and (max-width: 1200px) {
	body {
		overflow-x: hidden;
		background-size: 140%;
	}
	
	.top_menu .wrapper {
		height: 75px;
	}
	
	.top_menu {
		z-index: 999;
	}
	
	.game_icon {
		margin-top: -14px;
		padding-right: 0px;
		margin-left: 5px;
	}

	/*.game_icon:hover:after {
		background: url(../img/icon_small_d.png) no-repeat;
	}*/
	
	.lang {
		margin-top: 23px;
		margin-right: 20px;
	}

	.blog-toggle {
		margin-top: 14px;
	}
	.blog-panel {
		margin-top: 75px;
		height: calc(100vh - 75px);
	}
	
	.games {
		left: -14px;
		z-index: 20;
	}
	
	.block {
		z-index: 1;
		float: none;
		width: 270px;
		height: 148px;
		margin-left: auto;
		margin-right: auto;
	}

	.block:after {
		width: 277px;
		height: 156px;
	}

	.block > div img{
		top: -120px;
	}

	.block > div {
		padding-top: 78px;
	}
	
	.soc_panel,
	.top_blocks:before,
	.blocks_shadow,
	.toTop,
	.games,
	.first_margin,
	.game_icon:before,
	.fire,
	.smoke_center,
	.sub-menu:before,
	.eagle,
	.leaves,
	.modal_bottom:before,
	.modal_bottom:after	{
		display: none !important;
	}

	.rotateslider-container:before {
		content: none;
	}

	.c_top:before,
	.c_top:after,
	.modal_bottom:before,
	.modal_bottom:after {
		content: none;
	}

	.top_blocks:after {
		content: none;
	}

	.sparks {
		width: 100px;
		left: 75%;
		margin-left: -50px;
		display: none;
	}
	
	.jackpot {
		height: unset;
		margin-top: -35px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-bottom: 30px;
		font-size: 30px;
		zoom: 70%;
		padding-left: 30px;
		padding-right: 30px;
	}

	.top_blocks .block{
		margin-top: 110px;
	}
	
	.content {
		background: none;
	}
	
	.logo_area {
		padding-top: 70px;
    	top: -25px;
	}
	
	.jackpot div {
		font-size: 50px;
	}
	
	.rotateslider-container .arrow {
		z-index: 19;
	}
	
	.rotateslider-container .arrow.left {
		left: -10px;
	}
	
	.rotateslider-container .arrow.right {
		right: -10px;
	}
	
	.wrapper,
	.footer .wrapper {
		width: auto;
	}
	
	.logo_area img {
		width: 320px;
	}
	
	.top_blocks {
		width: 100%;
		margin: 0px auto;
		height: auto;
	}
	
	.ba_right {
		margin: 0px;
		width: 100%;
	}
	
	.ba_left {
		width: auto;
		text-align: center;
	}
	
	.rotateslider-container {
		width: 70%;
	}

	.rotateslider-container .rotateslider-item.now{
		transform: translateY(-50%) translateX(-50%) scale(0.75);
	}

	#terms_modal, #privacy_modal, #faq_modal, #faq_rewards_modal, #faq_referral_modal, #faq_membership_modal, #rules_modal, #tickets_modal {
		width: 100%;
	}

	.c_bottom:before, .modal_top:before{
		content: none;
	}

	#game_history table tr:after, .tr_winner:after{
		content: none;
	}
	
	.page_block .table_list tr {
		white-space: nowrap;
	}

	.page_block .table_list {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}

	.company_selector {
		display: none;
	}

	.nickname .sub-menu > li > a, .nickname .sub-menu > li > .link-button {
		padding: 0px 23px 0px 30px;
	}

	.nickname .sub-menu > li > a:before, .nickname .sub-menu > li > .link-button:before {
		left: 0px;
	}
	
	.ba_left h2 {
		margin-bottom: 15px;
	}
	
	.ba_left button {
		margin: 20px auto;
	}
	
	.adv_list {
		margin: 0px;
		padding-top: 0px;
		text-align: center;
	}
	
	.adv span {
		width: auto;
		padding: 0px 16px;
	}
		
	.adv_icon {
		background-size: 80%;
		width: 180px;
		height: 180px;
	}
	
	.adv_icon img {
		width: 100%;
		margin-top: -8px;
	}
	
	.footer {
		margin-top: 40px;
	}
	
	.payments {
		padding: 0px 30px;
		margin-bottom: -20px;
	}
	
	.payments a {
		margin: 10px;
	}
	
	.copyright {
		padding: 20px 40px 40px 40px;
		margin-top: -20px;
		text-align: center;
	}
	
	.f_menu {
		float: none;
		margin-bottom: 10px;
		overflow: hidden;
	}
	
	.f_menu a,
	.f_menu span.link-button {
		display: inline-block;
		margin: 5px;
		width: 100%;
	}
	
	.soc {
		float: none;
		width: auto;
		text-align: center;
		margin-top: 0px;
	}
	
	.soc a {
		display: inline-block;
		margin: 0px 10px;
		float: none;
	}
	
	.f_content {
		padding: 30px 0px 30px 0px;
		text-align: center;
	}
	
	.f_text {
		width: auto;
		float: none;
	}
	
	.f_logos {
		width: auto;
		margin-bottom: 20px;
	}
	
	.f_logos img {
		float: none;
		margin: 0px 10px;
	}
	
	.logo_footer {
		margin-bottom: -12px !important;
	}
	
	#menu_icon {
		display: block;
	}
	/* Mobile Menu */
	
	.toggle-button {
		display: block;
	}
	
	.toggle-button {
		position: fixed;
		width: 60px;
		height: 60px;
		padding: 24px 16px 16px 21px;
		top: 5px;
		left: 5px;
		transition: .25s;
		z-index: 15;
	}
	
	.toggle-button:hover {
		cursor: pointer;
	}
	
	.toggle-button .menu-bar {
		position: absolute;
		width: 16px;
		transition: .5s;
	}
	
	.toggle-button .menu-bar-top {
		border: 1px solid #fff;
		border-bottom: none;
		top: 24;
	}
	
	.toggle-button .menu-bar-bottom {
		border: 1px solid #fff;
		border-top: none;
		top: 34px;
	}
	
	.button-open .menu-bar-top {
		border-color: #fff;
		transform: rotate(45deg) translate(8px, 8px);
		transition: .5s;
	}
	
	.button-open .menu-bar-bottom {
		border-color: #fff;
		transform: rotate(-45deg) translate(7px, -7px);
		transition: .5s;
		top: 40px;
	}
	
	.button-open {
		width: 23px;
		height: 25px;
		padding: 19px 16px 16px 21px;
	}
	
	.menu-wrap {
		position: fixed;
		top: 0;
		height: 100%;
		width: 80%;
		font-size: 15px;
		margin-left: -90%;
		overflow: auto;
		transition: .25s;
		z-index: 12;
		background: rgba(0,0,0,0.9);
		box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
	}
	.menu-show {
		margin-left: 0;
	}
	
	.top-fixed .profile {
		padding-top: 100px;
	}
	
	.top-panel input {
		background: rgba(83,79,74,0.2);
		width: 100%;
		box-sizing: border-box;
		margin-right: 0px;
		margin-bottom: 10px;
	}
	
	.login_panel input,
	.login_panel button,
	.login_panel .bordered_button,
	.login_panel .register_button {
		width: 100%;
		box-sizing: border-box;
	}
	
	.login_panel span {
		margin: 0px;
		width: 100%;
		text-align: center;
	}
	
	.profile {
		float: none;
		padding: 100px 20px;
		width: auto;
	}
	
	.login_button,
	.register_button {
		background: url(../img/login_button_mobile.jpg);
		background-size: cover;
	}
	
	.login_panel > div,
	.register_button > div {
		width: 100%;
	}
	
	.balance {
		width: 100%;
		box-sizing: border-box;
		height: 70px;
	}
	
	.duels, .referral-contests, .nickname button {
		margin-left: 0px;
		margin-bottom: 15px;
		/* width: 100%; */
		box-sizing: border-box;
		text-align: left;
	}
	
	.nickname {
		width: 100%;
	}
	
	.nickname .main-item:focus ~ .sub-menu,
	.nickname .main-item:active ~ .sub-menu,
	.nickname .sub-menu:hover {
		background: none;
		box-shadow: none;
		top: 25px;
		right: auto;
		left: 0px;
		width: 100%;
	}
	
	.nickname .sub-menu > li > a:before {
		left: 0px;
	}
	
	.page_content {
		width: auto;
		padding: 0px;
	}
	
	.profile_data {
		padding: 10px 0px;
	}
	
	.nickname .sub-menu > li > a {
		padding: 0px 0px 0px 30px;
	}
	
	.nickname .sub-menu hr {
		margin: 10px 0px;
	}
	
	.c_top {
		background-position: center top;
	}
	
	.c_bottom {
		background-position: center bottom;
		margin-bottom: 0px;
		padding: 84px 20px 65px 20px;
	}
	
	.c_center {
		margin-top: 160px;
	}
	
	.c_top,
	.c_bottom,
	.c_center {
		width: auto;
	}
	
	.tabs > label {
		padding: 0px 12px;
	}
	
	.td_title {
		width: 100px;
	}
	
	.select {
		/* width: 100%; */
		box-sizing: border-box;
		height: 48px;
		line-height: 48px;
		margin: 0px;
	}
	
	/* .select button {
		padding-left: 5px;
		margin-left: 0px;
		margin-top: 11px;
		float: left;
		width: 100% !important;
	}

	.d-flex .select button {
		padding-left: 0px;
		margin-left: 0px;
		margin-top: 0px;
	} */
	
	table h5 {
		padding-left: 0px;
		line-height: 18px;
	}
	
	table td {
		vertical-align: top;
	}
	
	.public_info .select button {
		width: 100%;
	}
	
	.c_bottom h4 {
		font-size: 30px;
		margin: -20px 0px 0px 0px;
	}
	
	.c_top:after {
		right: 50%;
		margin-right: -497px;
	}
	
	.deposit_area {
		width: auto;
		float: none;
	}
	
	.text {
		padding: 0px 30px;
		margin-bottom: 10px;
	}
	
	.rules {
		margin: 0px;
		padding-left: 20px;
		padding-top: 40px;
		border: none;
		min-height: auto;
		border-top: 1px solid #d0b590;
		margin-top: 40px;
	}

	#premios_modal .modal_content {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.payment_sum {
		text-align: center;
	}
	
	.payment_sum input[type="radio"] + label {
		width: 120px;
		height: 160px;
		background: url(../img/deposit_sum.jpg) no-repeat center;
		background-size: cover;
		padding: 0px;
		border: none;
		margin: 4px;
		box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
		line-height: 0px;
		text-align: center;
		padding-top: 10px;
	}
	
	.payment_system input[type="radio"] + label {
		height: 70px;
	}
	
	.payment_sum input[type="radio"]:checked + label:after,
	.payment_system input[type="radio"]:checked + label:after {
		top: 14px;
		left: 14px;
	}
	
	.payment_system input[type="radio"] + label:before {
		top: 24px;
	}
	
	.payment_system input[type="radio"]:checked + label:after {
		top: 29px;
	}
	
	#game_history table td {
		padding: 9px 9px;
	}
	
	.subtitle td {
		line-height: 15px;
		padding: 8px 9px !important;
	}
	
	.t_game_icon img {
		margin: -23px;
	}
	
	.paginator {
		position: relative;
		z-index: 4;
	}
	
	.paginator a, .paginator span {
		width: 40px;
	}
	
	#page_active:before {
		left: 2px;
	}
	
	#game_ui .c_bottom {
		padding: 84px 20px 54px 20px;
	}
	
	.game_ui {
		margin: 0px;
	}
	
	.game_ui_list {
		margin: 0px -15px;
	}

	#game_ui.lg .game_ui {
		height: 781px;
	}

	#game_ui.lg .c_top {
		background-size: 958px 900px;
	}

	.page_block .game_ui_list {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		margin: 0px;
	}

	.page_block .game_ui_list .td_win{
		min-width: 250px;
	}

	.page_block .game_ui_list tr {
		white-space: nowrap;
	}
	
	.game_stat, .game_status {
		top: 57px;
	}
	
	.premio {
		margin-top: -45px;
	}
	
	.td_win > div {
		float: none;
	}
	
	.game_ui_list table td {
		padding: 10px;
	}
	
	.tr_winner:after {
		height: 120px;
		background-size: cover;
		margin-left: -440px;
		margin-top: -33px;
	}
	
	.modal_window {
		width: auto;
		top: 30px !important;
		bottom: 0px;
		background: url(../img/center_bg.jpg) center top;
	}


	#premios_modal.modal_window{
		max-width: 500px;
	}

	#premios_modal .modal_content{
		max-width: 450px;
		margin: auto;
	}


	.modalx-overlay {
		overflow-x: hidden;
	}
	
	.modalx-overlay::-webkit-scrollbar {
		width: 0px;
	}
	
	#close_modal {
		right: 10px;
	}

	.modal_top {
		background: url(../img/top_bg.jpg) center top no-repeat;
	}
	
	.modal_bottom {
		background: url(../img/bottom_bg.png) center bottom no-repeat;
	}
	
	.modal_content {
		padding: 70px 40px 80px 40px;
	}

	.error_reason {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		margin: 0px;
		right: 0px;
		top: 0px;
		margin-bottom: 15px;
	}
	
	.error:before {
		top: auto;
		bottom: 7px;
	}
	
	.error_reason:before {
		content: ''; 
		position: absolute;
		left: auto;
		right: 20px;
		top: auto;
		bottom: -16px;
		border: 8px solid transparent;
		border-top: 8px solid rgba(0,0,0,0.95);
	}
	
	.level {
		width: auto;
	}
	
	#game_page table td {
		padding: 5px;
	}
	
	#rewards .c_bottom,
	#referral .c_bottom {
		background-position: -80px bottom;
		padding: 84px 20px 40px 20px;
		width: auto;
	}
	
	#rewards .c_center,
	#referral .c_center	{
		background-position: -80px top;
		width: auto;
	}
	
	#rewards .c_top,
	#referral .c_top	{
		background-position: -80px top;
		width: auto;
	}
	
	#tournaments .page_block,
	#rewards .page_block,
	#referral .page_block,
	#support .page_block {
		overflow-x: scroll;
		display: block;
		width: 100%;
	}
	
	#tournaments .table_list,
	#generaljackpot .table_list,
	#rewards .table_list,
	#referral .table_list,
	#support .table_list {
		width: 680px;
		overflow: hidden;
	}
	
	#tournaments .page_block::-webkit-scrollbar,
	#rewards .page_block::-webkit-scrollbar,
	#referral .page_block::-webkit-scrollbar,
	#support .page_block::-webkit-scrollbar {
		height: 2px;
	}
	
	.faq_help {
		position: absolute;
		right: 50px;
		top: 30px;
	}

	.faq_help.game {
		margin-bottom: 39px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		right: unset;
		top: unset;
		z-index: 999;
	}
	
	.tour_info {
		width: 245px;
	}
	
	#support h4 {
		text-align: center;
	}
	
	#support .faq_help {
		top: 0px;
		margin: 10px 0px;
	}
	
	#tournaments table {
		width: 700px;
		overflow: hidden;
	}

	.page_block {
		display: block;
	}
	
	#support .page_block {
		overflow-y: hidden;
	}
	
	.ticket_status span {
		width: 100px;
		display: inline-block;
	}
	
	#terms_modal,
	#faq_modal,
	#tickets_modal {
		width: auto;
		background-position: 50% 50%;
	}
	
	#terms_modal .modal_top,
	#faq_modal .modal_top,
	#terms_modal .modal_bottom,
	#faq_modal .modal_bottom,
	#tickets_modal .modal_bottom {
		background: none;
	}
	
	#terms_modal .modal_content,
	#faq_modal .modal_content,
	#tickets_modal .modal_content {
		padding: 70px 20px 80px 20px;
	}
	
	#tickets_modal h5 {
		text-align: center;
		margin-top: 20px;
	}
	
	.ticket_time {
		text-align: center;
	}
	
	.rew_text {
		width: auto;
		float: none;
	}
	
	.reward_info {
		display: block;
	}
	
	.rew_balance {
		margin-left: auto;
		margin-top: 1px;
	}
	
	.rew_filters {
		float: none;
		margin-top: 10px;
	}
	
	.rew_filters .select {
		height: 43px;
		line-height: 43px;
	}
	
	#rewards .c_bottom h4,
	#referral .c_bottom h4 {
		font-size: 24px !important;
		text-align: center;
	}
	
	.rew_items  {
		text-align: center;
	}
	
	.r_item {
		float: none;
	}
	
	.rewards {
		margin-left: 0px;
	}
	
	#profile_modal,
	#challenger_modal {
		width: auto;
	}
	
	.personal_info, .level_info {
		float: none;
		width: auto;
		margin: 0px;
	}
	
	#profile_modal .modal_content,
	#challenger_modal .modal_content {
		padding: 30px;
	}
	
	.profile_avatar {
		margin-top: 10px;
	}
	
	.profile_avatar:before {
		width: 100%;
	}
	
	.level_info {
		margin-top: 20px;
	}
	
	.level_info button {
		width: 220px;
	}
	
	#profile_modal .modal_top,
	#challenger_modal .modal_top {
		background: url(../img/tournament_top.jpg) center top no-repeat;
	}
	
	#profile_modal .modal_bottom,
	#challenger_modal .modal_bottom	{
		background: url(../img/tournament_bottom.jpg) center bottom no-repeat;
	}
	
	#profile_modal,
	#challenger_modal {
		background: url(../img/tournament_center.jpg);
		background-position: 50% 50%;
	}
	
	#activated_modal {
		top: 125px;
		position: relative;
		margin-bottom: -150px;
	}
	
	.activate_buttons {
		padding: 0px 100px;
	}
	
	.activate_buttons button {
		width: 100%;
		box-sizing: border-box;
		margin: 5px 0px;
	}
	
	.mt_block {
		display: none;
	}
	.slider-handle {
		display: none;
	}
	
	.notifications {
		float: none;
	}
	
	.balance > button {
		margin-top: -27px;
		width: 72px;
	}
	
	.notifications .main-item:focus ~ .sub-menu,
	.notifications .main-item:active ~ .sub-menu,
	.notifications .sub-menu:hover {
		top: 140px;
		left: 0px;
	}
	
	.game_sel {
		overflow: hidden;
	}
	
	.game_selector {
		background-image: none;
	}
	
	.entry_cost .radio_r input[type="radio"] + label {
		width: 70px;
	}
	
	.entry_cost .radio_r input[type="radio"]:checked + label {
		width: 66px;
	}
	
	#challenger_modal .activate_buttons {
		padding: 0px;
		padding-bottom: 15px;
	}
	
	.vs_block {
		display: none;
	}
	
	.forgot_password {
		float: none;
		text-align: center;
		padding-left: 0px;
	}

	.forgot_password .link-button {
		width: 100%;
		line-height: 35px;
	}

}

@media screen and (max-width: 998px) {

	.subscription_content {
		width: auto;
		height: 1400px;
	}
	.subscription_layer {
		height: 1400px;
	}
	.subscription_layer .justify-content-center {
		margin: 30px 0px;
	}
	.payment_sum {
		text-align: center;
	}
	.payment_subscribe {
		display: block;
		margin: 0px;
	}

	.payment_sum input[type="radio"] + label.subscription_label {
		display: block;
		margin: 0 auto;
	}
		
	#spin_modal.modal_window {
		max-width: 400px;
	}

	#spin_modal #close_modal {
		right: 38px;
		top: 38px;
	}

	#spin_modal .game_image {
		display: none;
	}

	#spin_modal .modal_content .spin-container {
		max-width: 350px;
		margin: auto;
		display: flex;
		flex-direction: column;
		padding-top: 20px;
	}

	#spin_modal .spin-container .spin-right {
		display: none;
	}

	#spin_modal .spin-container .spin-mobile {
		display: block;
	}

	#spin_modal .spin-container img.spin-mobile {
		width: 100%;
	}

	#spin_modal .spin-container .spin-mobile.spin-text {
		margin-top: 24px;
		font-size: 16px;
	}

	#spin_modal .spin-result-container {
		margin-top: 0px;
		margin-left: -30px;
		margin-right: -30px;
	}

	#spin_modal .c_top  {
		width: 100%;
		background: url(../img/spin_top_320.png) no-repeat;
	}
	
	#spin_modal .c_bottom {
		width: 100%;
		padding: 40px 60px 30px 60px;
		background: url(../img/spin_bottom_320.png) center bottom no-repeat;
	}
	
	#spin_modal .c_center {
		width: 100%;
		background: url(../img/spin_center_320.png) center -155px repeat;
	}
	
}

@media screen and (max-width: 320px) {
	.td_title {
		width: 100px;
	}
}

@media screen and (max-width: 650px) {

	.membership-expiration {
		font-size: 14px;
		color: white;
		font-weight: 700;
	}

	.membership-image {
		width: 0px;
	}
	
	.membership-next-image {
		width: 0px;
	}

	.membership-bar {
		margin: 20px 0;
	}
}

@media screen and (max-width: 420px) {

	.membership-expiration {
		font-size: 14px;
		color: white;
		font-weight: 700;
		margin-top: -4px;
		margin-bottom: 10px;
	}

	.membership-bar {
		height: 18px;
		width: 180px;
		/* background: url(../img/membership/membership_progress_bg_small.png) no-repeat; */
		margin: 0px;
		position: relative;
		display: flex;
		overflow: hidden;
	}

	.membership-image {
		width: 40px;
	}
	
	.membership-next-image {
		width: 40px;
	}

	.membership-clock-image {
		height: 18px;
	}

	/* .CircularProgressbar, svg  {
		width: 32px;
		height: 32px;
	  } */
	
	.membership-point {
		font-size: 10px;
		color: white;
		font-weight: 700;
		width: 100%;
		text-align: center;
		margin-top: 1px;
	}
		
	.membership-bar .membership-progress {
		background: url(../img/membership/membership_progress_bar.png) left;
		height: 18px;
		text-align: left;
		font-size: 10px;
	}
	
	.membership-next {
		font-size: 14px;
		margin-top: 0px;
	}
	
	.membership-bar div span {
		color: #61bb11;
	}

	.membership-title {
		font-size: 12px;
		font-family: 'Albertus';
		color: #f08d2f;
		text-transform: uppercase;
		font-weight: 700;
	}
}

@media screen and (max-width: 1560px) {
	.App-blog-open {
		width: 100%;
	}
}

@media screen and (max-width: 1320px) and (min-width: 985px) {
	#objective .c_top:before,
	#objective .c_top:after,
	#membership .c_top:before,
	#membership .c_top:after,
	#laddergames .c_top:before,
	#laddergames .c_top:after {
		background: none;
		content: none;
	}

	#objective .c_center,
	#membership .c_center,
	#laddergames .c_center
	 {
		width: 984px;
	}

	#objective .c_top,
	#membership .c_top,
	#laddergames .c_top {
		width: 984px;
	}

	.membership-card-container {
		margin-top: 70px;
		margin-bottom: 50px;
		padding-left: 30px;
		padding-right: 30px;
	}

	.membership-card-bg {
		background: url(../img/membership/card-bg-black.jpg) no-repeat;
		height: auto;
		width: 100%;
		min-width: 180px;
		max-width: 240px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	.friend-card-bg {
		background: url(../img/membership/card-bg-black.jpg) no-repeat;
		height: auto;
		width: 100%;
		min-width: 180px;
		max-width: 240px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	.friend-card-bg-2 {
		background: url(../img/membership/card-frame.png) no-repeat;
		height: auto;
		width: 100%;
		min-width: 180px;
		max-width: 240px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	
	.membership-card-bg-2 {
		background: url(../img/membership/card-frame.png) no-repeat;
		height: auto;
		width: 100%;
		min-width: 180px;
		max-width: 240px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}

	
	.membership-card {
		font-family: 'OpenSans';
		padding: 12px 20px;
	}

	
	.membership-title {
		width: 90%;
		font-style: normal;
		font-weight: 300;
		font-size: 22px;
		line-height: 28px;
		font-family: 'Exocet';
		text-align: center;
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.friend-title {
		width: 200px;
		font-style: normal;
		font-weight: 300;
		font-size: 19px;
		line-height: 28px;
		font-family: 'Exocet';
		text-align: center;
		text-transform: uppercase;
		background: -webkit-linear-gradient(#107d69, #ffebd5);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.membership-card .membership-description {
		font-size: 12px;
		color: #958063;
		font-weight: 300;
		line-height: 14px;
		margin-top: 10px;
	}
	
	.membership-card .membership-icon {
		width: 72px;
	}
	
	.membership-card .membership-point {
		font-size: 16px;
		color: #ffffff;
	}
	
	.membership-card .membership-gem-discount {
		font-size: 16px;
		background: -webkit-linear-gradient(#677728, #94C142);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	.membership-card .membership-free-ticket {
		font-size: 16px;
		color: #FFA217;
	}

	.objective-col {
		flex: 33.33%;
		max-width: 33.33%;
	}

	.tournament-col {
		flex: 33.33%;
		max-width: 33.33%;
	}
}

@media screen and (max-width: 600px) {
	.top_blocks {
		zoom: 60%;
	}
}

@media screen and (max-width: 984px) and (min-width: 761px) {
	#objective .c_top:before,
	#objective .c_top:after,
	#membership .c_top:before,
	#membership .c_top:after,
	#laddergames .c_top:before,
	#laddergames .c_top:after {
		background: none;
		content: none;
	}

	#objective .c_center,
	#membership .c_center,
	#laddergames .c_center {
		width: 760px;
	}

	#objective .c_top,
	#membership .c_top,
	#laddergames .c_top {
		width: 760px;
	}

	.membership-image {
		width: 70px;
	}
	
	.membership-next-image {
		width: 70px;
	}

	.membership-name {
		width: 90px;
		font-size: 10px;
		line-height: 12px;
	}

	.membership-point {
		font-size: 24px;
	}

	.membership-progress-bg {
		background: url(../img/membership/card-bg-black-810.png) no-repeat;
		height: auto;
		width: 600px;
		min-width: 400px;
		background-position: center;
		background-size: cover;
	}
	
	.membership-proress-frame {
		background: url(../img/membership/card_frame_land.png) no-repeat;
		height: auto;
		width: 600px;
		min-width: 400px;
		aspect-ratio: 810 / 221;
		background-position: center;
		background-size: cover;
	}

	.membership-expiration {
		background: #0D0B0B;
		margin-top: 16px;
		padding: 4px 6px;
	}

	.membership-card-container {
		margin-top: 40px;
		margin-bottom: 0px;
		padding-left: 30px;
		padding-right: 30px;
	}

	.membership-bar {
		height: 40px;
		width: 400px;
		background: url(../img/membership/membership_progress_bg_400.png) no-repeat;
		margin: 0px;
		position: relative;
		overflow: hidden;
	}

	.membership-bar .membership-fr {
		width: 100%;
		height: 100%;
		background: url(../img/membership/membership_progress_fr_400.png) left;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.membership-bar .membership-progress {
		background: url(../img/membership/membership_progress_bar_400.png) left;
		height: 24px;
		margin-top: 8px;
		text-align: left;
		font-size: 10px;
	}
	
	.membership-expiration span {
		text-align: center;
		font-size: 14px;
		font-family: 'Exocet';
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.membership-card-bg {
		background: url(../img/membership/card-bg-black.jpg) no-repeat;
		height: auto;
		width: 100%;
		min-width: 160px;
		max-width: 200px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	
	.membership-card-bg-2 {
		background: url(../img/membership/card-frame.png) no-repeat;
		height: auto;
		width: 100%;
		min-width: 160px;
		max-width: 200px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	
	.membership-card {
		font-family: 'OpenSans';
		padding: 12px 20px;
	}

	
	.membership-title {
		width: 140px;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 16px;
		font-family: 'Exocet';
		text-align: center;
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	.membership-card .membership-description {
		font-size: 10px;
		color: #958063;
		font-weight: 300;
		line-height: 16px;
		margin-top: 4px;
	}
	
	.membership-card .membership-icon {
		width: 60px;
	}
	
	.membership-card .membership-point {
		font-size: 12px;
		color: #ffffff;
	}
	
	.membership-card .membership-gem-discount {
		font-size: 12px;
		background: -webkit-linear-gradient(#677728, #94C142);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	.membership-card .membership-free-ticket {
		font-size: 12px;
		color: #FFA217;
	}

	.objective-col {
		flex: 50%;
		max-width: 50%;
	}

	.tournament-col {
		flex: 50%;
		max-width: 50%;
	}
}

@media screen and (max-width: 760px) and (min-width: 569px) {
	#objective .c_top:before,
	#objective .c_top:after,
	#membership .c_top:before,
	#membership .c_top:after,
	#laddergames .c_top:before,
	#laddergames .c_top:after {
		background: none;
		content: none;
	}

	#objective .c_center,
	#membership .c_center,
	#laddergames .c_center {
		width: 100%;
	}

	#objective .c_top,
	#membership .c_top,
	#laddergames .c_top {
		width: 100%;
	}

	.membership-image {
		width: 70px;
	}
	
	.membership-next-image {
		width: 70px;
	}

	.membership-name {
		width: 90px;
		font-size: 10px;
		line-height: 12px;
	}

	.membership-point {
		font-size: 24px;
	}

	.membership-expiration {
		background: #0D0B0B;
		margin-top: 16px;
		padding: 4px 6px;
	}

	.membership-bar {
		height: 40px;
		width: 400px;
		background: url(../img/membership/membership_progress_bg_400.png) no-repeat;
		margin: 0px;
		position: relative;
		overflow: hidden;
	}

	.membership-bar .membership-fr {
		width: 100%;
		height: 100%;
		background: url(../img/membership/membership_progress_fr_400.png) left;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.membership-bar .membership-progress {
		background: url(../img/membership/membership_progress_bar_400.png) left;
		height: 24px;
		margin-top: 8px;
		text-align: left;
		font-size: 10px;
	}
	
	.membership-expiration span {
		text-align: center;
		font-size: 14px;
		font-family: 'Exocet';
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.membership-progress-bg {
		background: url(../img/membership/card-bg-black-810.png) no-repeat;
		height: auto;
		width: 600px;
		min-width: 400px;
		background-position: center;
		background-size: cover;
	}
	
	.membership-proress-frame {
		background: url(../img/membership/card_frame_land.png) no-repeat;
		height: auto;
		width: 600px;
		min-width: 400px;
		aspect-ratio: 810 / 221;
		background-position: center;
		background-size: cover;
	}

	.membership-card-container {
		margin-top: 40px;
		margin-bottom: 20px;
		padding-left: 30px;
		padding-right: 30px;
	}

	.membership-card-bg {
		background: url(../img/membership/card-bg-black.jpg) no-repeat;
		height: auto;
		width: 100%;
		min-width: 290px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	
	.membership-card-bg-2 {
		background: url(../img/membership/card-frame.png) no-repeat;
		height: auto;
		width: 100%;
		min-width: 290px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	
	.membership-card {
		font-family: 'OpenSans';
		padding: 20px 36px;
	}
	
	
	.membership-title {
		width: 200px;
		font-style: normal;
		font-weight: 300;
		font-size: 28px;
		line-height: 31px;
		font-family: 'Exocet';
		text-align: center;
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	.membership-card .membership-description {
		font-size: 16px;
		color: #958063;
		font-weight: 300;
		line-height: 22px;
		margin-top: 15px;
	}
	
	.membership-card .membership-icon {
		width: 130px;
	}
	
	.membership-card .membership-point {
		font-size: 16px;
		color: #ffffff;
	}
	
	.membership-card .membership-gem-discount {
		font-size: 16px;
		background: -webkit-linear-gradient(#677728, #94C142);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	.membership-card .membership-free-ticket {
		font-size: 16px;
		color: #FFA217;
	}

	.objective-col {
		flex: 50%;
		max-width: 50%;
	}

	.tournament-col {
		flex: 50%;
		max-width: 50%;
	}
}

@media screen and (max-width: 661px) {
	#objective .c_bottom::before,
	#membership .c_bottom::before,
	#laddergames .c_bottom::before {
		background: none;
		width: 100%;
	}

	.filter-bar {
		display: flex;
		justify-content: center;
	}

	.filter-bar .right-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 8px;
	}
}

@media screen and (max-width: 568px) {
	#objective .c_top:before,
	#objective .c_top:after,
	#membership .c_top:before,
	#membership .c_top:after,
	#laddergames .c_top:before,
	#laddergames .c_top:after {
		background: none;
		content: none;
	}

	#objective .c_center,
	#membership .c_center,
	#laddergames .c_center {
		width: 100%;
	}

	#objective .c_top,
	#membership .c_top,
	#laddergames .c_top {
		width: 100%;
	}

	.membership-image {
		width: 40px;
	}
	
	.membership-next-image {
		width: 40px;
	}

	.membership-name {
		width: 50px;
		font-size: 8px;
		line-height: 10px;
	}

	.membership-point {
		font-size: 16px;
	}

	.membership-expiration {
		background: #0D0B0B;
		margin-top: 6px;
		padding: 2px 4px;
	}

	.membership-expiration span {
		text-align: center;
		font-size: 8px;
		font-family: 'Exocet';
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.membership-bar {
		height: 20px;
		width: 252px;
		background: url(../img/membership/membership_progress_bg_252.png) no-repeat;
		margin: 0px;
		position: relative;
		overflow: hidden;
	}

	.membership-bar .membership-fr {
		width: 100%;
		height: 100%;
		background: url(../img/membership/membership_progress_fr_252.png) left;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.membership-bar .membership-progress {
		background: url(../img/membership/membership_progress_bar_252.png) left;
		height: 12px;
		margin-top: 4px;
		text-align: left;
		font-size: 10px;
	}
	
	.membership-expiration spaaergergn {
		text-align: center;
		font-size: 14px;
		font-family: 'Exocet';
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.membership-progress-bg {
		background: url(../img/membership/card-bg-black-810.png) no-repeat;
		height: auto;
		width: 360px;
		min-width: 360px;
		background-position: center;
		background-size: cover;
	}
	
	.membership-proress-frame {
		background: url(../img/membership/card_frame_land.png) no-repeat;
		height: auto;
		width: 360px;
		min-width: 360px;
		aspect-ratio: 810 / 221;
		background-position: center;
		background-size: cover;
	}

	.membership-card-bg {
		background: url(../img/membership/card-bg-black.jpg) no-repeat;
		height: auto;
		width: 100%;
		min-width: 360px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	
	.membership-card-bg-2 {
		background: url(../img/membership/card-frame.png) no-repeat;
		height: auto;
		width: 100%;
		min-width: 360px;
		aspect-ratio: 289 / 413;
		background-position: center;
		background-size: cover;
	}
	
	.membership-card {
		font-family: 'OpenSans';
		padding: 30px 20px;
	}
	
	
	.membership-title {
		width: 400px;
		margin-bottom: 20px;
		font-style: normal;
		font-weight: 300;
		font-size: 44px;
		line-height: 40px;
		font-family: 'Exocet';
		text-align: center;
		text-transform: uppercase;
		background: -webkit-linear-gradient(#DBA76D, #875E31);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	.membership-card .membership-description {
		font-size: 24px;
		color: #958063;
		font-weight: 300;
		line-height: 24px;
		margin-top: 20px;
	}
	
	.membership-card .membership-icon {
		width: 160px;
	}
	
	.membership-card .membership-point {
		font-size: 28px;
		color: #ffffff;
	}
	
	.membership-card .membership-gem-discount {
		font-size: 28px;
		background: -webkit-linear-gradient(#677728, #94C142);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	.membership-card .membership-free-ticket {
		font-size: 28px;
		color: #FFA217;
	}

	.objective-col {
		flex: 100%;
		max-width: 100%;
	}

	.tournament-col {
		flex: 100%;
		max-width: 100%;
	}
}