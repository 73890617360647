button.pulse {
    z-index: 1;
    position: relative;
    font-size: inherit;
    font-family: inherit;
    color: white;
    padding: 0.5em 1em;
    outline: none;
    border: none;
}

button.pulse:hover,
button.pulse:active {
    cursor: pointer;
}

button.pulse.btn-secondary::before {
    border: 4px solid var(--secondary);
    border-radius: .25rem;
}

button.pulse::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-origin: center;
    transform: scale(1);
}

button.pulse:hover::before,
button.pulse:active::before {
    transition: all 0.75s ease-in-out;
    transform-origin: center;
    transform: scale(1.75);
    opacity: 0;
}

button.jelly {
    z-index: 1;
    font-size: inherit;
    font-family: inherit;
    color: white;
    padding: 0.5em 1em;
    outline: none;
    border: none;
}

button.jelly:active {
    cursor: pointer;
    animation: jelly 2s;
}

@keyframes jelly {
    0%,
    100% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(0.9, 1.1);
    }
    50% {
        transform: scale(1.1, 0.9);
    }
    75% {
        transform: scale(0.95, 1.05);
    }
}

button.press {
    box-shadow: 0 1px 2px 0px #252525;
}

button.press:active {
    box-shadow: 0px 0px 2px 0px #252525 !important;
    transform: translateY(1px);
    transition: 0ms;
}

