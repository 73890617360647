.top-enter-active {
    transition: all .3s ease-in-out .3s
}

.top-leave-active {
    transition: all .15s ease-in-out 0s
}

.top-enter {
    transform: translateY(-5px);
    opacity: 0
}

.bottom-enter-active {
    transition: all .3s ease-in-out .3s
}

.bottom-leave-active {
    transition: all .15s ease-in-out 0s
}

.bottom-enter {
    transform: translateY(5px);
    opacity: 0
}

.left-enter-active {
    transition: all .3s ease-in-out .3s
}

.left-leave-active {
    transition: all .15s ease-in-out 0s
}

.left-enter {
    transform: translateX(-5px);
    opacity: 0
}

.right-enter-active {
    transition: all .3s ease-in-out .3s
}

.right-leave-active {
    transition: all .15s ease-in-out 0s
}

.right-enter {
    transform: translateX(5px);
    opacity: 0
}

.fade-enter-active {
    transition: opacity .5s
}

.fade-enter {
    opacity: 0
}

.anim-coin {
    -webkit-animation: splash-data-v-4cb2cb27 .7s linear infinite;
    animation: splash-data-v-4cb2cb27 .7s linear infinite
}

@-webkit-keyframes splash-data-v-4cb2cb27 {
    0% {
        top: -20px
    }

    to {
        top: 90px
    }
}

@keyframes splash-data-v-4cb2cb27 {
    0% {
        top: -20px
    }

    to {
        top: 90px
    }
}

@-webkit-keyframes dropDown-data-v-4cb2cb27 {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes dropDown-data-v-4cb2cb27 {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.gray {
    color: #7b8ba4!important
}

.white {
    color: #fff!important
}

.fw700 {
    font-weight: 700
}

.redbox {
    position: relative;
    margin: 0 0 28px
}

.redbox .completed {
    opacity: 1;
    position: absolute;
    z-index: 1;
    transform: scale(.5) translateY(-100%);
    transition: all .5s;
    top: 50%;
    left: 14%
}

@media(max-width: 1020px) {
    .redbox .completed {
        transform:scale(.6);
        left: -7%;
        top: -15%
    }
}

.redbox .opened {
    bottom: -3.5%
}

.redbox:hover {
    cursor: pointer
}

.redbox:hover .completed {
    opacity: 0!important
}

.redbox:hover .op_completed {
    opacity: 1!important
}

.redbox__line {
    display: none
}

/*.redbox_new:after {
    pointer-events: none;
    content: url(../img/redbox-new.2b5f2c79.svg);
    position: absolute;
    right: 0;
    top: 0
}*/

@media(max-width: 1020px) {
   /* .redbox_new:after {
        content:url(../img/new_small.66ee7856.svg);
        position: absolute;
        left: 10.5px;
        top: 1px;
        transform: scale(1.1)
    }*/
}

.redbox_opened .redbox__info-bottom {
    display: block;
    transition: all 1s;
    -webkit-animation: dropDown-data-v-4cb2cb27 1s ease-in-out both;
    animation: dropDown-data-v-4cb2cb27 1s ease-in-out both
}

.redbox_opened .icon-arrow {
    transform: rotate(180deg);
    fill: #fff!important
}

.redbox__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 21px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%!important;
    overflow: hidden;
    /*background-image: url(../img/box_bg_desktop.2854f75e.png)*/
}

@media(max-width: 1020px) {
    .redbox__bg {
        /*background-image:url(../img/box_bg_mobile.7138854c.png);*/
        background-position: 50%;
        background-repeat: no-repeat
    }
}

.redbox__main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    min-height: 179px
}

.redbox__main>:first-child {
    position: relative;
    width: 45%
}

.redbox__main>:nth-child(2) {
    width: 55%
}

.redbox__leftside {
    border-radius: 20px 0 0 20px
}

@media(max-width: 1020px) {
    .redbox__leftside {
        border-radius:20px 20px 0 0
    }
}

.op_completed {
    opacity: .7!important
}

.redbox__left {
    position: relative;
    padding: 36px 0 0 34px
}

.redbox__title {
    margin: 0 0 24px;
    font-size: 30px;
    line-height: 1.2;
    color: #fff
}

.redbox__title_small {
    margin: 3px 0 27px;
    font-size: 25px
}

.redbox__bc {
    padding: 0 0 0 51px;
    color: hsla(0,0%,100%,.6);
    background-size: 38px!important;
    background-position: 0 0!important;
    background-repeat: no-repeat!important;
    position: relative
}

@media(min-width: 1024px) {
    .redbox__bc {
        background-size:40px!important;
        background-position: left top 2px!important
    }
}

.redbox__bc .redbox__coin {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    left: 0
}

.redbox__bc-heading {
    margin: 0 0 4px;
    font-size: 12px;
    line-height: 1.2;
    color: #fff;
    font-weight: 600
}

.redbox__bc-heading.blackbox>span {
    position: relative
}

.redbox__bc-heading.blackbox>span:before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    height: 1px;
    background-color: #fff;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto
}

.redbox__bc-heading.smaller {
    font-size: 11px
}

.redbox__bc-value {
    display: inline-block;
    margin: 0;
    font-size: 25px;
    line-height: 1.2;
    white-space: nowrap
}

.redbox__bc-value span {
    color: #fff
}

.to_satoshi {
    display: flex;
    align-items: center
}

.to_satoshi .df-arrow {
    height: 15px;
    width: 15px;
    fill: #a5aab6
}

.to_satoshi .df-arrow:hover svg:hover {
    cursor: pointer;
    fill: #fff!important
}

.redbox__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.redbox__img .view {
    position: absolute;
    height: 248px;
    width: 248px;
    right: 0;
    top: -50px;
    z-index: 10
}

.redbox__img .view img {
    position: absolute;
    height: 100%;
    width: 100%;
    
}

.redbox__img .view>img {
    filter: opacity(0.3);
}
/*
.redbox__img .view span img {
    z-index: 999;
}*/

.redbox__img .view .img-jon-snow {
    top: 20px;
    -webkit-animation: jon-snow-data-v-4cb2cb27 .5s linear alternate;
    animation: jon-snow-data-v-4cb2cb27 .5s linear alternate
}

.redbox__img .view .minus-10 {
    position: absolute;
    bottom: 0;
    left: 20px;
    top: unset;
    height: auto;
    width: 88px
}

.redbox__img .view .box-open .drop-coin {
    width: 20px;
    height: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%) scale(.8)
}

.redbox__img .shield {
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg,#ff5465,#ff2885);
    box-shadow: 2px -2px 5px rgba(31,61,109,.5);
    border-radius: 0 20px;
    padding: 5px 15px;
    font-size: 12px;
    font-weight: 600
}

@media(max-width: 1020px) {
    .redbox__img .shield {
        bottom:auto;
        left: auto;
        top: 0;
        right: 0;
        padding: 4px 15px;
        font-size: 11px
    }
}

@-webkit-keyframes jon-snow-data-v-4cb2cb27 {
    0% {
        opacity: 0;
        transform: scale(.4)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes jon-snow-data-v-4cb2cb27 {
    0% {
        opacity: 0;
        transform: scale(.4)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

.redbox__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 31px 9px 38px
}

.redbox__right_1.sale:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    /*background: url(../img/sale.ccfab942.png) 0 0 no-repeat;*/
    width: 79px!important;
    height: 100%
}

.redbox__right_1>:first-child {
    width: 183px
}

.redbox__right_1>:nth-child(2) {
    width: 159px
}

.redbox__right_1>:nth-child(3) {
    width: 150px
}

.redbox__right_1>:nth-child(4) {
    width: 100%
}

@media(max-width: 1230px) {
    .redbox__right_1>:first-child {
        width:150px
    }

    .redbox__right_1>:nth-child(2) {
        width: 115px
    }

    .redbox__right_1>:nth-child(3) button {
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap
    }
}

.redbox__period {
    font-weight: 600
}

.redbox__heading {
    margin: 0 0 12px;
    font-size: 12px;
    line-height: 1.2;
    color: #7b8ba4
}

.redbox__period-days {
    display: flex
}

.redbox__period-day {
    margin: 0 10px 0 0
}

.redbox__period-day:last-child {
    margin-right: 0
}

.redbox__period-day button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 38px;
    border: 1.5px solid #297fe5;
    border-radius: 7px;
    text-decoration: none;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: #7b8ba4;
    background: #122e4f
}

@media(max-width: 1020px) {
    .redbox__period-day button {
        width:100%
    }
}

.redbox__period-day button:hover {
    color: #fff
}

.redbox__period-day button:disabled {
    pointer-events: none
}

.redbox__period-day_active button {
    color: #fff;
    border-color: #2281f6;
    background: #2281f6
}

.redbox__rate-value {
    font-size: 35px;
    line-height: 1;
    text-indent: 7px;
    white-space: nowrap;
    color: #fff
}

.redbox__btn {
    margin: 20px 0 0;
    text-transform: uppercase
}

.completed_btn {
    background: #55657e!important;
    border: 1px solid #55657e
}

.over_limit_div:hover .over_limit_tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateY(0)
}

.over_limit_tooltip {
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    left: 87%;
    bottom: calc(100% - 70px);
    margin-left: -125px;
    width: 250px;
    position: absolute;
    padding: 15px;
    border-radius: 7px;
    background-color: #101215;
    color: #fff;
    line-height: 1.4;
    font-weight: 400;
    font-size: 14px
}

@media(max-width: 420px) {
    .over_limit_tooltip {
        left:50%;
        bottom: calc(100% - 280px)
    }
}

.over_limit_tooltip:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #101215;
    border-width: 7px;
    margin-left: -7px
}

.redbox__info {
    margin: 26px 0 0;
    padding: 7px 0 0;
    border-top: 1px solid #313d6a;
    font-weight: 600
}

.redbox__info-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: .01em;
    color: #7b8ba4
}

.redbox__info-top>div {
    white-space: nowrap
}

.redbox__info-top>:first-child {
    width: 50%
}

@media screen and (max-width: 500px) {
    .redbox__info-top>:first-child {
        width:auto;
        padding-left: 5px
    }
}

.redbox__info-top>:first-child>:nth-child(2)>:first-child {
    color: #fff
}

.redbox__info-top>:nth-child(2) {
    width: 50%;
    text-align: right
}

@media screen and (max-width: 500px) {
    .redbox__info-top>:nth-child(2) {
        width:auto
    }
}

@media screen and (max-width: 500px) {
    .redbox__info-top {
        flex-direction:column;
        align-items: flex-start
    }
}

.redbox__info-detail {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 5px 0 5px 5px
}

.redbox__info-detail .icon-arrow {
    margin-left: 6px;
    fill: #7b8ba4
}

.redbox__info-detail:hover {
    cursor: pointer
}

.redbox__info-detail_rowup:after {
    transform: translateY(-50%) rotate(-180deg)
}

.redbox__info-bottom {
    display: none;
    padding: 8px 0 6px;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .01em;
    color: #7b8ba4
}

.redbox__info-bottom span {
    color: #fff
}

.redbox__info-bottom> {
    float: left
}

.redbox__info-bottom>:first-child {
    width: 55%
}

.redbox__info-bottom>:nth-child(2) {
    width: 45%
}

.redbox__right_2 {
    padding: 32px 31px 9px 38px
}

.redbox__right_2>:first-child {
    width: calc(100% - 150px)
}

.redbox__right_2>:nth-child(2) {
    width: 150px
}

.redbox__right_2>:nth-child(3) {
    width: 100%
}

.redbox__right_2 .redbox__bc {
    margin: 2px 0 0
}

.redbox__right_2 .redbox__bc-heading {
    color: #7b8ba4
}

.redbox__right_2 .redbox__btn {
    margin: 0
}

.redbox__right_2 .redbox__info {
    margin: 16px 0 0
}

.redbox__capacity {
    padding: 21px 0 0;
    font-weight: 600
}

.redbox__capacity-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: .01em;
    color: #55657e
}

.redbox__capacity-heading {
    display: block;
    width: 100px;
    color: #fff
}

.redbox__capacity-value {
    display: block;
    width: calc(100% - 100px);
    text-align: right;
    text-transform: uppercase
}

.redbox__capacity-value>*> {
    color: #fff
}

.redbox__capacity-scale {
    height: 8px;
    border-radius: 55px;
    background: #55657e
}

.redbox__capacity-scale> {
    height: 100%;
    border-radius: 55px;
    background: #2ddc4b
}

.redbox__capacity-scale>[style="width: 100%"] {
    border-radius: 55px
}

@media(max-width: 1020px) {
    .redbox__main>:nth-child(2) {
        width:100%
    }

    .redbox__bg {
        border-radius: 20px
    }

    .redbox__title {
        margin: 0 0 16px;
        font-size: 28px;
        line-height: 30px
    }

    .redbox__left {
        padding: 32px 0 0 25px
    }

    .redbox__bc {
        padding: 0 0 0 44px;
        background-size: 34px 34px
    }

    .redbox__bc-value {
        font-size: 17px
    }

    .redbox__bc-heading {
        margin: 0 0 3px
    }

    .redbox__right {
        padding: 50px 24px 14px
    }

    .redbox__right_1>:first-child {
        width: 100%
    }

    .redbox__right_1>:nth-child(2) {
        order: -1;
        width: 100%
    }

    .redbox__right_1>:nth-child(3) {
        width: 100%
    }

    .redbox__period-day {
        margin: 0 0 11px;
        width: 100%
    }

    .redbox__period-day:first-child {
        margin: 0 10px 0 0
    }

    .redbox__period-day:last-child {
        margin: 0
    }

    .redbox__period-day a {
        width: 100%;
        height: 44px
    }

    .redbox__rate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #313d6a
    }

    .redbox__rate p {
        font-weight: 700;
        line-height: 23px
    }

    .redbox__period {
        padding-top: 10px
    }

    .redbox__heading {
        margin: 0
    }

    .period_heading {
        padding-bottom: 10px
    }

    .redbox__btn {
        margin: 13px 0 0;
        max-width: 100%
    }

    .redbox__info {
        margin: 18px 0 0;
        padding: 10px 0 0
    }

    .redbox__info-bottom>:nth-child(2) {
        width: 100%
    }

    .redbox__info-bottom {
        padding: 5px 0 1px
    }

    .redbox__right_2>:nth-child(2) {
        width: 100%
    }

    .redbox__right_2 {
        padding: 80px 24px 14px!important
    }

    .redbox__right_2 .redbox__bc {
        margin: 0 0 22px
    }

    .redbox__capacity {
        padding: 26px 0 0
    }

    .redbox__right_2 .redbox__info {
        margin: 15px 0 0;
        padding: 7px 0 0
    }

    .redbox__main {
        min-height: auto
    }
}

@media(max-width: 420px) {
    .redbox__title {
        font-size:24px
    }

    .redbox__line {
        position: absolute;
        left: 0;
        top: 154px;
        display: block;
        width: 100%;
        height: 5px;
        /*background: url(../img/freebox-line.f13702b2.svg) no-repeat 50%;*/
        z-index: 1
    }

    .redbox__line> {
        display: block;
        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        border-radius: 7px;
        background: #0d131c;
        transform-origin: center center
    }

    .redbox__line>:first-child {
        left: 0;
        transform: translate(-60%,-50%) rotate(-45deg)
    }

    .redbox__line>:nth-child(2) {
        right: 0;
        transform: translate(60%,-50%) rotate(-45deg)
    }
}

.scale-up-center {
    -webkit-animation: scale-up-center-data-v-4cb2cb27 5s linear infinite;
    animation: scale-up-center-data-v-4cb2cb27 5s linear infinite;
    opacity: 0
}

.scale-up-side {
    -webkit-animation: scale-up-center-data-v-4cb2cb27 7s linear infinite;
    animation: scale-up-center-data-v-4cb2cb27 7s linear infinite;
    opacity: 0
}

.anim-light {
    -webkit-animation: anim-light-data-v-4cb2cb27 2s linear infinite;
    animation: anim-light-data-v-4cb2cb27 2s linear infinite
}

@-webkit-keyframes anim-light-data-v-4cb2cb27 {
    0% {
        opacity: .4
    }

    50% {
        opacity: 1
    }

    to {
        opacity: .4
    }
}

@keyframes anim-light-data-v-4cb2cb27 {
    0% {
        opacity: .4
    }

    50% {
        opacity: 1
    }

    to {
        opacity: .4
    }
}

@-webkit-keyframes scale-up-center-data-v-4cb2cb27 {
    0% {
        transform: scale(.5);
        opacity: 0
    }

    25% {
        opacity: 1
    }

    to {
        transform: scale(1.2);
        opacity: 0
    }
}

@keyframes scale-up-center-data-v-4cb2cb27 {
    0% {
        transform: scale(.5);
        opacity: 0
    }

    25% {
        opacity: 1
    }

    to {
        transform: scale(1.2);
        opacity: 0
    }
}

@-webkit-keyframes scale-up-side-data-v-4cb2cb27 {
    0% {
        transform: scaleX(.5);
        opacity: 0
    }

    25% {
        opacity: 1
    }

    to {
        transform: scaleX(1.2);
        opacity: 0
    }
}

@keyframes scale-up-side-data-v-4cb2cb27 {
    0% {
        transform: scaleX(.5);
        opacity: 0
    }

    25% {
        opacity: 1
    }

    to {
        transform: scaleX(1.2);
        opacity: 0
    }
}

.dprop0 {
    -webkit-animation: dprop0-data-v-4cb2cb27 .5s linear both;
    animation: dprop0-data-v-4cb2cb27 .5s linear both
}

@-webkit-keyframes dprop0-data-v-4cb2cb27 {
    to {
        left: 20%;
        top: 20%
    }
}

@keyframes dprop0-data-v-4cb2cb27 {
    to {
        left: 20%;
        top: 20%
    }
}

.dprop1 {
    -webkit-animation: dprop1-data-v-4cb2cb27 .5s linear both;
    animation: dprop1-data-v-4cb2cb27 .5s linear both
}

@-webkit-keyframes dprop1-data-v-4cb2cb27 {
    to {
        left: 50%;
        top: 10%
    }
}

@keyframes dprop1-data-v-4cb2cb27 {
    to {
        left: 50%;
        top: 10%
    }
}

.dprop2 {
    -webkit-animation: dprop2-data-v-4cb2cb27 .5s linear both;
    animation: dprop2-data-v-4cb2cb27 .5s linear both
}

@-webkit-keyframes dprop2-data-v-4cb2cb27 {
    to {
        left: 80%;
        top: 20%
    }
}

@keyframes dprop2-data-v-4cb2cb27 {
    to {
        left: 80%;
        top: 20%
    }
}

.dprop3 {
    -webkit-animation: dprop3-data-v-4cb2cb27 .5s linear both;
    animation: dprop3-data-v-4cb2cb27 .5s linear both
}

@-webkit-keyframes dprop3-data-v-4cb2cb27 {
    to {
        left: 33%;
        top: 33%
    }
}

@keyframes dprop3-data-v-4cb2cb27 {
    to {
        left: 33%;
        top: 33%
    }
}

.dprop4 {
    -webkit-animation: dprop4-data-v-4cb2cb27 .5s linear both;
    animation: dprop4-data-v-4cb2cb27 .5s linear both
}

@-webkit-keyframes dprop4-data-v-4cb2cb27 {
    to {
        left: 40%;
        top: 30%
    }
}

@keyframes dprop4-data-v-4cb2cb27 {
    to {
        left: 40%;
        top: 30%
    }
}

.dprop5 {
    -webkit-animation: dprop5-data-v-4cb2cb27 .5s linear both;
    animation: dprop5-data-v-4cb2cb27 .5s linear both
}

@-webkit-keyframes dprop5-data-v-4cb2cb27 {
    to {
        left: 70%;
        top: 35%
    }
}

@keyframes dprop5-data-v-4cb2cb27 {
    to {
        left: 70%;
        top: 35%
    }
}

.dprop6 {
    -webkit-animation: dprop6-data-v-4cb2cb27 .5s linear both;
    animation: dprop6-data-v-4cb2cb27 .5s linear both
}

@-webkit-keyframes dprop6-data-v-4cb2cb27 {
    to {
        left: 60%;
        top: 25%
    }
}

@keyframes dprop6-data-v-4cb2cb27 {
    to {
        left: 60%;
        top: 25%
    }
}
