:root {
  --wheel-font: "Albertus";
  /* --wheel-container-width: 460px; */
  --wheel-container-width: 516px;
  --wheel-container-height: 516px;
  --wheel-arrow-width: 37px;
  --wheel-arrow-height: 85px;
  --wheel-size: 260px;
  --wheel-top: 148px;
  --wheel-arrow-top: 148px;
  --wheel-slice-spacing: 35px;
  --wheel-border-size: 5px;
  --wheel-color:#832A4B;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 1;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 10;
  --spinning-duration: 7s;
  --spinning-effect-duration: 1.4s;
  --reset-duration: 0.25s;
}

@media screen and (max-width: 998px) {
  :root {
    /* --wheel-container-width: 328px; */
    --wheel-container-width: 368px;
    --wheel-container-height: 368px;
    --wheel-arrow-width: 26px;
    --wheel-arrow-height: 61px;
    --wheel-size: 200px;
    --wheel-top: 111px;
    --wheel-arrow-top: 111px;
  }
}

.wheel-container {
    width: var(--wheel-container-width);
    height: var(--wheel-container-height);
    background: url(../img/spin/idle/spin-bg-0.png) no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
}

.wheel-container.bg-animation {
  animation: change-bg-data  1s infinite;
}

.wheel-container .spin-background {
  width: var(--wheel-container-width);
  height: var(--wheel-container-height);
}

.wheel-arrow-container {
  width: var(--wheel-arrow-width);
  height: var(--wheel-arrow-height);
  position: absolute;
  left: 50%;
  top: var(--wheel-arrow-top);
  transform: translate(-50%, -50%);
  z-index: 10;
}

.wheel-arrow-container .wheel-arrow {
  width: var(--wheel-arrow-width);
  height: var(--wheel-arrow-height);
  background: url(../img/spin/spin-arrow.png) no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  transform-origin: top center;
}

.arrow-spinning.rotate-arrow {
  -webkit-animation: rotate-arrow-data 0.2s 19 linear forwards;
  animation: rotate-arrow-data 0.2s 19 linear forwards;
}

.arrow-spinning-slow.rotate-arrow {
  -webkit-animation: rotate-arrow-slow-data 0.3s 3 linear forwards;
  animation: rotate-arrow-slow-data 0.4s 3 linear forwards;
}

.arrow-spinning-1.rotate-arrow {
  -webkit-animation: rotate-arrow-data-1 0.5s 1 linear forwards;
  animation: rotate-arrow-data-1 0.5s 1 linear forwards;
}

.arrow-spinning-2.rotate-arrow {
  -webkit-animation: rotate-arrow-data-2 1.5s 1 linear;
  animation: rotate-arrow-data-2 1.5s 1 linear;
}

@keyframes rotate-arrow-data {
    0% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(-24deg);
    }

    50% {
        transform: rotate(-45deg);
    }

    80% {
      transform: rotate(-24deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes rotate-arrow-data {
    0% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(-24deg);
    }

    50% {
        transform: rotate(-45deg);
    }

    80% {
      transform: rotate(-24deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@keyframes rotate-arrow-slow-data {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-24deg);
  }

  50% {
      transform: rotate(-45deg);
  }

  80% {
    transform: rotate(-24deg);
  }

  to {
      transform: rotate(0deg);
  }
}

@-webkit-keyframes rotate-arrow-slow-data {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-24deg);
  }

  50% {
      transform: rotate(-45deg);
  }

  80% {
    transform: rotate(-24deg);
  }

  to {
      transform: rotate(0deg);
  }
}

@keyframes rotate-arrow-data-1 {
  0% {
    transform: rotate(0deg);
  }

  to {
      transform: rotate(-45deg);
  }
}

@-webkit-keyframes rotate-arrow-data-1 {
  0% {
    transform: rotate(0deg);
  }
  
  to {
      transform: rotate(-45deg);
  }
}

@keyframes rotate-arrow-data-2 {
  0% {
    transform: rotate(-45deg);
  }

  20% {
    transform: rotate(-24deg);
  }

  50% {
      transform: rotate(0deg);
  }

  80% {
    transform: rotate(24deg);
  }

  to {
      transform: rotate(0deg);
  }
}

@-webkit-keyframes rotate-arrow-data-2 {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-24deg);
  }

  50% {
      transform: rotate(-45deg);
  }

  80% {
    transform: rotate(-24deg);
  }

  to {
      transform: rotate(0deg);
  }
}

.wheel-wrapper {
    width: var(--wheel-size);
    height: var(--wheel-size);
    position: absolute;
    top: var(--wheel-top);
    left: 50%;
    transform: translate(-50%, 0%);
}

.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  /* background: url(../img/spin/spin-wheel.png); */
  background-position: center;
  background-size: contain;
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
  cursor: pointer;
}

.wheel-bg {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  background: url(../img/spin/spin-wheel.png);
  background-position: center;
  background-size: contain;
  transition: transform var(--reset-duration);
  transform: rotate(calc(45deg * var(--higher-prize-nb, 0)));
  cursor: pointer;
}



/* center button */
.wheel::after {
  display: block;
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  z-index: 2;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: url(../img/spin/spin-center.png);
  background-size: contain;
  background-position: center;
}

.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1)) - 90deg));
  color: var(--neutral-color);
  padding: 0 45px 0 2px;
  font-family: var(--wheel-font);
  font-size: 16px;
  text-align: end;
  text-shadow: 1.7px 1.7px #4a0c0c;
}

.wheel-item:before {
  content: ' ';
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;
  --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
  --slice-width: 162px;
  
}

@-webkit-keyframes rotate-wheel-data {
  0% {
      transform: rotate(0deg);
  }

  75% {
      transform: rotate(calc(var(--nb-turn) * 360deg + 22.5deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }

  80% {
      transform: rotate(calc(var(--nb-turn) * 360deg + 20deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }

  to {
    transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }
}

@keyframes rotate-wheel-data {
  0% {
      transform: rotate(0deg);
  }

  75% {
      transform: rotate(calc(var(--nb-turn) * 360deg + 22.5deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }

  80% {
      transform: rotate(calc(var(--nb-turn) * 360deg + 20deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }

  to {
    transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }
}

@-webkit-keyframes spin-effect-data {
  0% {
      opacity: 0;
  }

  45% {
    opacity: 0.7;
  }

  55% {
    opacity: 0.7;
  }

  to {
    opacity: 0;
  }
}

@keyframes spin-effect-data {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.7;
  }

  60% {
    opacity: 0.7;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes change-bg-data {
  0%,100%  {background-image: url(../img/spin/idle/spin-bg-0.png);}
  4.17% {background-image: url(../img/spin/idle/spin-bg-1.png);}
  8.33% {background-image: url(../img/spin/idle/spin-bg-2.png);}
  12.5% {background-image: url(../img/spin/idle/spin-bg-3.png);}
  17% {background-image: url(../img/spin/idle/spin-bg-4.png);}
  21% {background-image: url(../img/spin/idle/spin-bg-5.png);}
  25% {background-image: url(../img/spin/idle/spin-bg-6.png);}
  29% {background-image: url(../img/spin/idle/spin-bg-7.png);}
  33% {background-image: url(../img/spin/idle/spin-bg-8.png);}
  37.5% {background-image: url(../img/spin/idle/spin-bg-9.png);}
  42% {background-image: url(../img/spin/idle/spin-bg-10.png);}
  46% {background-image: url(../img/spin/idle/spin-bg-11.png);}
  50% {background-image: url(../img/spin/idle/spin-bg-12.png);}
  54% {background-image: url(../img/spin/idle/spin-bg-13.png);}
  58% {background-image: url(../img/spin/idle/spin-bg-14.png);}
  62.5% {background-image: url(../img/spin/idle/spin-bg-15.png);}
  67% {background-image: url(../img/spin/idle/spin-bg-16.png);}
  71% {background-image: url(../img/spin/idle/spin-bg-17.png);}
  75% {background-image: url(../img/spin/idle/spin-bg-18.png);}
  79% {background-image: url(../img/spin/idle/spin-bg-19.png);}
  83% {background-image: url(../img/spin/idle/spin-bg-20.png);}
  87.5% {background-image: url(../img/spin/idle/spin-bg-21.png);}
  92% {background-image: url(../img/spin/idle/spin-bg-22.png);}
  96% {background-image: url(../img/spin/idle/spin-bg-23.png);}
}

@keyframes change-bg-data {
  0%,100%  {background-image: url(../img/spin/idle/spin-bg-0.png);}
  4.17% {background-image: url(../img/spin/idle/spin-bg-1.png);}
  8.33% {background-image: url(../img/spin/idle/spin-bg-2.png);}
  12.5% {background-image: url(../img/spin/idle/spin-bg-3.png);}
  17% {background-image: url(../img/spin/idle/spin-bg-4.png);}
  21% {background-image: url(../img/spin/idle/spin-bg-5.png);}
  25% {background-image: url(../img/spin/idle/spin-bg-6.png);}
  29% {background-image: url(../img/spin/idle/spin-bg-7.png);}
  33% {background-image: url(../img/spin/idle/spin-bg-8.png);}
  37.5% {background-image: url(../img/spin/idle/spin-bg-9.png);}
  42% {background-image: url(../img/spin/idle/spin-bg-10.png);}
  46% {background-image: url(../img/spin/idle/spin-bg-11.png);}
  50% {background-image: url(../img/spin/idle/spin-bg-12.png);}
  54% {background-image: url(../img/spin/idle/spin-bg-13.png);}
  58% {background-image: url(../img/spin/idle/spin-bg-14.png);}
  62.5% {background-image: url(../img/spin/idle/spin-bg-15.png);}
  67% {background-image: url(../img/spin/idle/spin-bg-16.png);}
  71% {background-image: url(../img/spin/idle/spin-bg-17.png);}
  75% {background-image: url(../img/spin/idle/spin-bg-18.png);}
  79% {background-image: url(../img/spin/idle/spin-bg-19.png);}
  83% {background-image: url(../img/spin/idle/spin-bg-20.png);}
  87.5% {background-image: url(../img/spin/idle/spin-bg-21.png);}
  92% {background-image: url(../img/spin/idle/spin-bg-22.png);}
  96% {background-image: url(../img/spin/idle/spin-bg-23.png);}
}

.wheel.spinning {
  -webkit-animation: rotate-wheel-data var(--spinning-duration) cubic-bezier(0, 0, 0.54, 0.99) forwards;
  animation: rotate-wheel-data var(--spinning-duration) cubic-bezier(0, 0, 0.54, 0.99) forwards;
}

.spin-effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.spin-effect.spin-effect-anim {
  -webkit-animation: spin-effect-data 1.1s 3 linear forwards ;
  animation: spin-effect-data 1.1s 3 linear forwards;
}

@media screen and (max-width: 998px) {
  .wheel-item {
    padding: 0 30px 0 20px;
    font-family: var(--wheel-font);
    font-size: 12px;
    text-align: end;
  }
}