@-webkit-keyframes rotate-item-data {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2);
    }

    20% {
        transform: scale(.8) rotate(0deg);
    }

    50% {
        transform: scale(.8) rotate(180deg);
    }

    80% {
        transform: scale(.8) rotate(360deg);
    }

    90% {
        transform: scale(1.2) rotate(360deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

@keyframes rotate-item-data {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2);
    }

    20% {
        transform: scale(.8) rotate(0deg);
    }

    50% {
        transform: scale(.8) rotate(180deg);
    }

    80% {
        transform: scale(.8) rotate(360deg);
    }

    90% {
        transform: scale(1.2) rotate(360deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

@-webkit-keyframes menu-show-item-data {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}

@keyframes menu-show-item-data {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}

.rotate-item {
    -webkit-animation: rotate-item-data 2s linear infinite;
    animation: rotate-item-data 2s linear infinite;
}

.sub-menu {
    -webkit-animation: menu-show-item-data 300ms ease-in-out forwards;
    animation: menu-show-item-data 300ms ease-in-out forwards;
    transform-origin: top center
}